import { createAction } from 'redux-actions'

// Actions
export const UPDATE = 'carrierOnboarding/userData/UPDATE'
export const RESET = 'carrierOnboarding/userData/RESET'

const initialState = {
  id: '',
  name: '',
  email: ''
}
// Reducer
export default function userData (state = initialState, action) {
  switch (action.type) {
    case UPDATE:
      return action.payload
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const updateUserData = createAction(UPDATE)
export const resetUserData = createAction(RESET)