import React from 'react'
import PropTypes from 'prop-types'
import { Button, Input } from '@intxlog/iuilib'

//import styles
import styles from './styles.module.scss'

const CompanyLookupForm = ({
  onChange,
  onSubmit
}) => {
  return (
    <form onSubmit={onSubmit} className={styles.container}>
      <Input
        idForLabel='lookupNumber'
        className={styles.input}
        onChange={onChange}
      ></Input>
      <Button
        text={`Search`}
        submitsForm={true}
      ></Button>
    </form>
  )
}

//rules for props being passed in
CompanyLookupForm.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func
}

//
// // Specifies the default values for props:
// CompanyLookupForm.defaultProps = {
//
// }

export default CompanyLookupForm