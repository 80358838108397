import React from 'react'
import PropTypes from 'prop-types'
import { Button, Input, LoadingGif, Modal } from '@intxlog/iuilib'
import classNames from 'classnames'


//import css
import styles from './styles.module.scss'

class LoginForm extends React.Component {
  render(){

    let showModal
    if (this.props.modal.isOpen) {
      showModal = <Modal
        {...this.props.modal}
        closeFunc={this.props.handleModalClose}
      ></Modal>
    }
    let mainCSSCLasses = classNames({
      [styles.loading]: this.props.isLoading
    })

    return (
      <>
        <form onSubmit={this.props.onSubmit} className={mainCSSCLasses}>
          <h1>Login</h1>
            <p>Please sign in below using the information you entered when creating an account previously. If do not have an account, please click the button below to sign up.</p>
            <p>If you leave the process and return from the same device, you'll be brought back to the point where you left.</p>
          <div className={styles.inputWrapper}>
          <label htmlFor="email">Email Address</label>
          <Input
              idForLabel="email"
              type='email'
              required
              error={this.props.emailError}
              infoText={this.props.emailErrorText}
              onChange={this.props.onEmailChange}
              isValid={this.props.isEmailValid}
              defaultValue={this.props.emailInputValue}
              placeholder={'Email Address'}
            ></Input>
          <label htmlFor="password">Password</label>
            <Input
              idForLabel="password"
              required
              onChange={this.props.onPasswordChange}
              placeholder={'Password'}
              type="password"
            />
            
            
          </div>
          <Button
            fullWidth={true}
            text={`Log In`}
            submitsForm={true}
          ></Button>
          

          <div>
          <p>Need an account?</p>
          <Button
            text={`Sign Up`}
            onClick={this.props.onClickSignup}
          ></Button>
        </div>
        </form>

        {showModal}

        {this.props.isLoading &&
        <LoadingGif />
        }
      </>
    )
  }
}

//rules for props being passed in
LoginForm.propTypes = {
  isEmailValid: PropTypes.func,
  emailError: PropTypes.bool,
  emailErrorText: PropTypes.string,
  disableButton: PropTypes.bool,
  onEmailChange: PropTypes.func,
  onSubmit: PropTypes.func
}

// Specifies the default values for props:
// LoginForm.defaultProps = {

// }

export default LoginForm
