import { createAction } from 'redux-actions'

// Actions
export const FINISHED_WAITING = 'carrierOnboarding/auth/FINISHED_WAITING'
export const SET_WAITING_ON_VERIFICATION = 'carrierOnboarding/auth/SET_WAITING_ON_VERIFICATION'
export const UNSET_WAITING_ON_VERIFICATION = 'carrierOnboarding/auth/UNSET_WAITING_ON_VERIFICATION'
export const USER_VERIFIED = 'carrierOnboarding/auth/USER_VERIFIED'
export const LOG_IN = 'carrierOnboarding/auth/USER_IS_LOGGED_IN'
export const LOG_OUT = 'carrierOnboarding/auth/USER_IS_LOGGED_OUT'
export const SET_IS_VALID_CARRIER = 'carrierOnboarding/auth/SET_IS_VALID_CARRIER'
export const RESET = 'carrierOnboarding/auth/RESET'
export const GET_URL_TOKEN = 'carrierOnboarding/auth/GET_URL_TOKEN'
export const GET_URL_CARRIERID = 'carrierOnboarding/auth/GET_URL_CARRIERID'

const initialState = {
  waiting: true,
  waitingOnVerificaion: false,
  isValidCarrier: false,
  userVerified: false,
  userLoggedIn: false,
  urlToken: null,
  urlCarrierID: null
}
// Reducer
export default function auth (state = initialState, action) {
  switch (action.type) {
    case FINISHED_WAITING:
      return {
        ...state,
        waiting: false
      }
    case SET_WAITING_ON_VERIFICATION:
      return {
        ...state,
        waitingOnVerificaion: true
      }
    case UNSET_WAITING_ON_VERIFICATION:
      return {
        ...state,
        waitingOnVerificaion: false
      }
    case USER_VERIFIED:
      return {
        ...state,
        userVerified: true
      }
    case LOG_IN:
      return {
        ...state,
        userLoggedIn: true
      }
    case LOG_OUT:
      return {
        ...state,
        userLoggedIn: false
      }
    case SET_IS_VALID_CARRIER:
      return {
        ...state,
        isValidCarrier: true
      }
    case GET_URL_TOKEN:
      return {
        ...state,
        urlToken: action.payload
      }
    case GET_URL_CARRIERID:
      return {
        ...state,
        urlCarrierID: action.payload
      }
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const finishWaitingAuth = createAction(FINISHED_WAITING)
export const userVerifiedAuth = createAction(USER_VERIFIED)
export const userLoggedInAuth = createAction(LOG_IN)
export const userLoggedOutAuth = createAction(LOG_OUT)
export const setIsValidCarrier = createAction(SET_IS_VALID_CARRIER)
export const resetAuth = createAction(RESET)
export const getUrlToken = createAction(GET_URL_TOKEN)
export const getUrlCarrierID = createAction(GET_URL_CARRIERID)
