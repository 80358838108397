import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@intxlog/iuilib'
import classNames from 'classnames'

//import styles
import styles from './styles.module.scss'

function Avatar(props) {
  const node = useRef();
  const [open, setOpen] = useState(false);
  const handleClick = e => {
    if (!node.current.contains(e.target)) {
      setOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    }
  }, []);

  var editDisabled = props.completedProcess ? '' : 'Edit is not allowed until final submission'

  return (
    <div className={styles.avatarWrap} ref={node}>
      {!props.name ? null : <button className={styles.avatar}
        onClick={e => setOpen(!open)}
      >
        {props.name}
        <div className={styles.arrow}></div>
      </button>}
      <ul className={
        classNames(
          styles.avatarDropDown,
          {
            [styles.open]: open === true
          }
        )
      }>
        <li title={editDisabled}>
          <Button
            text={`Edit Info`}
            className={styles.edit}
            onClick={props.editInfoFunction}
            disabled={!props.completedProcess} //Only allow if user has completed process
          ></Button>
        </li>
        <li>
          <Button
            text={`Log Out`}
            className={styles.logout}
            onClick={props.logoutFunction}
          ></Button>
        </li>
      </ul>
    </div>
  )
}

Avatar.propTypes = {
  name: PropTypes.string,
  open: PropTypes.bool,
}

export default Avatar