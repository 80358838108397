import { createAction } from 'redux-actions'

// Actions
export const SET_IS_VALID = 'carrierOnboarding/serviceAgreement/SET_IS_VALID'
export const UNSET_IS_VALID = 'carrierOnboarding/serviceAgreement/UNSET_IS_VALID'
export const SET_FORM_SUBMITTED = 'carrierOnboarding/serviceAgreement/SET_FORM_SUBMITTED'
export const UNSET_FORM_SUBMITTED = 'carrierOnboarding/serviceAgreement/UNSET_FORM_SUBMITTED'
export const SET_IS_SUBMITTING = 'carrierOnboarding/serviceAgreement/SET_IS_SUBMITTING'
export const UNSET_IS_SUBMITTING = 'carrierOnboarding/serviceAgreement/UNSET_IS_SUBMITTING'
export const RESET = 'carrierOnboarding/serviceAgreement/RESET'

const initialState = {
  formIsValid: false,
  formSubmitted: 0,
  isSubmitting: false
}

// Reducer
export default function serviceAgreement (state = initialState, action) {
  switch (action.type) {
    case SET_IS_VALID:
      return {
        ...state,
        formIsValid: true
      }
    case UNSET_IS_VALID:
      return {
        ...state,
        formIsValid: false
      }
    case SET_FORM_SUBMITTED:
      return {
        ...state,
        formSubmitted: state.formSubmitted + 1
      }
    case UNSET_FORM_SUBMITTED:
      return {
        ...state,
        formSubmitted: 0
      }
    case SET_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: true
      }
    case UNSET_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: false
      }
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const setIsValidServiceAgreement = createAction(SET_IS_VALID)
export const unsetIsValidServiceAgreement = createAction(UNSET_IS_VALID)
export const setformSubmittedServiceAgreement = createAction(SET_FORM_SUBMITTED)
export const unsetformSubmittedServiceAgreement = createAction(UNSET_FORM_SUBMITTED)
export const setIsSubmittingServiceAgreement = createAction(SET_IS_SUBMITTING)
export const unsetIsSubmittingServiceAgreement = createAction(UNSET_IS_SUBMITTING)
export const resetServiceAgreement = createAction(RESET)
