import { createAction } from 'redux-actions'

// Actions
export const UPDATE = 'carrierOnboarding/carrier/UPDATE'
export const RESET = 'carrierOnboarding/carrier/RESET'

const initialState = {
  name: '',
  dbaName: '',
  street: '',
  city: '',
  state: '',
  country: '',
  zip: '',
  phone: '',
  fax: '',
  email: '',
  mcNumber: '',
  dotNumber: ''
}

// Reducer
export default function carrier (state = initialState, action)  {
  switch (action.type) {
    case UPDATE:
      return action.payload
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const updateCarrier = createAction(UPDATE)
export const resetCarrier = createAction(RESET)