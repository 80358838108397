import { createAction } from 'redux-actions'

// Actions
export const UPDATE_NUMBER_TYPE = 'carrierOnboarding/companyLookup/UPDATE_NUMBER_TYPE'
export const UPDATE_NUMBER = 'carrierOnboarding/companyLookup/UPDATE_NUMBER'
export const UPDATE_HAS_RESULT = 'carrierOnboarding/companyLookup/UPDATE_HAS_RESULT'
export const SET_SUBMITTED = 'carrierOnboarding/companyLookup/SET_SUBMITTED'
export const UNSET_SUBMITTED = 'carrierOnboarding/companyLookup/UNSET_SUBMITTED'
export const SET_IS_DUPLICATE = 'carrierOnboarding/companyLookup/SET_IS_DUPLICATE'
export const UNSET_IS_DUPLICATE = 'carrierOnboarding/companyLookup/UNSET_IS_DUPLICATE'
export const RESET = 'carrierOnboarding/companyLookup/RESET'

const initialState = {
  number: null,
  numberType: null,
  hasResult: false,
  submitted: false,
  isDuplicate: false
}
// Reducer
export default function companyLookup (state = initialState, action) {
  switch (action.type) {
    case UPDATE_HAS_RESULT:
      return {
        ...state,
        hasResult: action.payload
      }
    case UPDATE_NUMBER_TYPE:
      return {
        ...state,
        numberType: action.payload
      }
    case UPDATE_NUMBER:
      return {
        ...state,
        number: action.payload
      }
    case SET_SUBMITTED:
      return {
        ...state,
        submitted: true
      }
    case UNSET_SUBMITTED:
      return {
        ...state,
        submitted: false
      }
    case SET_IS_DUPLICATE:
      return {
        ...state,
        isDuplicate: true
      }
    case UNSET_IS_DUPLICATE:
      return {
        ...state,
        isDuplicate: false
      }
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const updateNumberTypeCompanyLookup = createAction(UPDATE_NUMBER_TYPE)
export const updateNumberCompanyLookup = createAction(UPDATE_NUMBER)
export const updateHasResultCompanyLookup = createAction(UPDATE_HAS_RESULT)
export const setSubmittedCompanyLookup = createAction(SET_SUBMITTED)
export const unsetSubmittedCompanyLookup = createAction(UNSET_SUBMITTED)
export const setIsDuplicateCompanyLookup = createAction(SET_IS_DUPLICATE)
export const unsetIsDuplicateCompanyLookup = createAction(UNSET_IS_DUPLICATE)
export const resetCompanyLookup = createAction(RESET)