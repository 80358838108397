import React from 'react'
import { Route, Switch } from 'react-router-dom'

//import components
import ThankYou from 'components/ThankYou'
import ErrorPage from 'components/ErrorPage'

//import containers
import PaymentContainer from 'containers/PaymentContainer'
import AdvanceContainer from 'containers/AdvanceContainer'
import ProfileContainer from 'containers/ProfileContainer'
import TaxForm from 'containers/TaxFormContainer'
import AgreementContainer from 'containers/AgreementContainer'
import AdminContainer from 'containers/AdminContainer'
import PayToContainer from 'containers/PayToContainer'

const LoggedInUserConfirmedRouter = () => {
  return (
    <Switch>
      <Route exact path="/" component={PaymentContainer} />
      <Route exact path="/login" component={PaymentContainer} />
      <Route path="/payment-options" component={PaymentContainer} />
      <Route path="/advance-options" component={AdvanceContainer} />
      <Route path="/company-profile" component={ProfileContainer} />
      <Route path="/remit-information" component={PayToContainer} />
      <Route path="/tax-information" component={TaxForm} />
      <Route path="/agreement" component={AgreementContainer} />
      <Route path="/thank-you" component={ThankYou} />
      {process.env.REACT_APP_ENV !== `prod` &&
        <Route path="/admin" component={AdminContainer} />
      }
      <Route component={ErrorPage} />
    </Switch>
  )
}

export default LoggedInUserConfirmedRouter
