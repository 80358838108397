import IELAPIServiceFactory from './IELAPIServiceFactory'
import store from 'store'

//Import actions
import { updateEquipmentTypes } from 'modules/equipmentTypes'

import navigateTo from 'utils/navigateTo'

import {
    setIsSubmittingServiceAgreement,
    unsetIsSubmittingServiceAgreement
} from 'modules/serviceAgreement'

import {
    setIsLoadingAppSettings,
    unsetIsLoadingAppSettings
} from 'modules/appSettings'

import {
    updateHasResultCompanyLookup,
    setSubmittedCompanyLookup,
    setIsDuplicateCompanyLookup,
    unsetIsDuplicateCompanyLookup,
    unsetSubmittedCompanyLookup
} from 'modules/companyLookup'

import { updateAppDataSettings } from 'modules/appDataSettings'

import {
    updateCarrier
} from 'modules/carrier'

export default class IELAPICarrierService {
    constructor(props) {
        this.service = IELAPIServiceFactory.new()
        this.uid = store.getState().userData.uid
    }

    dupeCheck(carrierName, dotNumber, mcNumber) {
        return this.service._withToken().post(`COP/carrier/dupeCheck`, {
            'carrierName': carrierName,
            'dotNumber': dotNumber,
            'mcNumber': mcNumber
        }).then((result) => {
            const resObj = result.data.data;
            return resObj
        }).catch(error => {
            this.service.handleError(error)
        })
    }

    lookUpCarrierInfo(isMCNumber, number) {
        store.dispatch(setIsLoadingAppSettings())
        store.dispatch(unsetSubmittedCompanyLookup())
        store.dispatch(updateHasResultCompanyLookup(false))
        store.dispatch(unsetIsDuplicateCompanyLookup())

        return this.service._withToken().post(`COP/carrier/lookUpCarrierInfo`, {
            'isMCNumber': isMCNumber,
            'number': number
        }).then(async (result) => {
            const resObj = result.data.data;

            //Check if the carrier thats been searched for exists in the IEL system
            const isDupe = await this.dupeCheck(resObj.name, resObj.dotNumber, resObj.mcNumber)

            if (isDupe) {
                store.dispatch(setSubmittedCompanyLookup());
                store.dispatch(updateCarrier(resObj));
                store.dispatch(updateHasResultCompanyLookup(true));
                store.dispatch(setIsDuplicateCompanyLookup());

                store.dispatch(unsetIsLoadingAppSettings());
            } else {
                store.dispatch(setSubmittedCompanyLookup());
                store.dispatch(updateCarrier(resObj));
                store.dispatch(updateHasResultCompanyLookup(true));
                store.dispatch(unsetIsLoadingAppSettings());
            }
        }).catch(error => {
            store.dispatch(unsetIsLoadingAppSettings())
            this.service.handleError(error)
        })
    }

    getTrailerTypes() {
        return this.service._withToken().get(`COP/carrier/getTrailerTypes`, {}).then((result) => {
            store.dispatch(updateEquipmentTypes(result.data.data))
        }).catch(error => {
            this.service.handleError(error)
        })
    }

    async createCarrier(isFromInvite = false, isEditRequest = false) {
        store.dispatch(setIsSubmittingServiceAgreement())
        store.dispatch(setIsLoadingAppSettings())
        const data = { ...store.getState().userResponses }

        //check if carrier already exists
        //This means they have a W9/W8 and contract on file
        //if carrier already exists within the IEL system,
        //but is submitting via here, it should be an Edit request,
        //and not an Add request
        if (!isEditRequest) {
            const isDupe = await this.dupeCheck(data.companyName, data.companyDOTNumber, data.companyMCNumber)

            if (isDupe) {
                isEditRequest = true
            }
        }

        //map the quick pay percents to ids default
        //thirty = 2
        //2day = 6
        let paytermtypeid
        switch (data.quickPayPreference) {
            case `twoDay`:
                paytermtypeid = 6
                break
            case `thirtyDay`:
                paytermtypeid = 2
                break
            default: //the default is 30 day
                paytermtypeid = 2
                break
        }

        //save w9 logic
        let savew9 = data.companyCountry !== `CA` ? true : false

        const taxClass = data.w9TaxClassification

        //if the user uses a factoring company then their payment option must be thirty days
        if (data.usesFactoringCompany) {
            data.quickPayPreference = `thirty`
        }

        let url = ''
        if (isFromInvite === true) {
            url = 'COP/carrier/createCarrier'
        } else if (isEditRequest === true) {
            url = 'COP/carrier/createCarrierEditRequest'
        } else {
            url = 'COP/carrier/createCarrierRequest'
        }

        return this.service._withToken().post(url, {
            'user_id': this.uid,
            'carrierName': data.companyName,
            'legalName': data.companyName,
            'mcNumber': data.companyMCNumber,
            'dotNumber': data.companyDOTNumber,
            'address1': data.companyAddress,
            'address2': data.companyAddress2,
            'city': data.companyCity,
            'state': data.companyState,
            'zip': data.companyZip,
            'country': data.companyCountry,
            'fax': data.companyFax,
            'email': data.companyEmail,
            'phone': data.companyPhone,
            'marketingEmail': data.companyEmail,
            'payTermID': paytermtypeid,
            'contactName': "",
            'dispatcher_accessorial_charges': data.dispatcherAccessorialCharges,
            'dispatcher_fuel_advances': data.dispatcherFuelAdvances,
            'driver_accessorial_charges': data.driverAccessorialCharges,
            'driver_fuel_advances': data.driverFuelAdvances,
            'usefactoring': data.usesFactoringCompany,
            'factoringCompanyname': data.factoringCompanyName,
            'remit_name': data.remitCompanyName,
            'remit_address1': data.remitCompanyAddress,
            'remit_address2': '',
            'remit_city': data.remitCompanyCity,
            'remit_state': data.remitCompanyState,
            'remit_zip': data.remitCompanyZip,
            'remit_phone': data.remitCompanyPhone,
            'remit_fax': data.remitCompanyFax,
            'remit_email': data.remitCompanyEmail,
            'trailertypes': data.equipmentTypes,
            'quickpaypercent': 0,
            'federalid': data.w9TaxNumber,
            'serviceagreementsig': data.serviceAgreementSignature,
            'servagreementsave': true,
            'sendagreement': true,
            'savew9': savew9,
            '1.name': data.w9Name,
            '2.dbaname': data.w9BusinessName,
            '3.soleproprietor': taxClass === `indiv` ? 1 : false,
            '3.ccorp': taxClass === `cCorp` ? 2 : false,
            '3.scorp': taxClass === `sCorp` ? 3 : false,
            '3.partnership': taxClass === `partnership` ? 4 : false,
            '3.trust': taxClass === `trust` ? 5 : false,
            '3.llcclassification': taxClass === `llc` ? 6 : false,
            '3.llcclassification-input': taxClass === `llc` ? data.w9TaxClassificationLLCoptions : false,
            '3.other': taxClass === `other` ? 7 : false,
            '3.other-input': taxClass === `other` ? data.w9TaxClassificationOther : false,
            '4.exemption-payee-code': false,
            '4.exemption-from-fatca': false,
            '5.address': data.w9Address,
            '6.city-state-zip': `${data.w9City}, ${data.w9State} ${data.w9Zip}`,
            '7.list-acct-nums': false,
            '7.requestor-name-addy': false,
            'p1.ssn1': data.w9NumberType === `ssn` ? data.w9TaxNumber.substring(0, 3) : false,
            'p1.ssn2': data.w9NumberType === `ssn` ? data.w9TaxNumber.substring(4, 6) : false,
            'p1.ssn3': data.w9NumberType === `ssn` ? data.w9TaxNumber.substring(7, 11) : false,
            'p1.ein1': data.w9NumberType === `ein` ? data.w9TaxNumber.substring(0, 2) : false,
            'p1.ein2': data.w9NumberType === `ein` ? data.w9TaxNumber.substring(3, 11) : false,
            //NOTE: The two below fields are no longer being used but we are keeping them for backwards compatibility
            'issuecomchektodispatcher': null,
            'issuecomchektodrivers': null
        }).then((result) => {
            store.dispatch(unsetIsLoadingAppSettings())
        }).then(() => {
            //Mark completed process
            store.dispatch(updateAppDataSettings({ completedProcess: true }))

            store.dispatch(unsetIsLoadingAppSettings())
            store.dispatch(unsetIsSubmittingServiceAgreement())

            //send the user to the thank you page
            store.dispatch(navigateTo(`/thank-you`))
        }).catch(error => {
            //Here, we stop the app from loading,
            //but we also unset the service agreement from sending,
            //that way the user can re-submit if they had an error
            store.dispatch(unsetIsLoadingAppSettings())
            store.dispatch(unsetIsSubmittingServiceAgreement())
            this.service.handleError(error, true)
        })
    }
}
