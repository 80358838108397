import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

//import components
import ServiceAgreement from 'components/ServiceAgreement'
import QuestionPageTemplate from 'components/QuestionPageTemplate'

//import utils
import navigateTo from 'utils/navigateTo'
import handleUpdateUserResponses from 'utils/handleUpdateUserResponses'
import checkRequiredFormErrors from 'utils/checkRequiredFormErrors'
import {
  setIsValidServiceAgreement,
  unsetIsValidServiceAgreement,
  setformSubmittedServiceAgreement
} from 'modules/serviceAgreement'

import { setTypeModal, setTextModal, setOpenModal } from 'modules/modal'

import IELAPICarrierService from 'services/IELAPICarrierService'
import ConfirmationModal from 'components/ConfirmationModal'

export class AgreementContainer extends React.Component {
  constructor(props) {
    super(props)

    this.apiService = new IELAPICarrierService();

    this.state = {
      editModal: false
    }
  }
  getCurrentDate = () => {
    const d = new Date()
    return d.getDate()
  }

  getCurrentMonth = () => {
    const d = new Date()
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    return months[d.getMonth()]
  }

  onSignatureChange = (val) => {
    this.props.handleUpdateUserResponses({ serviceAgreementSignature: val })
  }

  onSubmit = () => {
    this.props.setformSubmittedServiceAgreement()

    if (this.props.formIsValid) {
      if (this.props.isEditing) {
        //Open modal to confirm edit submission
        this.setState({ editModal: true })
      } else {
        this.apiService.createCarrier(this.props.appDataSettings.isFromInvite, false)
      }
    } else {
      this.props.checkRequiredFormErrors()
    }
  }

  submitEditModal = () => {
    this.apiService.createCarrier(false, true)
  }

  closeEditModal = () => {
    this.setState({ editModal: false })
  }

  handleBack = () => {
    this.props.navigateTo('/tax-information')
  }

  onSignatureValid = (isValid) => {
    if (isValid) {
      this.props.setIsValidServiceAgreement()
    } else {
      this.props.unsetIsValidServiceAgreement()
    }
  }

  render() {

    return (
      <>
        <QuestionPageTemplate
          title={`Carrier/Broker Service Agreement`}
          infoText={`Please verify that the following information in bold is correct before agreeing.`}
          wrapInBorder={false}
          prevText={`Back to Tax Information`}
          prevFunction={this.handleBack}
          nextText={`Submit`}
          nextFunction={this.onSubmit}
          disableNext={this.props.isSubmitting}
          isEditing={this.props.isEditing}
          disabled={this.props.isEditing}
        >
          <ServiceAgreement
            name={this.props.name}
            address={this.props.address}
            city={this.props.city}
            state={this.props.state}
            zip={this.props.zip}
            MCNumber={this.props.MCNumber}
            DOTNumber={this.props.DOTNumber}
            signature={this.props.signature}
            getDay={this.getCurrentDate}
            getMonth={this.getCurrentMonth}
            formSubmitted={this.props.formSubmitted}
            onSignatureChange={this.onSignatureChange}
            onSignatureValid={this.onSignatureValid}
          ></ServiceAgreement>
        </QuestionPageTemplate>
        {this.state.editModal ?
          <ConfirmationModal
            headerText={"Warning!"}
            descriptionText={"You are about to submit a request to update your information in the IEL system. If this request is accepted by a carrier service specialist, it will overwrite your existing info within our system."}
            closeModal={() => { this.closeEditModal() }}
            selectedNo={() => { this.closeEditModal() }}
            selectedYes={() => { this.submitEditModal() }}
          ></ConfirmationModal> : null}
      </>
    )
  }
}

const mapStateToProps = state => ({
  name: state.userResponses.companyName,
  address: state.userResponses.companyAddress,
  city: state.userResponses.companyCity,
  state: state.userResponses.companyState,
  zip: state.userResponses.companyZip,
  MCNumber: state.userResponses.companyMCNumber,
  DOTNumber: state.userResponses.companyDOTNumber,
  signature: state.userResponses.serviceAgreementSignature,
  uid: state.userData.uid,
  ...state.serviceAgreement,
  appDataSettings: state.appDataSettings,
  ...state.appSettings
})

const mapDispatchToProps = dispatch => bindActionCreators({
  checkRequiredFormErrors,
  navigateTo,
  handleUpdateUserResponses,
  setIsValidServiceAgreement,
  unsetIsValidServiceAgreement,
  setformSubmittedServiceAgreement,
  setTypeModal,
  setTextModal,
  setOpenModal
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgreementContainer)