import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Modal } from '@intxlog/iuilib'

//import components
import SplashPage from 'components/SplashPage'

//import utils
import navigateTo from 'utils/navigateTo'

import { setInviteToken } from 'modules/inviteInfo'
import { toggleOpenModal } from 'modules/modal'
import IELAPIUserService from 'services/IELAPIUserService'

export class SplashContainer extends React.Component {
  onClickContinue = () => {
    if (this.props.inviteInfo.valid === true) {
      this.props.navigateTo(`/signup`)
    } else {
      this.props.navigateTo(`/login`)
    }
  }

  async componentDidMount() {
    this.apiService = new IELAPIUserService();

    //Grab the query strings
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const invitetoken = urlParams.get('invitetoken'); //token for invite from iTMS
    const emailtoken = urlParams.get('emailtoken'); //token for verifying user email after sign up

    if (invitetoken) {
      this.props.setInviteToken(invitetoken);
      await this.apiService.validateInvite(invitetoken);
    }

    if (emailtoken) {
      await this.apiService.verifyEmail(emailtoken);
    }
  }

  render() {
    return (
      <>
      <SplashPage
        onClickContinue={this.onClickContinue}
      ></SplashPage>
      <Modal
          {...this.props.modal}
          closeFunc={this.props.toggleOpenModal}
        ></Modal>
        </>
    )
  }
}

const mapStateToProps = state => ({
  inviteInfo: state.inviteInfo,
  modal: state.modal
})

const mapDispatchToProps = dispatch => bindActionCreators({
  navigateTo,
  setInviteToken,
  toggleOpenModal
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SplashContainer)