import React from 'react'
import PropTypes from 'prop-types'

//import components
import { RadioGroup, RadioButton } from '@intxlog/iuilib'
import QuestionWrapper from 'components/QuestionWrapper'

//import css
import styles from './styles.module.scss'

const AdvanceOptions = ({
  handleDispatcherAccessorial,
  handleDispatcherFuel,
  handleDriverAccessorial,
  handleDriverFuel,
  dispatcherAccessorial,
  dispatcherFuel,
  driverAccessorial,
  driverFuel,
  formSubmitted
}) => {
  return (
    <>
      <QuestionWrapper>
        <div className={styles.row}>
          <div className={styles.column}>
            <div className={styles.question}>Can a Comcheck be issued to <span className={styles.type}>DISPATCHERS</span> for:</div>
            <div className={styles.radioWrapper}>
              <span className={styles.chargesHeading}>ACCESSORIAL CHARGES</span>
              <RadioGroup
                name={`dispatcherAccessorial`}
                onChange={handleDispatcherAccessorial}
                direction={`horizontal`}
                required
                defaultValue={dispatcherAccessorial}
                formSubmitted={formSubmitted}
              >
                <RadioButton
                  labelText={`Yes`}
                  value={true}
                ></RadioButton>
                <RadioButton
                  labelText={`No`}
                  value={false}
                ></RadioButton>
              </RadioGroup>
            </div>
            <div className={styles.radioWrapper}>
              <span className={styles.chargesHeading}>FUEL ADVANCES</span>
              <RadioGroup
                name={`dispatcherFuel`}
                onChange={handleDispatcherFuel}
                direction={`horizontal`}
                required
                defaultValue={dispatcherFuel}
                formSubmitted={formSubmitted}
              >
                <RadioButton
                  labelText={`Yes`}
                  value={true}
                ></RadioButton>
                <RadioButton
                  labelText={`No`}
                  value={false}
                ></RadioButton>
              </RadioGroup>
            </div>
          </div>
          <div className={styles.divider}></div>
          <div className={styles.column}>
            <div className={styles.question}>Can a Comcheck be issued to <span className={styles.type}>DRIVERS</span> for:</div>
            <div className={styles.radioWrapper}>
              <span className={styles.chargesHeading}>ACCESSORIAL CHARGES</span>
              <RadioGroup
                name={`driverAccessorial`}
                onChange={handleDriverAccessorial}
                direction={`horizontal`}
                required
                defaultValue={driverAccessorial}
                formSubmitted={formSubmitted}
              >
                <RadioButton
                  labelText={`Yes`}
                  value={true}
                ></RadioButton>
                <RadioButton
                  labelText={`No`}
                  value={false}
                ></RadioButton>
              </RadioGroup>
            </div>
            <div className={styles.radioWrapper}>
              <span className={styles.chargesHeading}>FUEL ADVANCES</span>
              <RadioGroup
                name={`driverFuel`}
                onChange={handleDriverFuel}
                direction={`horizontal`}
                required
                defaultValue={driverFuel}
                formSubmitted={formSubmitted}
              >
                <RadioButton
                  labelText={`Yes`}
                  value={true}
                ></RadioButton>
                <RadioButton
                  labelText={`No`}
                  value={false}
                ></RadioButton>
              </RadioGroup>
            </div>
          </div>
        </div>
      </QuestionWrapper>
    </>
  )
}

AdvanceOptions.propTypes = {
  handleDispatcherAccessorial: PropTypes.func,
  handleDispatcherFuel: PropTypes.func,
  handleDriverAccessorial: PropTypes.func,
  handleDriverFuel: PropTypes.func,
  dispatcherAccessorial: PropTypes.bool,
  dispatcherFuel: PropTypes.bool,
  driverAccessorial: PropTypes.bool,
  driverFuel: PropTypes.bool
}

export default AdvanceOptions
