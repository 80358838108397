import React from 'react'
import { Button } from '@intxlog/iuilib'

//import css
import styles from './styles.module.scss'

const CompanyLookupDuplicateResult = ({
  name,
  mcNumber,
  dotNumber
}) => {
  var permitText = ''
  if (mcNumber) {
    permitText += `MC # ${mcNumber}, `
  }

  if (dotNumber) {
    permitText += `DOT # ${dotNumber}`
  }


  return (
    <div className={styles.container}>
      <p>The carrier {name} ({permitText}) is already registered with IEL and does not need to register again. Please reach out to our Carrier Services Specialists at  <Button text={process.env.REACT_APP_CARRIER_DEPT_PHONE} type="link" elementType="link" linkTo={`tel:+${process.env.REACT_APP_CARRIER_DEPT_PHONE}`} target='_blank' rel="noopener noreferrer" >{process.env.REACT_APP_CARRIER_DEPT_PHONE}</Button> or <Button text={process.env.REACT_APP_CARRIER_SERVICES_EMAIL} type="link" elementType="link" linkTo={`mailto:${process.env.REACT_APP_CARRIER_SERVICES_EMAIL}`}  rel='noopener noreferrer' target='_top'>{process.env.REACT_APP_CARRIER_SERVICES_EMAIL}</Button> so we may assist you with any questions.</p>
    </div>
  )
}

export default CompanyLookupDuplicateResult