import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

//import components
import CompanyProfileQuestions from 'components/CompanyProfileQuestions'

//import utils
import handleUpdateUserResponses from 'utils/handleUpdateUserResponses'

//import actions
import {
  setProfileIsValidCompanyProfileForm,
  unsetProfileIsValidCompanyProfileForm
} from 'modules/companyProfileForm'

export class CompanyProfileQuestionsContainer extends React.Component {
  onNameDBAChange = (val) => {
    this.props.handleUpdateUserResponses({companyDBAName: val})
  }

  onEmailChange = (val) => {
    this.props.handleUpdateUserResponses({companyEmail: val})
  }

  isEmailValid = (val) => {
    if (val) {
      this.props.setProfileIsValidCompanyProfileForm()
    } else {
      this.props.unsetProfileIsValidCompanyProfileForm()
    }
  }

  render(){
    return (
      <CompanyProfileQuestions
        name={this.props.name}
        nameDBA={this.props.nameDBA}
        address={this.props.address}
        city={this.props.city}
        state={this.props.state}
        zip={this.props.zip}
        country={this.props.country}
        contactEmail={this.props.companyEmail}
        accountingPhone={this.props.accountingPhone}
        accountingEmail={this.props.accountingEmail}
        onNameDBAChange={this.onNameDBAChange}
        usesFactoringCompany={this.props.usesFactoringCompany}
        error={() => {return this.props.formSubmitted && !this.props.isProfileValid}}
        onEmailChange={this.onEmailChange}
        isEmailValid={this.isEmailValid}
        disableEmail={this.props.carrierEmail.length > 0 ? true : false}
      ></CompanyProfileQuestions>
    )
  }
}

const mapStateToProps = state => ({
  name: state.userResponses.companyName,
  nameDBA: state.userResponses.companyDBAName,
  address: state.userResponses.companyAddress,
  city: state.userResponses.companyCity,
  state: state.userResponses.companyState,
  zip: state.userResponses.companyZip,
  country: state.userResponses.companyCountry,
  companyEmail: state.userResponses.companyEmail,
  accountingPhone: state.userResponses.accountingPhone,
  accountingEmail: state.userResponses.accountingEmail,
  uid: state.userData.uid,
  usesFactoringCompany: state.userResponses.usesFactoringCompany,
  formSubmitted: state.companyProfileForm.formSubmitted,
  isEquipmentValid: state.companyProfileForm.equipmentTypesIsValid,
  isProfileValid: state.companyProfileForm.profileIsValid,
  carrierEmail: state.carrier.email
})

const mapDispatchToProps = dispatch => bindActionCreators({
  handleUpdateUserResponses,
  setProfileIsValidCompanyProfileForm,
  unsetProfileIsValidCompanyProfileForm
}, dispatch)

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(CompanyProfileQuestionsContainer)