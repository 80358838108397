import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

//import components
import QuestionPageTemplate from 'components/QuestionPageTemplate'
import TaxForm from 'components/TaxForm'
import TaxFormResponseMessage from 'components/TaxFormResponseMessage'

//import utils
import navigateTo from 'utils/navigateTo'
import handleUpdateUserResponses from 'utils/handleUpdateUserResponses'
import checkRequiredFormErrors from 'utils/checkRequiredFormErrors'

//import helpers
import validate from 'helpers/checkObjForValid'

//import action creators
import {
  setNameIsValidTaxForm,
  unsetNameIsValidTaxForm,
  setAddressIsValidTaxForm,
  unsetAddressIsValidTaxForm,
  setCityIsValidTaxForm,
  unsetCityIsValidTaxForm,
  setStateIsValidTaxForm,
  unsetStateIsValidTaxForm,
  setZipIsValidTaxForm,
  unsetZipIsValidTaxForm,
  setSignatureIsValidTaxForm,
  unsetSignatureIsValidTaxForm,
  setFormIsSubmittedTaxForm
} from 'modules/taxForm'

export class TaxFormContainer extends React.Component {
  handleProceed = () => {
    //if the carrier is from canada then we just need to submit the form
    if (this.props.country === 'CA') {
      this.props.navigateTo('/agreement')
    } else { // else
      //check to see if everything is valid
      if (validate(this.props.validation)) {
        this.props.navigateTo('/agreement')
      } else {//the fields are not valid
        this.props.checkRequiredFormErrors()
        this.props.setFormIsSubmittedTaxForm()
      }
    } //end if
  }

  handleBack = () => {
    //navigate to the page
    this.props.navigateTo(`/remit-information`)
  }

  onNameChange = (val) => {
    this.props.handleUpdateUserResponses({w9Name: val})
  }

  onBusinessNameChange = (val) => {
    this.props.handleUpdateUserResponses({w9BusinessName: val})
  }

  onTaxClassificationChange = (val) => {
    this.props.handleUpdateUserResponses({w9TaxClassification: val})
  }

  onAddressChange = (val) => {
    this.props.handleUpdateUserResponses({w9Address: val})
  }

  onCityChange = (val) => {
    this.props.handleUpdateUserResponses({w9City: val})
  }

  onStateChange = (val) => {
    this.props.handleUpdateUserResponses({w9State: val})
  }

  onZipChange = (val) => {
    this.props.handleUpdateUserResponses({w9Zip: val})
  }

  onSSNChange = (val) => {
    this.props.handleUpdateUserResponses({w9SSN: val})
  }

  onEINChange = (val) => {
    this.props.handleUpdateUserResponses({w9EIN: val})
  }

  onSignatureChange = (val) => {
    this.props.handleUpdateUserResponses({w9Signature: val})
  }

  onNameIsValid = (isValid) => {
    // shorthand if statement
    isValid ? this.props.setNameIsValidTaxForm() : this.props.unsetNameIsValidTaxForm()
  }

  onAddressIsValid = (isValid) => {
    //shorthand if statement
    isValid ? this.props.setAddressIsValidTaxForm() : this.props.unsetAddressIsValidTaxForm()
  }

  onCityIsValid = (isValid) => {
    //shorthand if statement
    isValid ? this.props.setCityIsValidTaxForm() : this.props.unsetCityIsValidTaxForm()
  }

  onStateIsValid = (isValid) => {
    //shorthand if statement
    isValid ? this.props.setStateIsValidTaxForm() : this.props.unsetStateIsValidTaxForm()
  }

  onZipIsValid = (isValid) => {
    //shorthand if statement
    isValid ? this.props.setZipIsValidTaxForm() : this.props.unsetZipIsValidTaxForm()
  }

  onSignatureIsValid = (isValid) => {
    //shorthand if statement
    isValid ? this.props.setSignatureIsValidTaxForm() : this.props.unsetSignatureIsValidTaxForm()
  }

  render(){
    let title = `W-9 Tax Information`
    let infoText = `Please complete the following information regarding your w-9. Fields marked with an * are required.`
    let isFromCanada = false
    let content = null

    //is from canada? logic
    if (this.props.country === `CA`) {
      isFromCanada = true
    }

    //logic for title infoText wording
    if (isFromCanada) {
      title = `W-8 Tax Information`
      infoText = null

      content = <TaxFormResponseMessage></TaxFormResponseMessage>
    } else { // else isFromCanada
      content = <TaxForm
        name={this.props.name}
        businessName={this.props.businessName}
        taxClassification={this.props.taxClassification}
        address={this.props.address}
        city={this.props.city}
        state={this.props.state}
        zip={this.props.zip}
        ssn={this.props.ssn}
        ein={this.props.ein}
        signature={this.props.signature}
        date={this.props.date}
        onNameChange={this.onNameChange}
        onBusinessNameChange={this.onBusinessNameChange}
        onTaxClassificationChange={this.onTaxClassificationChange}
        onAddressChange={this.onAddressChange}
        onCityChange={this.onCityChange}
        onStateChange={this.onStateChange}
        onZipChange={this.onZipChange}
        onSSNChange={this.onSSNChange}
        onEINChange={this.onEINChange}
        onSignatureChange={this.onSignatureChange}
        onDateChange={this.onDateChange}
        onNameIsValid={this.onNameIsValid}
        onAddressIsValid={this.onAddressIsValid}
        onCityIsValid={this.onCityIsValid}
        onStateIsValid={this.onStateIsValid}
        onZipIsValid={this.onZipIsValid}
        onSignatureIsValid={this.onSignatureIsValid}
        onDateIsValid={this.onDateIsValid}
        formSubmitted={this.props.formSubmitted}
      ></TaxForm>
    } // end isFromCanada if

    return (
      <QuestionPageTemplate
        title={title}
        infoText={infoText}
        prevText={`Back to Remit Information`}
        prevFunction={this.handleBack}
        nextText={`Proceed to Carrier/Broker Agreement`}
        nextFunction={this.handleProceed}
        isEditing={this.props.isEditing}
        disabled={this.props.isEditing}
      >
        {content}
      </QuestionPageTemplate>
    )
  }
}

const mapStateToProps = state => ({
  name: state.userResponses.w9Name,
  businessName: state.userResponses.w9BusinessName,
  taxClassification: state.userResponses.w9TaxClassification,
  address: state.userResponses.w9Address,
  city: state.userResponses.w9City,
  state: state.userResponses.w9State,
  zip: state.userResponses.w9Zip,
  ssn: state.userResponses.w9SSN,
  ein: state.userResponses.w9EIN,
  signature: state.userResponses.w9Signature,
  date: state.userResponses.w9Date,
  country: state.userResponses.companyCountry,
  uid: state.userData.uid,
  usesFactoringCompany: state.userResponses.usesFactoringCompany,
  formSubmitted: state.taxForm.formSubmitted,
  validation: {
    nameIsValid: state.taxForm.nameIsValid,
    classificationIsValid: state.taxForm.classificationIsValid,
    classificationLLCisValid: state.taxForm.classificationLLCisValid,
    classificationOtherIsValid: state.taxForm.classificationOtherIsValid,
    addressIsValid: state.taxForm.addressIsValid,
    cityIsValid: state.taxForm.cityIsValid,
    stateIsValid: state.taxForm.stateIsValid,
    zipIsValid: state.taxForm.zipIsValid,
    einIsValid: state.taxForm.einIsValid,
    signatureIsValid: state.taxForm.signatureIsValid
  },
  ...state.appSettings
})

const mapDispatchToProps = dispatch => bindActionCreators({
  checkRequiredFormErrors,
  navigateTo,
  handleUpdateUserResponses,
  setNameIsValidTaxForm,
  unsetNameIsValidTaxForm,
  setAddressIsValidTaxForm,
  unsetAddressIsValidTaxForm,
  setCityIsValidTaxForm,
  unsetCityIsValidTaxForm,
  setStateIsValidTaxForm,
  unsetStateIsValidTaxForm,
  setZipIsValidTaxForm,
  unsetZipIsValidTaxForm,
  setSignatureIsValidTaxForm,
  unsetSignatureIsValidTaxForm,
  setFormIsSubmittedTaxForm
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaxFormContainer)
