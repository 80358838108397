import React from 'react'
import PropTypes from 'prop-types'

import logoImage from 'assets/images/logo.png' 

//import styles
import styles from './styles.module.scss'

class Logo extends React.Component {
  render(){
    //inline styles
    let inlineStyles = {
      width: this.props.width
    }

    return (
      <div className={styles.container} style={inlineStyles}>
        <img src={logoImage} alt=""/>
      </div>
    )
  }
}

//rules for props being passed in
Logo.propTypes = {
  width: PropTypes.number
}

// Specifies the default values for props:
Logo.defaultProps = {
  width: 100
}

export default Logo