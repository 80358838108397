import React from 'react'

//import components
import { SiteContainer } from '@intxlog/iuilib'

//import styles
import styles from './styles.module.scss'

import pdf from 'assets/pdf/carrierRelationsPacket.pdf'

const ThankYou = () => {
  return (
    <SiteContainer>
      <div className={styles.container}>
        <p>Congratulations on your new carrier setup with Integrity Express Logistics!
            A member of our Carrier Services Team may contact you for final verification.
            We are excited about partnering with your carrier team to provide the best in class service to our customers.
            You will also receive an email, including our Welcome Packet,
            which will give you more information about IEL and help you understand our policies and procedures.
            </p>
        <a href={pdf} download>Click Here to download the Welcome Packet</a>
        <p className={styles.signed}>Thank you,<br/>IEL Carrier Relations</p>
      </div>
    </SiteContainer>
  )
}

export default ThankYou
