import React from 'react'
import { Route, Switch } from 'react-router-dom'

//import containers
import HomeContainer from 'containers/HomeContainer'
import AdminContainer from 'containers/AdminContainer'
import HomeContainerFromInvite from 'containers/HomeContainerFromInvite'

const LoggedInUserNotConfirmedRouter = (props) => {
  let homeContainer = props.isFromInvite ? HomeContainerFromInvite : HomeContainer

  return (
    <Switch>
      {process.env.REACT_APP_ENV !== `prod` &&
        <Route path='/admin' component={AdminContainer} />
      }
      <Route component={homeContainer} />
    </Switch>
  )
}

export default LoggedInUserNotConfirmedRouter