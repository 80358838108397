
import * as modal from 'modules/modal'

export default function checkRequiredFormErrors() {
  return dispatch => {
    dispatch(modal.setTypeModal(`error`))
    dispatch(modal.setTextModal('Please review your form for empty Required information!'))
    dispatch(modal.toggleOpenModal())

  }
}

