import { updateUserResponses } from 'modules/userResponses'
import IELAPIUserService from "services/IELAPIUserService"

import store from 'store'

export default function handleUpdateUserResponses(payload) {
  return dispatch => {
    const userService = new IELAPIUserService();

    //Send the payload to redux, and then grab the state from redux and send it all to the database to update all fields
    //Since we should have the latest fields set in redux
    dispatch(updateUserResponses(payload))

    const responses = store.getState().userResponses

    userService.updateUserResponses(responses)
  }
}