import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

//import components
import Admin from 'components/Admin'

//import utils
import logUserOut from 'utils/logUserOut'
import resetUser from 'utils/resetUser'

export class AdminContainer extends React.Component {
  render(){
    return (
      <Admin
        logoutFunc={this.props.logUserOut}
        resetUserFunc={this.props.resetUser}
      ></Admin>
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => bindActionCreators({
  logUserOut,
  resetUser
}, dispatch)

export default withRouter(connect(
  mapStateToProps, 
  mapDispatchToProps
)(AdminContainer))