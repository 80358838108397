import React from 'react'
import PropTypes from 'prop-types'

//import components
import RadioGroup from 'components/RadioGroupUpd'
import RadioButtonUpd from 'components/RadioButtonUpd'
import { RadioButton } from '@intxlog/iuilib'
import QuestionWrapper from 'components/QuestionWrapper'


//import css
import styles from './styles.module.scss'

const PaymentOptions = ({
  usesFactoringCompany,
  factoringChangeFunction,
  factoringInfo,
  quickPayComponent,
  formSubmitted,
  disabled
}) => {
  return (
    <>
      <QuestionWrapper>
        <div className={styles.question}>Do you use a factoring company?</div>
        <div className={styles.radioWrapper}>
          <RadioGroup
            name={`factoring`}
            onChange={factoringChangeFunction}
            direction={`horizontal`}
            required
            defaultValue={usesFactoringCompany}
            formSubmitted={formSubmitted}
          >            
            <RadioButtonUpd
              disabled={disabled}
              labelText={`Yes`}
              value={true}
            />
          <RadioButton
              labelText={`No`}
              value={false}
            />
          </RadioGroup>
        </div>
        {factoringInfo}
        </QuestionWrapper>
      {quickPayComponent}
    </>
  )
}

//rules for props being passed in
PaymentOptions.propTypes = {
  usesFactoringCompany: PropTypes.bool,
  factoringChangeFunction: PropTypes.func,
  quickPayChangeFunction: PropTypes.func,
  factoringInfo: PropTypes.object,
  quickPayComponent: PropTypes.object,
  disabled: PropTypes.bool
}
//
// // Specifies the default values for props:
// PaymentOptions.defaultProps = {
//
// }

export default PaymentOptions
