import React, { useEffect } from 'react'

//import css
import styles from './styles.module.scss'

export default function SplashPage () {
  const highwayURL = "https://gohighway.com/go/integrity-express"

  useEffect(() => {
    setTimeout(() => {
      window.location = highwayURL
    }, 10000)
  }, [])

  return <div className={styles.container}>
    <h1>Carrier Onboarding Registration Now On Highway</h1>
    <p>The IEL Carrier Onboarding Portal has been decommissioned. The carrier registration services
      have moved to the new IEL Highway portal.</p>
    <p>You will be automatically redirected to this new portal momentarily. 
      To access the Highway portal now, go to <a href={highwayURL}>{highwayURL}</a>.</p>
  </div>
}