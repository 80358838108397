import React from 'react'
import PropTypes from 'prop-types'

//import components
import { SiteContainer, Button } from '@intxlog/iuilib'

//import css
import styles from './styles.module.scss'

//render prop component to be used for formatting other components
class Admin extends React.Component {
  handleTokenClick = () => {
    // auth.currentUser.getIdToken(true).then((idToken) => {
    //   console.log(`User Token: ${idToken}`)
    // })
  }

  handleDeleteClick = () => {
    // let user = auth.currentUser
    // user.delete().then(() => {  
    //   db.ref(`users/${user.uid}`).remove() 
    // }).catch((error) => {
    //   console.error(`An error occurred trying to delete the user: ${error}`)
    // })
  }

  testError = () => {
    throw new Error('This is just a test!')
  }

  render(){
    return (
      <SiteContainer>
        <h1>Admin Console</h1>
        <div className={styles.container}>
          <Button
            text={`Restart User Flow`}
            onClick={(this.props.resetUserFunc)}
          ></Button>
          <Button
            text={`Delete User`}
            onClick={this.handleDeleteClick}
          ></Button>
          <Button
            text={`Get User Token`}
            onClick={this.handleTokenClick}
          ></Button>
          <Button
            text={`Logout`}
            onClick={this.props.logoutFunc}
          ></Button>
          <Button
            text={`Test Error`}
            onClick={this.testError}
          ></Button>
        </div>
      </SiteContainer>
    )
  }
}

//rules for props being passed in
Admin.propTypes = {
  logoutFunc: PropTypes.func,
  resetUserFunc: PropTypes.func
}

// // Specifies the default values for props:
// Admin.defaultProps = {
//
// }

export default Admin