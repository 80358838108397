import React from 'react'

import { Button } from '@intxlog/iuilib'

//import styles
import styles from './styles.module.scss'

function EditingMessage() {

    return (
        <div className={styles.info}>
            <b>NOTE:</b> Only Advance Options can be edited at this time. If you would like to edit other info, please reach out to our Carrier Services Specialists at  <Button text={process.env.REACT_APP_CARRIER_DEPT_PHONE} type="link" elementType="link" linkTo={`tel:+${process.env.REACT_APP_CARRIER_DEPT_PHONE}`} target='_blank' rel="noopener noreferrer" >{process.env.REACT_APP_CARRIER_DEPT_PHONE}</Button> or <Button text={process.env.REACT_APP_CARRIER_SERVICES_EMAIL} type="link" elementType="link" linkTo={`mailto:${process.env.REACT_APP_CARRIER_SERVICES_EMAIL}`}  rel='noopener noreferrer' target='_top'>{process.env.REACT_APP_CARRIER_SERVICES_EMAIL}</Button>.
        </div>
    )
}

export default EditingMessage