export default function checkObjForValid(valObj) {
  let formIsValid = true

  //find if all values in the validation object are true
  for (const key in valObj) {
    const result = valObj[key]
    
    if (!result) {
      formIsValid = false
    }
  }

  return formIsValid 
}