import { combineReducers } from 'redux'

import appSettings from 'modules/appSettings'
import appDataSettings from 'modules/appDataSettings'
import auth from 'modules/auth'
import carrier from 'modules/carrier'
import companyLookup from 'modules/companyLookup'
import companyProfileForm from 'modules/companyProfileForm'
import equipmentTypes from 'modules/equipmentTypes'
import inviteInfo from 'modules/inviteInfo'
import loginForm from 'modules/loginForm'
import modal from 'modules/modal'
import saferWatchResponse from 'modules/saferWatchResponse'
import serviceAgreement from 'modules/serviceAgreement'
import taxForm from 'modules/taxForm'
import userData from 'modules/userData'
import userResponses from 'modules/userResponses'
import remitInfoForm from 'modules/remitInfoForm'

export default combineReducers({
  appSettings,
  appDataSettings,
  auth,
  carrier,
  companyLookup,
  companyProfileForm,
  equipmentTypes,
  inviteInfo,
  loginForm,
  modal,
  saferWatchResponse,
  serviceAgreement,
  remitInfoForm,
  taxForm,
  userData,
  userResponses
})
