import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'

//import components
import {RadioGroup} from '@intxlog/iuilib'

//import css
// import styles from './styles.module.scss'

//render prop component to be used for formatting other components
const ChooseCIDtype = ({
  onChange, defaultValue
}) => {
  const [value, setValue] = useState();

  useEffect(()=> {
    setValue(defaultValue)
  }, [defaultValue]);

  return (
    <RadioGroup
        direction={`horizontal`}
        name={'CIDType'}
    >
      <label htmlFor="MC"><input type="radio" value="MC" id="MC" checked={value === 'MC'} onChange={e => onChange(e.currentTarget.value)}/>  MC #</label>
      <label htmlFor="DOT"><input type="radio"value="DOT" id="DOT" checked={value === 'DOT'} onChange={e => onChange(e.currentTarget.value)}/>  DOT #</label>
    </RadioGroup>
  )
}

//rules for props being passed in
ChooseCIDtype.propTypes = {
  onChange: PropTypes.func
}
//
// // Specifies the default values for props:
// ChooseLookupNumberType.defaultProps = {
//
// }

export default ChooseCIDtype
