import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

//import components
import PayToInfo from 'components/PayToInfo'
import QuestionPageTemplate from 'components/QuestionPageTemplate'

//import utils
import navigateTo from 'utils/navigateTo'
import handleUpdateUserResponses from 'utils/handleUpdateUserResponses'
import checkRequiredFormErrors from 'utils/checkRequiredFormErrors'

//import helpers
import validate from 'helpers/checkObjForValid'

//import action creators
import {
    setNameIsValidRemitInfoForm,
    unsetNameIsValidRemitInfoForm,
    setAddressIsValidRemitInfoForm,
    unsetAddressIsValidRemitInfoForm,
    setCityIsValidRemitInfoForm,
    unsetCityIsValidRemitInfoForm,
    setStateIsValidRemitInfoForm,
    unsetStateIsValidRemitInfoForm,
    setZipIsValidRemitInfoForm,
    unsetZipIsValidRemitInfoForm,
    setCountryIsValidRemitInfoForm,
    unsetCountryIsValidRemitInfoForm,
    setEmailIsValidRemitInfoForm,
    unsetEmailIsValidRemitInfoForm,
    setPhoneIsValidRemitInfoForm,
    unsetPhoneIsValidRemitInfoForm,
    setFormIsSubmittedRemitInfoForm,
    unsetFormIsSubmittedRemitInfoForm
} from 'modules/remitInfoForm'

export class PayToContainer extends React.Component {

    handleProceed = () => {
        //check to make sure the the required fields have values
        if (validate(this.props.validation)) {
            //let the user proceed
            this.props.navigateTo('/tax-information')
        } else {
            this.props.checkRequiredFormErrors()
            this.props.setFormIsSubmittedRemitInfoForm()
        }
    }

    handleBack = () => {
        //navigate to the page
        this.props.navigateTo('/company-profile')
    }

    onCompanyNameChange = (val) => {
        this.props.handleUpdateUserResponses({remitCompanyName: val})
    }

    onAddressChange = (val) => {
        this.props.handleUpdateUserResponses({remitCompanyAddress: val})
    }

    onCityChange = (val) => {
        this.props.handleUpdateUserResponses({remitCompanyCity: val})
    }

    onStateChange = (val) => {
        this.props.handleUpdateUserResponses({remitCompanyState: val})
    }

    onZipChange = (val) => {
        this.props.handleUpdateUserResponses({remitCompanyZip: val})
    }

    onCountryChange = (val) => {
        this.props.handleUpdateUserResponses({remitCompanyCountry: val})
    }

    onPhoneChange = (val) => {
        this.props.handleUpdateUserResponses({remitCompanyPhone: val})
    }

    onEmailChange = (val) => {
        this.props.handleUpdateUserResponses({remitCompanyEmail: val})
    }

    onFaxChange = (val) => {
        this.props.handleUpdateUserResponses({remitCompanyFax: val})
    }

    onFirstNameChange = (val) => {
        this.props.handleUpdateUserResponses({remitCompanyPayToFirstName: val})
    }

    onLastNameChange = (val) => {
        this.props.handleUpdateUserResponses({remitCompanyPayToLastName: val})
    }

    onNameIsValid = (isValid) => {
        // shorthand if statement
        isValid ? this.props.setNameIsValidRemitInfoForm() : this.props.unsetNameIsValidRemitInfoForm()
    }

    onAddressIsValid = (isValid) => {
        //shorthand if statement
        isValid ? this.props.setAddressIsValidRemitInfoForm() : this.props.unsetAddressIsValidRemitInfoForm()
    }

    onCityIsValid = (isValid) => {
        //shorthand if statement
        isValid ? this.props.setCityIsValidRemitInfoForm() : this.props.unsetCityIsValidRemitInfoForm()
    }

    onStateIsValid = (isValid) => {
        //shorthand if statement
        isValid ? this.props.setStateIsValidRemitInfoForm() : this.props.unsetStateIsValidRemitInfoForm()
    }

    onZipIsValid = (isValid) => {
        //shorthand if statement
        isValid ? this.props.setZipIsValidRemitInfoForm() : this.props.unsetZipIsValidRemitInfoForm()
    }

    onCountryIsValid = (isValid) => {
        //shorthand if statement
        isValid ? this.props.setCountryIsValidRemitInfoForm() : this.props.unsetCountryIsValidRemitInfoForm()
    }

    onPhoneIsValid = (isValid) => {
        //shorthand if statement
        isValid ? this.props.setPhoneIsValidRemitInfoForm() : this.props.unsetPhoneIsValidRemitInfoForm()
    }

    onEmailIsValid = (isValid) => {
        //shorthand if statement
        isValid ? this.props.setEmailIsValidRemitInfoForm() : this.props.unsetEmailIsValidRemitInfoForm()
    }

    render() {
        return (
            <QuestionPageTemplate
                title={`Remit Information`}
                infoText={`Please complete the following about your Pay To information. Fields marked with an * are required.`}
                prevText={`Back to Company Profile`}
                prevFunction={this.handleBack}
                nextText={`Proceed to Tax Information`}
                nextFunction={this.handleProceed}
                isEditing={this.props.isEditing}
                disabled={this.props.isEditing}
            >
                <PayToInfo
                    companyName={this.props.companyName}
                    address={this.props.address}
                    city={this.props.city}
                    state={this.props.state}
                    zip={this.props.zip}
                    country={this.props.country}
                    email={this.props.email}
                    fax={this.props.fax}
                    phone={this.props.phone}
                    firstName={this.props.firstName}
                    lastName={this.props.lastName}
                    onCompanyNameChange={this.onCompanyNameChange}
                    onAddressChange={this.onAddressChange}
                    onCityChange={this.onCityChange}
                    onStateChange={this.onStateChange}
                    onZipChange={this.onZipChange}
                    onCountryChange={this.onCountryChange}
                    onFirstNameChange={this.onFirstNameChange}
                    onLastNameChange={this.onLastNameChange}
                    onEmailChange={this.onEmailChange}
                    onPhoneChange={this.onPhoneChange}
                    onFaxChange={this.onFaxChange}
                    onNameIsValid={this.onNameIsValid}
                    onAddressIsValid={this.onAddressIsValid}
                    onCityIsValid={this.onCityIsValid}
                    onStateIsValid={this.onStateIsValid}
                    onZipIsValid={this.onZipIsValid}
                    onCountryIsValid={this.onCountryIsValid}
                    onPhoneIsValid={this.onPhoneIsValid}
                    onEmailIsValid={this.onEmailIsValid}
                    formSubmitted={this.props.formSubmitted}
                ></PayToInfo>
            </QuestionPageTemplate>
        )
    }
}

const mapStateToProps = state => ({
    uid: state.userData.uid,
    companyName: state.userResponses.remitCompanyName,
    address: state.userResponses.remitCompanyAddress,
    city: state.userResponses.remitCompanyCity,
    state: state.userResponses.remitCompanyState,
    zip: state.userResponses.remitCompanyZip,
    country: state.userResponses.remitCompanyCountry,
    phone: state.userResponses.remitCompanyPhone,
    fax: state.userResponses.remitCompanyFax,
    email: state.userResponses.remitCompanyEmail,
    firstName: state.userResponses.remitCompanyPayToFirstName,
    lastName: state.userResponses.remitCompanyPayToLastName,
    validation: {
        nameIsValid: state.remitInfoForm.nameIsValid,
        addressIsValid: state.remitInfoForm.addressIsValid,
        cityIsValid: state.remitInfoForm.cityIsValid,
        stateIsValid: state.remitInfoForm.stateIsValid,
        zipIsValid: state.remitInfoForm.zipIsValid,
        countryIsValid: state.remitInfoForm.countryIsValid,
        phoneIsValid: state.remitInfoForm.phoneIsValid,
        emailIsValid: state.remitInfoForm.emailIsValid,
    },
    ...state.appSettings
})

const mapDispatchToProps = dispatch => bindActionCreators({
    checkRequiredFormErrors,
    navigateTo,
    handleUpdateUserResponses,
    setNameIsValidRemitInfoForm,
    unsetNameIsValidRemitInfoForm,
    setAddressIsValidRemitInfoForm,
    unsetAddressIsValidRemitInfoForm,
    setCityIsValidRemitInfoForm,
    unsetCityIsValidRemitInfoForm,
    setStateIsValidRemitInfoForm,
    unsetStateIsValidRemitInfoForm,
    setZipIsValidRemitInfoForm,
    unsetZipIsValidRemitInfoForm,
    setCountryIsValidRemitInfoForm,
    unsetCountryIsValidRemitInfoForm,
    setEmailIsValidRemitInfoForm,
    unsetEmailIsValidRemitInfoForm,
    setPhoneIsValidRemitInfoForm,
    unsetPhoneIsValidRemitInfoForm,
    setFormIsSubmittedRemitInfoForm,
    unsetFormIsSubmittedRemitInfoForm,
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PayToContainer)
