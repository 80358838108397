import { resetAppDataSettings } from 'modules/appDataSettings'
import { resetAppSettings } from 'modules/appSettings'
import { resetAuth } from 'modules/auth'
import { resetCarrier } from 'modules/carrier'
import { resetCompanyLookup } from 'modules/companyLookup'
import { resetCompanyProfileForm } from 'modules/companyProfileForm'
import { resetEquipmentTypes } from 'modules/equipmentTypes'
import { resetInviteInfo } from 'modules/inviteInfo'
import { resetLoginForm } from 'modules/loginForm'
import { resetModal } from 'modules/modal'
import { resetRemitInfoForm } from 'modules/remitInfoForm'
import { resetTaxForm } from 'modules/taxForm'
import { resetSaferWatchResponse } from 'modules/saferWatchResponse'
import { resetServiceAgreement } from 'modules/serviceAgreement'
import { resetUserData } from 'modules/userData'
import { resetUserResponses } from 'modules/userResponses'


export default function resetState() {
    return dispatch => {
        dispatch(resetAppDataSettings())
        dispatch(resetAppSettings())
        dispatch(resetAuth())
        dispatch(resetCarrier())
        dispatch(resetCompanyLookup())
        dispatch(resetCompanyProfileForm())
        dispatch(resetEquipmentTypes())
        dispatch(resetInviteInfo())
        dispatch(resetLoginForm())
        dispatch(resetModal())
        dispatch(resetRemitInfoForm())
        dispatch(resetTaxForm())
        dispatch(resetSaferWatchResponse())
        dispatch(resetServiceAgreement())
        dispatch(resetUserData())
        dispatch(resetUserResponses())
    }
}