import { createStore, applyMiddleware, compose } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import {createBrowserHistory} from 'history'
import rootReducer from './modules'

// Logger with default options
import logger from 'redux-logger'

export const history = createBrowserHistory()

const initialState = {}
const enhancers = []
const middleware = [
  thunk,
  routerMiddleware(history)
]


// only add redux dev tools and redux logger on non prod builds
if (process.env.REACT_APP_ENV !== `prod`) {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === `function`) {
    enhancers.push(devToolsExtension())
  }

  //add in the logger
  middleware.push(logger)
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

const store = createStore(
  connectRouter(history)(rootReducer),
  initialState,
  composedEnhancers
)

export default store
