import React from 'react'
import PropTypes from 'prop-types'

//import components
import { Input } from '@intxlog/iuilib'
import LabelWrapper from 'components/LabelWrapper'

//import css
import styles from './styles.module.scss'

const ServiceAgreement = ({
  name,
  address,
  city,
  state,
  zip,
  MCNumber,
  DOTNumber,
  getDay,
  getMonth,
  formSubmitted,
  onSignatureChange,
  onSignatureValid,
  signature
}) => {
  return (
    <>
      <div className={styles.agreementWrapper}>
        <p>BROKER-CARRIER AGREEMENT</p>
        <p>This Broker-Carrier Agreement (the "Agreement") is made and intended to be effective this <span className={styles.highlight}>{getDay()}</span> day of <span className={styles.highlight}>{getMonth()}</span>, (the "Effective Date"), by and between Integrity Express Logistics, LLC, an Ohio Limited Liability Company whose main office is located in Blue Ash, Ohio ("Broker") and {name} whose main office is located at <span className={styles.highlight}>{address}</span>. ("Carrier"), collectively the "Parties."</p>
        <p>RECITALS</p>
        <p>WHEREAS, Broker is licensed by the Federal Motor Carrier Safety Administration in Docket No. MC-596655 to engage in operations, in interstate or foreign commerce, as a broker, arranging for transportation of freight (except household goods), and as a broker arranges transportation services for various consignors, consignees, motor carriers and/or other third parties (hereinafter individually or collectively "Shippers," and each a "Shipper");</p>
        <p>WHEREAS, Carrier holds motor carrier operating authority from the Federal Motor Carrier Safety Administration (FMCSA) in Certificate No. MC-<span className={styles.highlightRed}>MC# {MCNumber} </span>  and, or, Permit/Certificate No. DOT-<span className={styles.highlightRed}>DOT# {DOTNumber}</span> to engage in transportation as a common or contract carrier of property (except household goods) under contracts with shippers and receivers and/or brokers of general commodities, and shall transport said property under its own operating authority and subject to the terms of this Agreement, and makes the representations herein for the purpose of inducing Broker to enter into this agreement: WHEREAS, Broker, to satisfy some of the freight transportation needs of its Shippers, desires to use the services of Carrier on a non-exclusive basis.</p>
        <p>NOW, THEREFORE, for good and valuable consideration, the Parties agree as follows:</p>
        <p>AGREEMENT</p>
        <p>1. TERM.  The term of this Agreement shall be one (1) year, commencing on the date first mentioned above. This Agreement shall automatically renew itself for successive one year periods. Notwithstanding the foregoing, either Party may terminate this Agreement on 30 days prior written notice, at any time, to the other Party, with or without cause, or as otherwise provided in this Agreement. In the event that either Party chooses to terminate this Agreement, pursuant to this Paragraph 1, the Parties shall still be obligated to: (a) complete performance of any work in progress, including, without limitation, loads in transit or shipments contracted for and waiting for transport at the time of termination, in accordance with the terms of this Agreement; and (b) pay any outstanding claims for services fully and properly rendered, or for damaged, lost or delayed shipments or claims resulting from services not properly performed under this Agreement or under law, before, on or after the time of termination in accordance with the terms of this Agreement.</p>
        <p>2. CARRIER'S COVENANTS. In performing transportation services hereunder, Carrier agrees that it shall, at all times and at its own expense, provide and maintain:</p>
        <p>(a) Driver(s) with enough available hours of service to pick up and complete delivery of the tendered load(s) within time frame(s) requested by Broker and/or its Shipper(s), without violating the FMCSA hours of service regulations contained at 49 C.F.R. ? 395;</p>
        <p>(b) Certified, registered Electronic Logging Devices (“ELDs”) in each vehicle that comply with requirements of the ELD regulations set forth in Section 32301(b) of the Commercial Motor Vehicle Safety Enhancement Act, enacted as part of MAP-21, (Pub. L. 112-141, 126 Stat. 405, 786-788, July 6, 2012), which are registered with the Federal Motor Carrier Safety Administration on or before Carrier is mandated by law to do so;</p>
        <p>(c) Compliance during the term of this Agreement, with all applicable federal, state and local laws and Broker requirements relating to the Carrier’s provision of its services including, but not limited to: transportation of Hazardous Materials, (including the licensing and training of drivers), as defined in 49 C.F.R. ? 172.800, ? 173, and ? 397 et seq., to the extent that any shipments hereunder constitute Hazardous Materials; security regulations; owner/operator lease regulations (lease documents to be provided to Broker upon request); regulations regarding the loading and securing of freight; federal, state and local environmental regulation; federal, state and local emission standards for all equipment utilized by Carrier; sanitation, temperature, and contamination requirements for transporting food, perishable, and other products (all in accordance with the Food Safety Modernization Act, the Sanitary Food Transportation Act of 2005 and the FDA’s Final Rule pertaining to Sanitary Transportation of Human and Animal Food); implementation and maintenance of equipment safety regulations; maintenance and control of the means and method of transportation including, but not limited to, performance of its drivers, completion of all routine and out of the ordinary vehicle maintenance necessary to ensure that all equipment at all times meets or exceeds all operational standards set by Broker or its customers, all applicable insurance laws and regulations, including, but not limited to, workers' compensation (all vehicle maintenance and driver performance and certification records to be provided to Broker no less than monthly), and ensuring that all vehicles and equipment utilized to provide services under this Agreement are registered with Carrier’s insurance providers under the required insurance policies;</p>
        <p>(d) Compliance during the term of this Agreement, with all applicable federal, state and local laws relating to the implementation and maintenance of driver safety regulations including, but not limited to, hiring, controlled substances, and hours of service regulations, qualification and licensing and training of drivers, including, but not limited to only utilizing drivers who (i) have been fully trained in the requirements imposed by the Federal Motor Carrier Safety Regulations contained in 49 C.F.R. Parts 390-397, and (ii) who hold a current commercial driver's license, as mandated by 49 C.F.R. Part 383, reflecting on its face any requisite hazardous materials endorsement and/or tank vehicle endorsement;</p>
        <p>(e) Responsibility to furnish all fuel, oil, tires and other parts, supplies and equipment necessary or required by Shipper(s) for the safe and efficient operation and maintenance of all equipment utilized in transporting a Shipper's freight;</p>
        <p>(f) Responsibility for any and all management, governing, discipline, direction and control of its employees, owner/operators, and equipment with respect to operating within all applicable federal and state legal and regulatory requirements to ensure the safe operation of Carrier's vehicles, drivers and facilities. Carrier and Broker agree that safe and legal operation of the Carrier and its drivers shall completely and without question govern and supersede any service requests, demands. preferences, instructions, or information from Broker or Broker's Shippers with respect to any shipment at any time;</p>
        <p>(g) Carrier will notify Broker immediately if its federal operating authority is revoked, suspended or rendered inactive for any reason; and/or if it is sold, or if there is a change in control of ownership, and/or any insurance required hereunder is threatened to be or is terminated, cancelled, suspended, or revoked for any reason. If Carrier performs any services for which it does not or no longer possesses proper authority, Carrier shall be solely and entirely responsible for all claims arising from such services; and Carrier shall hold Broker, Broker's Shippers and all affiliates of Broker and Broker’s Shippers harmless under the terms of Section 10 of this Agreement;</p>
        <p>(h) Carrier authorizes Broker to invoice Carrier's freight charges to Shippers, consignees, or third-parties responsible for payment. 3. BROKER'S COVENANTS. Broker warrants that it has authority to tender its Shippers' freight for transportation under this Agreement. Broker is not restricted from tendering freight to other carriers; Carrier is not restricted from performing transportation for parties other than Broker.</p>
        <p>4. COMPENSATION. Carrier agrees to transport freight for Broker, under the terms of its carrier authority, at a rate mutually agreed upon in writing, by fax, or by electronic means, contained in Broker's Load Confirmation or Rate Confirmation forms. Additionally:</p>
        <p>(a) Any rates, which may be verbally agreed upon, shall be deemed confirmed in writing where Carrier has billed the agreed rate and Broker has paid it. Rates or charges, including but not limited to stop-offs, detention, loading or unloading, fuel surcharges, or other accessorial charges, released rates or values, or tariff rules or circulars, shall only be valid when specifically agreed to in a signed writing by the Parties.</p>
        <p>(b) Carrier agrees to submit invoices, bills of lading and signed loading or delivery receipts, and rate confirmation sheets, each in a form that is clear and legible for all transportation services furnished under this Agreement to Broker no later than sixty (60) days after load delivery. Failure to provide all necessary paperwork within sixty (60) days may result in delay or forfeiture of payment. Broker agrees to pay Carrier's invoices within thirty (30) days of receipt of all of the above documentation if Carrier is not in default under the terms of this Agreement. Carrier agrees that Broker is the sole party responsible for payment of Carrier invoices, and under no circumstances will Carrier seek payment from the Shipper, consignee, or other parties responsible for payment, unless otherwise advised by Broker in writing to do so.</p>
        <p>(c) Broker and Carrier shall use their best efforts to verify the accuracy of all freight charge billings tendered by Broker to Shippers for transportation services performed by Carrier under this Agreement. Broker shall have the right to audit, from time to time, any and all freight charge billings by Carrier, and Carrier shall cooperate fully with the conduct of such audits.</p>
        <p>(d) Except in case of Force Majeure, Carrier will be responsible for any additional costs incurred by Broker when replacement services are required arising out of Carrier's failure to provide a clean bill of lading and otherwise make delivery in strict conformance with the bill of lading and related documentation, or as otherwise agreed in writing by Carrier and Broker. Economic hardships, including, but not limited to, recession and depression, shall not constitute Force Majeure events.</p>
        <p>(e) Carrier hereby expressly waives its right to any lien on any cargo or other property of Broker or Broker's Shippers. As such, Carrier shall not withhold any freight due to a dispute with Broker regarding any freight charges, whether for the current load or for previously as yet unpaid loads, regardless of whether or not such charges are being disputed by Broker or Broker’s Shipper(s).</p>
        <p>(f) If Carrier in good faith believes that it undercharged or was underpaid for services provided under this Agreement, and seeks to collect charges in addition to those it originally billed and collected for a particular shipment, Carrier will issue any additional bills within 180 days of the delivery of the original bill to Broker. Any civil action brought by Carrier to recover such charges must be brought within 18 months after such claim arises. To the extent permitted by applicable law, the expiration of the said 18 month period shall be a complete and absolute defense to any such action or proceeding, without regard to any mitigating or extenuating circumstance or excuse whatsoever.</p>
        <p>(g) Broker has the right to offset any claim, fine or fee, regardless of when or from which load assessed, including, but not limited to, fees assessed pursuant to Paragraph 23 below with pending invoices, and insurance deductibles paid to cover claims for which Carrier is/was liable.</p>
        <p>5. DOT SAFETY RATING. Carrier represents and warrants that it does not have an "Unsatisfactory" safety rating issued by the Federal Motor Carrier Safety Administration (FMCSA), a department of the U.S. Department of Transportation ("DOT"), and will notify Broker in writing immediately if its safety rating is changed to "Unsatisfactory," "Conditional," "Unfit," or "Marginal." Carrier shall be responsible for all liability and damages asserted against or imposed on Broker arising out of Carrier’s changed safety rating or Carrier’s violation of this paragraph.</p>
        <p>6. INSURANCE. Carrier shall obtain and maintain in effect during the term of this Agreement at least the following types and amounts of insurance coverage from reliable insurance companies having an AM Best rating of A-VII or better. All such insurance shall be written and be required to respond and pay prior to any other available coverage: public/general liability: $1,000,000.00/per incident; motor vehicle (including hired and non-owned vehicles) $100,000.00/per incident; $1,000,000.00/per incident, if transporting hazardous materials including environmental damages and remediation arising out of release or discharge of hazardous substances; Cargo damage/loss: $100,000.00/per incident; and worker's compensation with limits required by applicable state law, as well as any other insurance required by Broker in writing from time to time, including, without limitation, Broad Form Cargo insurance. No policy shall have a deductible, retention, or the like in excess of $5,000.00. It is the sole responsibility of Carrier to be in compliance with the above limits at all times throughout the duration of this Agreement. Carrier agrees to provide copies of its insurance policies and a comprehensive list of policy exclusions to Broker annually. Additionally:</p>
        <p>(a) Except for the higher coverage limits specified above, the insurance policies shall comply with minimum requirements of the Federal Motor Carrier Safety Administration and any other applicable regulatory state agency. Nothing in this Agreement shall be construed to limit liability of the Carrier to the insurance limits set forth above, nor shall any exclusion, declaration, or deductible amount in any insurance policy exonerate Carrier from financial liability for loss or damage. It is Carrier's sole responsibility to abide by the conditions contained in its insurance policies.</p>
        <p>(b) Carrier shall furnish Broker with a certificate of insurance, in a form satisfactory to Broker, to prove that each of the coverages specified in this paragraph is in effect and is being properly maintained and that neither Broker nor its Shippers are obligated to pay premiums for any such insurance. Such certificate of insurance shall reflect that all liability policies name Broker as certificate holder, and is required to name Broker and Broker's Shipper(s) as additional insureds. In addition, such policies shall provide Broker with at least 30 days advance notice prior to cancellation, material change or non-renewal.</p>
        <p>(c) Carrier shall timely pay all deductible amounts under the foregoing policies. Upon request by Broker, Carrier shall provide an actual copy of the policies currently in effect along with any exclusions, exemptions, or riders that are not depicted in the governing certificate of insurance.</p>
        <p>(d) Carrier agrees to only utilize vehicles that carry the above specified limits for Broker's shipments and which are listed on the Carrier’s scheduled automotive insurance policy. Carrier is fully liable for any loss or damage not covered by insurance, and agrees to indemnify Broker and Broker's Shipper(s) for any such loss or damage regardless of vehicle used on any shipment tendered by Broker. Carrier agrees to provide an updated list of scheduled vehicles to Broker on the first day of each calendar quarter (Jan. 1st, April 1st, July 1st, October 1st) each year.</p>
        <p>7. HAZMAT. If Broker requests Carrier to transport any shipment required to be placarded under the DOT rules as a hazardous material, the additional provisions in Appendix A, including additional insurance requirements, shall apply for each such shipment.</p>
        <p>8. CARGO LIABILITY AND CLAIMS. Carrier shall issue a bill of lading in compliance with 49 U.S.C. ? 80101 et seq. and 49 C.F.R. ? 373.101, for the property it receives for transportation under this Agreement. Unless otherwise agreed in writing, Carrier shall become fully responsible and liable for the freight when it takes/receives possession thereof, and the trailer(s) is loaded, regardless of whether a bill of lading has been issued, and/or signed, and/or delivered to Carrier, and Carrier's responsibility and liability shall continue until delivery of the shipment to the consignee and the consignee signs the bill of lading or delivery receipt.</p>
        <p>Additionally:</p>
        <p>(a) If any terms of the bill of lading (including but not limited to payment terms) are inconsistent with the terms of this Agreement, the terms of this Agreement shall control. Failure to issue a bill of lading, or to sign a bill of lading acknowledging receipt of the cargo, by Carrier, shall not affect the liability of the Carrier. Under no circumstances shall Carrier execute any bill of lading or any other document which represents or holds out Broker as the person responsible for delivery of any freight.</p>
        <p>(b) If a consignee refuses a shipment, or Carrier is unable to deliver it for any reason, Carrier's liability as a warehouseman shall not begin until Carrier has provided 24-hour prior written notification of request for directions, and if no other directions are received, either has placed the shipment in a Broker approved public warehouse, or in Carrier's terminal or storage facility under reasonable security.</p>
        <p>(c) Carrier shall comply with 49 C.F.R. ? 370.1 et seq. and any amendments and/or any other applicable regulations adopted by the Federal Motor Carrier Safety Administration, U.S. Department of Transportation, or any applicable state regulatory agency, for processing all loss and damage claims and salvage. Provided Broker has received written notice prior to pick-up of freight, in the event that branded or labeled goods are damaged, Broker or Broker's Shipper may determine, in its sole discretion, whether the goods may be salvaged, and, if salvageable, the value of such salvage. The salvage value of the goods (if any) will be deducted from any claim of Shipper(s) or Broker.</p>
        <p>(d) Except as otherwise provided in this agreement, all liability standards, time limitations and burdens of proof regardless of whether the Carrier has common or contract authority shall be governed by the common law applicable to common carriers and by 49 U.S.C. ? 14706 (the Carmack Amendment).</p>
        <p>(e) Notwithstanding the terms of 49 C.F.R. 370.9, Carrier shall pay, decline or make settlement offer in writing on all cargo loss or damage claims within 30 days of receipt of the claim. Failure of Carrier to pay, decline or offer settlement within this 30 day period shall be deemed admission by Carrier of full liability for the amount claimed and a material breach of this Agreement. Broker reserves the right to offset any claim(s), including those for payment or reimbursement for attorneys’ fees and insurance deductibles, with pending invoices.</p>
        <p>(f) If Carrier fails to adhere to all special instructions set forth in any Rate Confirmation, Carrier shall automatically be liable for any and all damage to such load, without the need for Broker or Shipper’s Broker to prove causation. Under such circumstances, Carrier waives any right to challenge the existence, amount or cause of any damages claimed.</p>
        <p>9. INDEPENDENT CONTRACTORS. The relationship between Broker and Carrier shall, at all times, be that of independent contractors. Additionally:</p>
        <p>(a) None of the terms of this Agreement, or any act or omission of either Party shall be construed for any purpose to express or imply a joint venture, partnership, principal/agent, fiduciary, employer/employee relationship between the Parties.</p>
        <p>(b) Carrier and any of its approved carriers or agents shall employ, pay, supervise, direct, discipline, discharge and assume full responsibility and control over all persons required for the performance of Carrier's duties under this Agreement. Broker has no right to discipline or direct the performance of any driver/and or employees, contractors, subcontractors, or agents of Carrier. Under no circumstances shall Carrier or any of its approved carriers, agents or employees deemed to be or hold themselves out as employees of Broker or any Shipper.</p>
        <p>(c) Carrier and Broker agree that safe and legal operation of the Carrier and its drivers shall completely and without question govern and supersede any service requests, demands, preferences, instructions, or information from Broker or Broker's Shipper(s) with respect to any shipment at any time.</p>
        <p>(d) Carrier agrees that a shipper, consignor, or consignee's insertion of Broker's name as the carrier on a bill of lading shall be for the shipper, consignor, or consignee's convenience only and shall not change Broker's status as a property broker only, nor Carrier's status as a motor carrier.</p>
        <p>(e) Carrier agrees that any driving directions to or from a Shipper's location given by Broker are for informational purposes only. It is Carrier's sole responsibility to insure the directions are appropriate with regard to equipment, route, and safe operation, and to get the Shipper's goods to the designated destination on time and undamaged.</p>
        <p>(f) Carrier assumes full responsibility and liability for payment of the following items: All applicable federal, state, and local payroll taxes, taxes for unemployment insurance, old age pensions, workers' compensation, social security, with respect to persons engaged in the performance of its transportation services hereunder. Broker and Broker's Shipper(s) shall not be liable for any of the payroll related tax obligations specified above which specifically relate to Carrier's business and employees, and Carrier shall indemnify, defend, and hold Broker and Broker's Shipper(s) harmless from any claim or liability imposed or asserted against Broker or Broker's Shipper(s) for any such obligations.</p>
        <p>(g) No in-transit pricing increases or additional load requirements shall be permitted by Carrier. If Carrier demands additional payment, delays, damages or refuses to deliver a load after making such claims, Carrier will be liable to Broker and Broker’s Shipper for direct, consequential, special and liquidated and punitive damages.</p>
        <p>10. INDEMNIFICATION. Carrier agrees to defend, indemnify, hold Broker and its Shippers harmless from and against any and all claims or liability arising out of Carrier's performance under this agreement, including (but not limited to) cargo loss and damage, theft, delay, damage to property, and personal injury and/or death.</p>
        <p>11. BROKER'S ACCOUNTS. Carrier agrees to treat all Broker's Shippers as Broker's accounts during the term of this Agreement. If this Agreement is terminated for any reason whatsoever, Carrier agrees not to solicit freight or provide transportation services to any of Broker's Shippers, shippers, consignors, or consignees for a period of 18 months after the termination date of this Agreement. If Carrier does solicit freight or provide transportation services for any of Broker's Shippers in violation of this paragraph, then Broker shall be entitled to collect 10% of the gross compensation received by Carrier from any and all such Shippers on all shipments that Carrier transports for any such Shipper(s) during the 18 month period following the date of termination. Carrier shall also be liable for all costs and expenses incurred by Broker, including, but not limited to, reasonable attorney's fees.</p>
        <p>12. CO-BROKERING. Carrier is prohibited from brokering, re-brokering, co-brokering, subcontracting, transferring, trip leasing, assigning or interlining the transportation of shipments hereunder to any other person or entity conducting business under an operating authority different from Carrier's without the advance written authorization of Broker. Violation of this policy shall be grounds for immediate termination of this Agreement. If Broker becomes aware of such prohibited activity by Carrier prior to payment of any compensation otherwise due Carrier, Broker shall withhold payment to Carrier and shall instead pay appropriate compensation to the carrier who actually transported the shipment. Broker will deem any acceptance of a shipment by Carrier as a common or contract carrier and subsequent subcontracting of the shipment to any third party as an assignment of the right to be compensated for that shipment to the third party. Upon Broker's payment to delivering carrier, Carrier shall not be released from any liability to Broker under this Agreement. Carrier will be liable for the full extent of loss or damages (including but not limited to reasonable attorney's fees) for violation of this paragraph.</p>
        <p>13. WAIVER AND DISCHARGE.</p>
        <p>(i) The failure of either Party to enforce any provision of this Agreement shall not be construed as a waiver of such provision or the right of either Party to enforce such provision in the future or in any way to affect the validity of this Agreement or any part hereof; and</p>
        <p>(ii) This Agreement is for specified services pursuant to 49 U.S.C. ? 14101(b). To the extent that terms and conditions herein are inconsistent with Part </p>
        <p>(b), Subtitle IV, of Title 49 U.S.C. (ICC Termination Act of 1995), the Parties expressly waive any or all rights and remedies they may have under the Act.</p>
        <p>14. NOTICES. All notices required or permitted under this Agreement shall be in writing, shall be signed by or on behalf of the Party giving the notice, and shall be sent to the other Party at its main office listed above via certified U.S. Mail, overnight courier with delivery receipt or facsimile with machine printed proof of delivery.</p>
        <p>15. GOVERNING LAW. Unless preempted by or controlled by federal transportation laws and regulations, this Agreement is entered into in the State of Ohio and shall be governed by the laws of the State of Ohio without regard to conflicts of law principles. Any action or proceeding seeking to enforce any provision of, or based upon any right arising out of, this Agreement, or the business between the Parties, may be brought only in the courts of the State of Ohio, Hamilton County, or in the United States District Court for the Southern District of Ohio-Western Division sitting in Cincinnati, Ohio, and each Party consents to the exclusive jurisdiction of such courts (and the appropriate appellate courts) in any such action and waives any objection to personal jurisdiction or venue in such courts. Process in any action referred to in the preceding sentence may be served upon a Party anywhere in the world. Carrier agrees to pay all reasonable expenses, attorney fees and costs (including court costs) that Broker incurs in any such actions in which Broker prevails.</p>
        <p>16. ENTIRE AGREEMENT. This Agreement and the Appendices hereto constitute the entire agreement between the Parties with respect to the subject matter hereof and shall supersede all prior understandings, agreements, contracts, tariffs, schedules, bills of lading or other arrangements, whether written or oral, between the Parties regarding such subject matter. This Agreement shall be binding upon each Party and its successors and permitted assigns. The Parties further intend that no extrinsic evidence may be introduced to reform this Agreement in any judicial or equitable proceeding arising out of this Agreement.</p>
        <p>17. INVALIDITY OF PROVISIONS. If a court of competent jurisdiction declares any provision of this Agreement invalid, such decision shall not affect the validity of any remaining provisions, and all remaining provisions of this Agreement shall remain in full force and effect.</p>
        <p>18. ASSIGNMENT AND DELEGATION. This Agreement shall inure to the benefit of and be binding upon the successors and assigns of both Parties, provided, however, that no assignment of rights and no delegation of duties under this Agreement shall be effective without the prior written consent of the other Party. Notwithstanding the above, Broker may, at any time, transfer this agreement, together with its rights and duties to any parent corporation or wholly owned subsidiary of its parent corporation, without permission of Carrier.</p>
        <p>19. STATUTORY REFERENCES. References made herein to any federal, state or local statutes shall be read to mean such statute as the same may be amended or otherwise supplemented from time to time.</p>
        <p>20. FACTORING. Carrier shall provide Broker written notice of any assignment, factoring, or other transfer of its right to receive payments arising under this Contract at least thirty (30) days prior to such assignment, factoring, or other transfer taking legal effect as to Broker's payment obligation hereunder (Broker shall not be obligated to honor any factoring, assignment or any other transfer of Carrier's right to receive any payments hereunder unless such notice is timely received). Such written notice shall include the name and address of factoring company, assignee/transferee, date, date assignment is to begin, and terms of the assignment, and shall be considered delivered upon receipt of such written notice by Broker. Broker shall have the right to ask for and Carrier shall be obligated to furnish any further documentation Broker requires in order to satisfy itself as to the authenticity and payment requirements of the factoring arrangement(s). Broker's payment obligations hereunder shall not be subject to more than one factoring/assignment agreement at any one time. No multiple assignments, factoring or other such transfers by the Carrier shall be binding on Broker. Carrier shall indemnify Broker against and hold Broker harmless from any and all lawsuits, claims, actions, damages (including reasonable attorney's fees, obligations, liabilities, and liens) arising or imposed on Broker in connection with the factoring, assignment or transfer of any account or right arising thereunder. If Carrier wants to terminate factoring, a release from the Carrier and the factoring company in a form satisfactory to Broker's counsel must be received by Broker specifying the terms and date of release. Carrier also releases and waives any right, claim or action against Broker for any amount due and owing under this Agreement where Carrier has not complied with the notice requirements of this section.</p>
        <p>21. ELECTRONIC AND FAX COMMUNICATIONS. The disclaimer set out below applies to any and all electronic communication(s) (including any attachments thereto) emanating from Broker:</p>
        <p>(a) During the term of this Agreement, the parties anticipate that they will exchange materials and information in electronic form, including at times pricing for services, either through the other party's websites, e-mail, or other electronic means (collectively "Electronic Communications") and via fax. By providing their fax numbers and signing this Agreement, each party consents to receiving communications via fax regarding all aspects of their relationship.</p>
        <p>(b) Use of Electronic Communications is entirely at the user's own risk. Although Broker has taken reasonable steps to guard against viruses or destructive codes, it gives no warranty that Electronic Communications are free of viruses or any other data or code that has the ability to corrupt or affect Carrier's system. Under no circumstances will Broker or its affiliates be responsible for, and Carrier hereby expressly waives and releases Broker and its affiliates from, any liability for any loss or damage caused by computer viruses, the Carrier's receipt or use of Electronic Communications from Broker or its affiliates or Electronic Communications between Broker and its affiliates and the Carrier.</p>
        <p>(c) Electronic Communications may contain information that is confidential and subject to legal privilege. It is intended solely for the individual or entity to whom it is addressed and to others who have the authority to receive it. Carrier receiving Electronic Communications from the Broker or Broker’s affiliates may not under any circumstances disclose, copy, or distribute the content of the information contained therein without Broker’s written consent.</p>
        <p>(d) Electronic Communications will only be legal, valid and enforceable against Broker if an authorized employee or the head of the respective division of Broker has confirmed or consented thereto.</p>
        <p>(f) Unless otherwise noted, Electronic Communications are subject to intellectual property rights of Broker. Use of the content in web pages, electronic or written publications or any other media and/or words, phrases, names, designs or logos that are Broker's trademarks are expressly prohibited from use or copying, without the express written permission of Broker.</p>
        <p>22. CONFIDENTIALITY. In addition to confidential information protected by law, statutory or otherwise, the Parties agree that all of their financial information and that of their customers and Broker's Shippers, including, but not limited to, freight and brokerage rates, amounts received for brokerage services, amounts of freight charges collected, amounts of freight charges paid, freight volume requirements, as well as personal customer information, customer shipping or other logistics requirements shared or learned between the Parties and their customers, shall be treated as confidential, and shall not be disclosed or used for any reason without prior written consent. In the event of violation of this Paragraph, the Parties agree that the remedy at law, including monetary damages, may be inadequate and that the Parties shall be entitled, in addition to any other remedy they may have, to an injunction restraining the violating Party from further violation of this Agreement. If Broker prevails, Broker shall be entitled to recover all costs and expenses incurred, including but not limited to reasonable attorney's fees.</p>
        <p>23. GENERAL CARRIER DUTIES. In order to fulfill shipper delivery and tracking requests, Carrier agrees as follows (Carrier acknowledges that it is solely responsible for directing and controlling the manner in which the following are conducted):</p>
        <p>(a) Drivers to check call every day (including Saturday, Sunday, and holidays) before 10:00 am Eastern Standard Time giving their current location and load temperature if applicable. Failure to do so may result in a $125.00 deduction from the Carrier's settlement for each infraction.</p>
        <p>(b) Broker is available 24 hours a day. 7 days a week. Carrier is to call immediately to report any problems. Broker will be responsible for any fees that result from Carrier’s failure to report problems.</p>
        <p>(c) Carrier must immediately report overage, shortage and damage claims. Carrier shall be liable for any and all claims arising from Carrier’s failure to report any overage, shortage, or damage claims within 12 hours.</p>
        <p>(d) Carrier is strictly liable for any damage to or loss of the product, shipment or its packaging, and shortages of freight. In order to negate such liability, Carrier must immediately inform Broker PRIOR to leaving the shipper, of any discrepancies between the relevant bill of lading and the Load Confirmation.</p>
        <p>(e) If any unloading payment is agreed upon, Carrier must supply unloading receipt with lumper's full name and social security number along with the Broker authorization number at the time of original billing. Failure to do so within 48 hours of delivery will result in no reimbursement for unloading. Hand written receipts are invalid.</p>
        <p>(f) For all pallet exchange loads, the number of pallets in and out must be clearly notated on the original Bill of Lading.</p>
        <p>(g) Failure to deliver with fully loaded trailer or incomplete order will result in a reduced pro-rated fee.</p>
        <p>(h) All loads tendered to Carrier require exclusive use of trailer space unless otherwise specified in writing. Any costs incurred by Broker due to Carrier loading any extra freight not authorized in writing by Broker can be charged to Carrier.</p>
        <p>(i) Any costs incurred by Broker due to Carrier being late for pick-up or delivery appointments may be charged to Carrier.</p>
        <p>(j) Any product which must be disposed of must have prior written consent from Broker before being disposed of by any party. If the load is disposed of without prior written consent from Broker, Carrier is 100% liable for the value of the load, plus any other damages. Carrier is also required to remit to Broker any funds received from salvage or insurance.</p>
        <p>(k) Driver must ensure load is properly secured. Any concerns or requests for additional securements must be made PRIOR to leaving shipper. Carrier will be liable for any damage. If the Carrier is not permitted on the dock, all bills of lading must be marked “Shipper Load and Count.”</p>
        <p>(l) Loads that are sealed at the shipping point are to remain sealed until an authorized person at the receiver breaks the seal. Seal numbers must be marked on each bill of lading. If the seal is broken by an unauthorized person, Carrier shall be 100% liable for the invoice value to customer or cost, whichever is greater, of the product and any other expenses.</p>
        <p>(m) Carrier is required to provide a trailer that is in sound technical and structural condition, and complies with all laws, including, without limitation, all federal, state and local laws pertaining to sanitation, temperature, and contamination requirements for transporting food, perishable, and other products, including without limitation the Food Safety Modernization Act, the Sanitary Food Transportation Act of 2005 and the FDA’s Final Rule pertaining to Sanitary Transportation of Human and Animal Food, and the implementation and maintenance of equipment safety regulations, and is suitable in all manner to accept, load, and transport any shipment.</p>
        <p>(n) All accessorial charges must be pre-approved by Broker. Unauthorized charges will not be paid.</p>
        <p>(o) A $35.00 deduction will be made on Carrier's invoice for each Comchek issued for fuel advances, as well as other cash advances (if applicable).</p>
        <p>(p) In addition to all of the above, with regard to refrigerated loads, Carrier agrees as follows:</p>
        <p>(i) Prior to loading, Carrier shall confirm that the reefer unit is working properly and pre-cool trailer to temperature specified on Broker's rate confirmation sheet. Temperature on Broker's rate confirmation will be in degrees Fahrenheit unless otherwise specified in writing.</p>
        <p>(ii) All refrigerated trailers are required to have an air chute for proper circulation. It is Carrier's responsibility to make sure the chute is not damaged, obstructed or blocked in any way. It is Carrier's sole responsibility to make sure space is provided for air circulation in front, rear, top, bottom, and between the load.</p>
        <p>(iii) All Transport Refrigeration Units ("TRU") used to haul Shipper's goods are and will continue to operate in compliance with all federal, state and local environmental laws and emissions standards. All federal, state and local fines, fees and charges associated with or resulting from Carrier's failure to comply with this requirement will be the sole responsibility of Carrier. Any Carrier transporting TRUs to or through the State of California must sign Appendix B to this Agreement.</p>
        <p>(iv) Carrier shall check pulp temperature of the product to ensure that product has been pre-cooled. Carrier shall not accept any product pulping more than 3 degrees above or below the specified reefer unit temperature noted on Broker Rate Confirmation. If the temperature on Broker rate confirmation differs from that on the bill of lading, Carrier shall call Broker before signing the bills of lading at the shipper. If the load is accepted contrary to the terms on Broker rate confirmation, Carrier accepts full responsibility for any loss or damage. Carrier shall make sure the pulp temperature of the product loaded is confirmed on the original bill of lading.</p>
        <p>(v) By signing the bill of lading, Carrier is confirming that the correct product and correct count were received at the proper temperature. Carrier is solely responsible for loss or damage incurred due to inaccurate product information on Bill of Lading. If a discrepancy or count, condition, or temperature is encountered at the shipper, Carrier shall notify Broker immediately, and no change to loading information shall be made until confirmed in writing by Broker.</p>
        <p>(vi) Carrier shall maintain continuous temperature noted on Broker's Rate Confirmation in-route, unless otherwise notified in writing by Broker. Carrier shall not, at any time set reefer on start/stop, cycle, or any other non-continuous temperature mode or setting unless otherwise notified in writing by Broker. If at any time the reefer is set on start/stop, cycle or any other non-continuous temperature mode or setting, Carrier solely and automatically assumes all risk of loss of or damage to the applicable load. Carrier must contact Broker immediately in the event of any problems including, but not limited to, out-of-temperature condition, equipment malfunction, accident, or delay. If a load is rejected because the reefer temperature is not in accordance with the temperature set forth on the Broker’s Rate Confirmation, Carrier will immediately download the reefer information for the load and provide such information to Broker.</p>
        <p>Payment/Comchek Terms:<br/>
        Send all invoices to accounting@intxlog.com for payment processing.<br/>
        Fax: 855-643-4162<br/>
        Invoices sent to any other email/fax will NOT be processed.
        </p>
        <p>ALL PAPERWORK SUBMITTED MUST INCLUDE:</p>
        <ul>
          <li>Invoice with your company name and address with payment terms clearly stated. (IEL Rate Confirmation can be used as your invoice)</li>
          <li>Original Bill of Lading or Proof of Delivery (BOL/POD) with ALL pages signed by the receiver.</li>
          <li>Any Valid un/loading, pallet, gate, etc. receipts must be submitted with original invoice for reimbursement. Handwritten or illegible receipts may not be reimbursed.</li>
          <li>Copy of the IEL Rate Confirmation</li>
        </ul>
        <p>ADVANCE/COMCHEK RULES:</p>
        <p>Comcheks are available ONLY after freight has been loaded. Please indicate Yes or No for each item above. Not indicating Comchek preferences, will default to NO ALLOW Comcheks. The person receiving the Comchek must know the IEL PO number indicated on the Rate Confirmation. Fuel Advances will be approved with proof of loading for 40% of the flat rate of the load, up to $1,500.00. ALL COMCHEKS WILL BE SUBJECT TO A $35 FEE FOR FUEL ADVANCES. COMCHEKS FOR ACCESSORIAL CHARGES WILL NOT INCUR A FEE WITH RECEIPT SUBMITTED WITH ORIGINAL INVOICE.</p>
        <p>TRU Compliance Certification (IF APPLICABLE):</p>
        <p>For any shipment arranged by Broker to be transported by Carrier involving transportation of produce or other refrigerated commodities to or through the State of California, the Carrier hereby certifies that any and all Transport Refrigeration Units ("TRU") utilized for such transport are in compliance with all federal, state and local environmental laws and emissions standards, including, but not limited to, the California Air Resources Board (CARB) Transport Refrigerated Unit (TRU) Airborne Toxic Control Measure (ATCM) in-use regulations and all other such laws and standards currently in effect in the State of California. Carrier will maintain all TRUs in compliance with the above laws and regulations. Any and all fines, fees and charges resulting from Carrier's noncompliance with such laws will be the sole responsibility of Carrier. Carrier hereby indemnifies Broker and Broker's Shippers from all liability related to same</p>
        <p>Extra Requirement for Hazardous Materials Shipments (IF APPLICABLE) HAZMAT:</p>
        <p>For any shipment arranged by Broker to be transported by Carrier involving transportation of hazardous materials or waste requiring vehicle placarding under 49 C.F.R Part 172, or any amendment, revision or other applicable regulation, the parties agree the following provisions shall apply and shall be included by this reference, in addition to provisions in the Broker-Carrier Agreement, to which this Appendix is attached:</p>
        <p>1. Carrier also represents and warrants it holds all federal and/or state permits and registrations necessary to transport the hazardous materials or waste, and Carrier shall provide Broker copies of all appropriate documents upon Broker's request.</p>
        <p>2. Carrier also represents and warrants all of Carrier's drivers transporting hazardous materials or waste: (a) are properly trained and qualified under all applicable Federal and state laws, including, as example, 49 C.F.R. ? 172.000 and 177.800; and (b) have the proper endorsements on their Commercial Driver's License to transport such shipments.</p>
        <p>3. Carrier shall comply with all applicable laws and regulations relating to the transportation of "Hazardous Materials" as such term is defined in 49 C.F.R. ? 172.800. ? 173, and ? 397, et seq., to the extent that any shipments hereunder constitute Hazardous Materials. Carrier will comply with all applicable security plan regulations and training required by Homeland Security laws and regulations with regard to the handling and transporting of Hazardous Materials. Carrier shall be solely responsible for any violation of the applicable laws and regulations, and shall defend, indemnify, and hold Broker and its Shippers harmless from, and pay Broker on demand for any claims, losses, damages or liability incurred, including but not limited to reasonable attorney’s fees arising from non-compliance.</p>
        <p>4. If Carrier is requested to transport hazardous materials or waste for which Carrier must maintain 55 million ($US) liability coverage under 49 C.F.R. ? 387.9, Carrier shall procure and maintain, at its sole expense, public liability and property damage insurance from an insurance company authorized to do business in all states in which goods will move, insuring Carrier for at least $5 million ($US) per occurrence. Such insurance shall name Broker as certificate holder and, if requested by Broker, Broker and Broker’s Shipper as additional insureds for any and all liabilities for personal injuries (including death) and property damage, including environmental damage due to the release or discharge of a hazardous substance and cost of remediation arising out of or in any way related to Carrier's transportation.</p>
        <p>5. By signing below Carrier specifically acknowledges that this Appendix A is a part of the Broker-Carrier Agreement to which it is attached and is fully enforceable against the Carrier according to its terms.</p>
        <p>IN WITNESS WHEREOF, the Parties hereto have executed this Agreement as of the date first above written intending to be legally bound.</p>
        <p>CARRIER:<br/>
        <span className={styles.highlight}>{name}</span> 
        </p>
        <p>Authorized Carrier Representative::<br/>
        <span className={styles.highlight}>{name}</span>
        </p>
      </div>
        <p className={styles.highlight} >Carrier represents that the individual completing the setup process and signing this Agreement on its behalf has the authority to do so and to so legally bind the party. The Company represents that the execution, delivery and performance of this Agreement by the Company has been fully and validly authorized by all necessary corporate action.</p>
      <div className={styles.signatureContainer}>
        <div>
          Integrity Express Logistics<br/>
          4420 Cooper Rd. Ste 400.<br/>
          Cincinnati OH, 45242
        </div>
        <div className={styles.right}>
          <LabelWrapper
            id={`signature`}
            text={`Signature`}
            isRequired={true}
          >
            <Input
              idForLabel={`signature`}
              onChange={onSignatureChange}
              isValid={onSignatureValid}
              required={true}
              formSubmitted={formSubmitted}
              defaultValue={signature}
            ></Input>
          </LabelWrapper>
          <div></div>
          <div className={styles.infoWrapper}>
            {name}<br/>
            {address}<br/>
            {city} {state}, {zip}
          </div>
        </div>
      </div>
    </>
  )
}

//rules for props being passed in
ServiceAgreement.propTypes = {
  name: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  MCNumber: PropTypes.string,
  DOTNumber: PropTypes.string,
  getDay: PropTypes.func,
  getMonth: PropTypes.func,
  formSubmitted: PropTypes.number,
  onSignatureChange: PropTypes.func,
  onSignatureValid:PropTypes.func
}

// Specifies the default values for props:
// ServiceAgreement.defaultProps = {

// }

export default ServiceAgreement