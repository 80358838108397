import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Input } from '@intxlog/iuilib'

//import components
import SSNEINInput from 'components/SSNEINInput'

//import utils
import handleUpdateUserResponses from 'utils/handleUpdateUserResponses'

//import action creators
import {
  setEINIsValidTaxForm,
  unsetEINIsValidTaxForm,
  setFormIsSubmittedTaxForm
} from 'modules/taxForm'

export class SSNEINContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showTextInput: false,
      inputValCounter: 0
    }
  }

  componentDidMount () {
    if (this.props.numType !== null) {
      this.setState({
        showTextInput: true
      })
    }
  }

  componentDidUpdate (prevProps) {
    //logic to show input
    if (prevProps.numType === null && this.props.numType !== null) {
      this.setState({
        showTextInput: true
      })
    } 
  }

  onChange = (val) => {
    this.props.handleUpdateUserResponses({w9TaxNumber: val})
  }

  onRadioChange = (val) => {
    this.props.handleUpdateUserResponses({w9NumberType: val})
    //update the formSubmitted value to revalidate
    if (this.props.formSubmitted) {
      this.props.setFormIsSubmittedTaxForm()
    } else {
      this.setState({
        inputValCounter: this.state.inputValCounter + 1
      })
    }
  }

  onIsValid = (isValid) => {
    //shorthand if statement
    isValid ? this.props.setEINIsValidTaxForm() : this.props.unsetEINIsValidTaxForm()
  }

  render(){
    let input = null
    let inputType = `ssn`
    let placeholder = `Social Security Number`

    //logic for placeholder
    switch (this.props.numType) {
      case 'ein':
        placeholder = `12-3456789`
        inputType = `ein`
        break
      case 'ssn':
        placeholder = `123-45-6789`
        inputType = `ssn`
        break
      default:
        break
    }

    //logic to show/hide input
    if (this.state.showTextInput) {
      input = <Input
        idForLabel={`ssn`}
        type={inputType}
        defaultValue={this.props.value}
        placeholder={placeholder}
        onChange={this.onChange}
        required
        formSubmitted={this.props.formSubmitted + this.state.inputValCounter}
        isValid={this.onIsValid}
      ></Input>
    }

    return (
      <SSNEINInput
        onRadioChange={this.onRadioChange}
        textInputComponent={input}
        radioValue={this.props.numType}
        formSubmitted={this.props.formSubmitted}
      ></SSNEINInput>
    )
  }
}

const mapStateToProps = state => ({
  numType: state.userResponses.w9NumberType,
  value: state.userResponses.w9TaxNumber,
  formSubmitted: state.taxForm.formSubmitted,
  uid: state.userData.uid
})

const mapDispatchToProps = dispatch => bindActionCreators({
  handleUpdateUserResponses,
  setEINIsValidTaxForm,
  unsetEINIsValidTaxForm,
  setFormIsSubmittedTaxForm
}, dispatch)

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(SSNEINContainer)