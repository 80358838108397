import React from 'react'
import PropTypes from 'prop-types'
import { Input } from '@intxlog/iuilib'

//import components
import LabelWrapper from 'components/LabelWrapper'

//import helpers
import StatesOptions from 'helpers/selectOptions/StateProvince'
import CountryOptions from 'helpers/selectOptions/Country'

//import css
import styles from './styles.module.scss'

const PayToInfo = ({
  companyName,
  address,
  city,
  state,
  zip,
  country,
  firstName,
  lastName,
  email,
  phone,
  fax,
  onCompanyNameChange,
  onAddressChange,
  onCityChange,
  onStateChange,
  onZipChange,
  onCountryChange,
  onFirstNameChange,
  onLastNameChange,
  onEmailChange,
  onPhoneChange,
  onFaxChange,
  onNameIsValid,
  onAddressIsValid,
  onCityIsValid,
  onStateIsValid,
  onZipIsValid,
  onCountryIsValid,
  onEmailIsValid,
  onPhoneIsValid,
  formSubmitted
}) => {
  return (
    <div className={styles.container}>
      <LabelWrapper
        id={`companyName`}
        text={`Pay To Company Name:`}
        isRequired={true}
      >
        <Input
          idForLabel={`companyName`}
          defaultValue={companyName}
          onChange={onCompanyNameChange}
          required={true}
          formSubmitted={formSubmitted}
          isValid={onNameIsValid}
        ></Input>
      </LabelWrapper>
      <LabelWrapper
        id={`address`}
        text={`Address:`}
        isRequired={true}
      >
        <Input
          idForLabel={`address`}
          defaultValue={address}
          onChange={onAddressChange}
          required={true}
          formSubmitted={formSubmitted}
          isValid={onAddressIsValid}
        ></Input>
      </LabelWrapper>
      <LabelWrapper
        id={`city`}
        text={`City:`}
        isRequired={true}
      >
        <Input
          idForLabel={`city`}
          defaultValue={city}
          onChange={onCityChange}
          required={true}
          formSubmitted={formSubmitted}
          isValid={onCityIsValid}
        ></Input>
      </LabelWrapper>
      <LabelWrapper
        id={`state`}
        text={`State/Province:`}
        isRequired={true}
      >
        <Input
          type={`select`}
          defaultValue={state || `IUILIBdefault`}
          onChange={onStateChange}
          required={true}
          formSubmitted={formSubmitted}
          isValid={onStateIsValid}
        >
          <option disabled value={`IUILIBdefault`}>Please choose a state or province</option>
          <StatesOptions></StatesOptions>
        </Input>
      </LabelWrapper>
      <LabelWrapper
        id={`zip`}
        text={`ZIP/Postal Code:`}
        isRequired={true}
      >
        <Input
          idForLabel={`zip`}
          type={'zip'}
          defaultValue={zip}
          onChange={onZipChange}
          required={true}
          formSubmitted={formSubmitted}
          isValid={onZipIsValid}
        ></Input>
      </LabelWrapper>
      <LabelWrapper
        id={`country`}
        text={`Country:`}
        isRequired={true}
      >
        <Input
          type={`select`}
          defaultValue={country || `IUILIBdefault`}
          onChange={onCountryChange}
          required={true}
          formSubmitted={formSubmitted}
          isValid={onCountryIsValid}
        >
          <option disabled value={`IUILIBdefault`}>Please choose a country</option>
          <CountryOptions></CountryOptions>
        </Input>
      </LabelWrapper>
      <LabelWrapper
        id={`firstName`}
        text={`Pay To First Name:`}
      >
        <Input
          idForLabel={`firstName`}
          defaultValue={firstName}
          onChange={onFirstNameChange}
        ></Input>
      </LabelWrapper>
      <LabelWrapper
        id={`lastName`}
        text={`Pay To Last Name:`}
      >
        <Input
          idForLabel={`lastName`}
          defaultValue={lastName}
          onChange={onLastNameChange}
        ></Input>
      </LabelWrapper>
      <LabelWrapper
        id={`email`}
        text={`Email:`}
        isRequired={true}
      >
        <Input
          idForLabel={`email`}
          defaultValue={email}
          onChange={onEmailChange}
          required={true}
          type={`email`}
          formSubmitted={formSubmitted}
          isValid={onEmailIsValid}
        ></Input>
      </LabelWrapper>
      <LabelWrapper
        id={`phone`}
        text={`Phone:`}
        isRequired={true}
      >
        <Input
          idForLabel={`phone`}
          type={`phone`}
          defaultValue={phone}         
          onChange={onPhoneChange}
          required={true}
          formSubmitted={formSubmitted}
          isValid={onPhoneIsValid}
        ></Input>
      </LabelWrapper>
      <LabelWrapper
        id={`fax`}
        text={`Fax:`}
      >
        <Input
          idForLabel={`fax`}
          defaultValue={fax}
          onChange={onFaxChange}
        ></Input>
      </LabelWrapper>
    </div>
  )
}

//rules for props being passed in
PayToInfo.propTypes = {
  companyName: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  country: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  fax: PropTypes.string,
  onCompanyNameChange: PropTypes.func,
  onAddressChange: PropTypes.func,
  onCityChange: PropTypes.func,
  onStateChange: PropTypes.func,
  onZipChange: PropTypes.func,
  onCountryChange: PropTypes.func,
  onFirstNameChange: PropTypes.func,
  onLastNameChange: PropTypes.func,
  onEmailChange: PropTypes.func,
  onPhoneChange: PropTypes.func,
  onFaxChange: PropTypes.func,
  onNameIsValid: PropTypes.func,
  onAddressIsValid: PropTypes.func,
  onCityIsValid: PropTypes.func,
  onStateIsValid: PropTypes.func,
  onZipIsValid: PropTypes.func,
  onCountryIsValid: PropTypes.func,
  onEmailIsValid: PropTypes.func,
  onPhoneIsValid: PropTypes.func,
  formSubmitted: PropTypes.number
}

export default PayToInfo