import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

//import components
import PaymentOptions from 'components/PaymentOptions'
import QuickPayQuestions from 'components/QuickPayQuestions'
import QuestionPageTemplate from 'components/QuestionPageTemplate'

//import utils
import navigateTo from 'utils/navigateTo'
import handleUpdateAppDataSettings from 'utils/handleUpdateAppDataSettings'
import handleUpdateUserResponses from 'utils/handleUpdateUserResponses'
import checkRequiredFormErrors from 'utils/checkRequiredFormErrors'

export class PaymentContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formSubmitted: 0
    }
  }

  handleFactoringOnChange = (val) => {
    //val is coming in as a string. convert to boolean
    val = (val === 'true')

    //set the value so we know the user has answered the factoring question
    this.props.handleUpdateAppDataSettings({ answeredFactoring: true })
    //save the response
    this.props.handleUpdateUserResponses({ usesFactoringCompany: val })
  }

  handleQuickPayOnChange = (val) => {
    //save the response
    this.props.handleUpdateUserResponses({ quickPayPreference: val })
  }

  handleProceed = () => {
    this.props.navigateTo('/advance-options')
  }

  handleFactoringNameOnChange = (val) => {
    //save the response
    this.props.handleUpdateUserResponses({ factoringCompanyName: val })
  }

  render() {
    let quickPayContent = null
    let factoringInfo = null
    let disabled = null

    if (!this.props.usesFactoringCompany) {
      quickPayContent = <QuickPayQuestions
        value={this.props.quickPayPreference}
        onChange={this.handleQuickPayOnChange}
      ></QuickPayQuestions>
    }

    if (this.props.usesFactoringCompany) {
      factoringInfo = <a type={`emailLink`} href="mailto:info@triumphpay.com">info@triumphpay.com</a>
    }
    
    if (!this.props.usesFactoringCompany && this.props.quickPayPreference === `twoDay`) {
      disabled = true;
      factoringInfo = <span style={{ color: 'red', fontSize: 'small', fontStyle: 'italic' }}>Unable to choose Yes if Pay Option selected is 2 Days</span>;
    }

    return (
      <QuestionPageTemplate
        title={`Payment Options`}
        infoText={`Please answer the following questions regarding payment options:`}
        nextText={`Proceed to Advance Options`}
        nextFunction={this.handleProceed}
        showPrev={false}
        wrapInBorder={false}
        isEditing={this.props.isEditing}
        disabled={this.props.isEditing}
      >
        <PaymentOptions
          usesFactoringCompany={this.props.usesFactoringCompany}
          factoringChangeFunction={this.handleFactoringOnChange}
          factoringInfo={factoringInfo}
          disabled={disabled}
          quickPayComponent={quickPayContent}
          formSubmitted={this.state.formSubmitted}
        ></PaymentOptions>
      </QuestionPageTemplate>
    )
  }
}

const mapStateToProps = state => ({
  answeredFactoring: state.appDataSettings.answeredFactoring,
  usesFactoringCompany: state.userResponses.usesFactoringCompany,
  factoringCompanyName: state.userResponses.factoringCompanyName,
  issueComchekToDispatcher: state.userResponses.issueComchekToDispatcher,
  issueComchekToDrivers: state.userResponses.issueComchekToDrivers,
  quickPayPreference: state.userResponses.quickPayPreference,
  uid: state.userData.uid,
  ...state.appSettings
})

const mapDispatchToProps = dispatch => bindActionCreators({
  checkRequiredFormErrors,
  navigateTo,
  handleUpdateAppDataSettings,
  handleUpdateUserResponses
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentContainer)
