import React from 'react'
import PropTypes from 'prop-types'
import { RadioGroup, RadioButton } from '@intxlog/iuilib'

//import css
import styles from './styles.module.scss'

const SSNEINInput = ({
  onRadioChange,
  radioValue,
  textInputComponent,
  formSubmitted
}) => {
  return (
    <div className={styles.container}>
      <RadioGroup
        name={`ssnein`}
        onChange={onRadioChange}
        direction={`horizontal`}
        required
        formSubmitted={formSubmitted}
        defaultValue={radioValue}
      >
        <RadioButton 
          labelText={`SSN`}
          value={`ssn`}
        ></RadioButton>
        <RadioButton
          labelText={`EIN`}
          value={`ein`}
        ></RadioButton>
      </RadioGroup>
      {textInputComponent}
    </div>
  )
}

//rules for props being passed in
SSNEINInput.propTypes = {
  onRadioChange: PropTypes.func,
  radioValue: PropTypes.string,
  formSubmitted: PropTypes.number,
  textInputComponent: PropTypes.object
}

// // Specifies the default values for props:
// SSNEINInput.defaultProps = {
//
// }

export default SSNEINInput