import React, { useState } from 'react'

//import css
import styles from './styles.module.scss'

const FeedbackModal = (props) => {
    const [feedbackScore, setFeedbackScore] = useState(null);
    const [feedbackComment, setFeedbackComment] = useState("");
    const [feedbackCommentLength, setFeedbackCommentLength] = useState(0);
    const [isInvalid, setIsInvalid] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false)

    const handleFeedbackScore = (score) => {
        setFeedbackScore(score);
    }

    const handleFeedbackComment = (text) => {
        setFeedbackComment(text);
        setFeedbackCommentLength(text.length)
    }

    const handleSubmitFeedback = async () => {
        if (feedbackScore !== null || feedbackComment.length > 0) {
            setIsInvalid(false);

            var feedback = {
                net_promoter_score: feedbackScore,
                comment: feedbackComment
            }

            await props.submitFeedback(feedback)
            setIsSuccess(true)

            // Display success message for 2.5 seconds
            setTimeout(function () {
                props.closeModal()
            }, 2500);
        } else {
            setIsInvalid(true);
        }
    }

    const feedbackCommentStyle = () => {
        var style = {}
        if (feedbackCommentLength === 6000) {
            style = styles.charactersRemainingRed
        } else {
            style = styles.charactersRemaining
        }

        return style;
    }

    return (
        <div className={styles.modalBackgroundLayer}>
            <div onClick={props.closeModal} className={styles.exitLayer}></div>
            <div className={styles.modal}>

                {isSuccess && <div className={styles.submitStatus}>
                    <div className={styles.success}>Feedback Submitted Successfully. Thank you!</div>
                </div>}

                <div className={styles.header}>
                    <h1 className={styles.headerText}>Feedback</h1>
                    <span className={styles.closeModal} onClick={props.closeModal}>&#10005;</span>
                </div>

                <div className={styles.body}>
                    {/* I had to manually build this form with HTML elements and NOT the IEL UI library ones, because the IEL UI lib ones do not have maxLength properties :( */}
                    <form onSubmit={(e) => { handleSubmitFeedback(); e.preventDefault(); }}>
                        <label className={styles.label} htmlFor="feedbackScore">How likely are you to recommend the Carrier OnBoarding site to a friend or colleague?</label>
                        <select id="feedbackScore" defaultValue="default" onChange={(e) => { handleFeedbackScore(e.target.value) }}>
                            <option value="default" disabled>Choose an option (0-10)</option>
                            {/* We set 10, 5, and 0 to have labels so their values are numerical but they display text to the user */}
                            <option label="10 - Extremely Likely">10</option>
                            <option>9</option>
                            <option>8</option>
                            <option>7</option>
                            <option>6</option>
                            <option label="5 - Neither likely or unlikely">5</option>
                            <option>4</option>
                            <option>3</option>
                            <option>2</option>
                            <option>1</option>
                            <option label="0 - Not at All Likely">0</option>
                        </select>

                        <label className={styles.label} htmlFor="feedbackComment">Comment</label>
                        <textarea id="feedbackComment" type="textArea" onChange={(e) => { handleFeedbackComment(e.target.value) }} maxLength="6000"></textarea>
                        <div className={feedbackCommentStyle()}>{feedbackCommentLength} / 6000 Characters</div>

                        {isInvalid && <div className={styles.validateError}>Please either selection an option 0-10 in the dropdown, or enter a feedback comment.</div>}

                        <button type="submit" text="Submit Feedback" className={styles.btnSubmitFeedback}>Submit Feedback</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default FeedbackModal;