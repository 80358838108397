//import actions
import { userLoggedOutAuth } from 'modules/auth'

import resetState from 'utils/resetState'

import navigateTo from 'utils/navigateTo'

export default function logUserOut() {
  return dispatch => {
    //Clear session and local storage (ID tokens, uid, refresh token, etc)
    sessionStorage.clear()
    localStorage.clear()

    //Set state to user logged out
    dispatch(userLoggedOutAuth())
    dispatch(resetState())

    dispatch(navigateTo('/'))
  }
}