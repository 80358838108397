import React from 'react'
import PropTypes from 'prop-types'
import { Button, Input, LoadingGif, Modal } from '@intxlog/iuilib'
import classNames from 'classnames'


//import css
import styles from './styles.module.scss'

class SignupForm extends React.Component {
  render() {

    let showModal
    if (this.props.modal.isOpen) {
      showModal = <Modal
        {...this.props.modal}
        closeFunc={this.props.handleModalClose}
      ></Modal>
    }
    let mainCSSCLasses = classNames({
      [styles.loading]: this.props.isLoading
    })

    return (

      <>
        <form onSubmit={this.props.onSubmit} className={mainCSSCLasses}>
          <h1>Signup</h1>
          <p>Please create an account below. The information you enter here will be your login information to complete the onboarding process.</p>
          <div className={styles.inputWrapper}>
          <label htmlFor="fullname">Full Name</label>
            <Input
              idForLabel="fullname"
              required
              onChange={this.props.onNameChange}
              placeholder={'Full Name'}
            ></Input>
            <label htmlFor="email">Email Address</label>
            <Input
              idForLabel="email"
              type='email'
              required
              error={this.props.emailError}
              infoText={this.props.emailErrorText}
              onChange={this.props.onEmailChange}
              isValid={this.props.isEmailValid}
              defaultValue={this.props.emailInputValue}
              placeholder={'Email Address'}
              disabled={this.props.emailDisabled}
            ></Input>
            <label htmlFor="password">Password</label>
            <Input
              idForLabel="password"
              required
              error={!this.props.passwordIsGood}
              onChange={this.props.onPasswordChange}
              placeholder={'Password'}
              type="password"
            />
            <label htmlFor="passwordConfirm">Confirm Password</label>
            <Input
              idForLabel="passwordConfirm"
              required
              error={!this.props.passwordIsGood}
              onChange={this.props.onPasswordConfirmChange}
              placeholder={'Confirm Password'}
              type="password"
            />
            {this.props.passwordIsGood ? null : <label style={{color: 'red'}}>{this.props.passwordError}</label>}
          </div>
          <Button
            fullWidth={true}
            text={`Sign Up`}
            submitsForm={true}
          ></Button>
          


          <div>
          <p>Have an account?</p>
          <Button
            text={`Log In`}
            onClick={this.props.onClickLogin}
          ></Button>
        </div>
        </form>
        
        {showModal}
        {this.props.isLoading &&
          <LoadingGif />
        }
      </>
    )
  }
}

//rules for props being passed in
SignupForm.propTypes = {
  isEmailValid: PropTypes.func,
  emailError: PropTypes.bool,
  emailErrorText: PropTypes.string,
  disableButton: PropTypes.bool,
  onEmailChange: PropTypes.func,
  onSubmit: PropTypes.func
}

export default SignupForm
