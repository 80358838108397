import { createAction } from 'redux-actions'

// Actions
export const SET_IS_LOADING = 'carrierOnboarding/appSettings/SET_IS_LOADING'
export const UNSET_IS_LOADING = 'carrierOnboarding/appSettings/UNSET_IS_LOADING'
export const SET_WAITING_USER_RESPONSES = 'carrierOnboarding/appSettings/SET_WAITING_USER_RESPONSES'
export const UNSET_WAITING_USER_RESPONSES = 'carrierOnboarding/appSettings/UNSET_WAITING_USER_RESPONSES'
export const SET_WAITING_APP_DATA_SETTINGS = 'carrierOnboarding/appSettings/SET_WAITING_APP_DATA_SETTINGS'
export const UNSET_WAITING_APP_DATA_SETTINGS = 'carrierOnboarding/appSettings/UNSET_WAITING_APP_DATA_SETTINGS'
export const SET_WAS_REDIRECTED = 'carrierOnboarding/appSettings/SET_WAS_REDIRECTED'
export const UNSET_WAS_REDIRECTED = 'carrierOnboarding/appSettings/UNSET_WAS_REDIRECTED'
export const SET_IS_EDITING = 'carrierOnboarding/appSettings/SET_IS_EDITING'
export const UNSET_IS_EDITING = 'carrierOnboarding/appSettings/UNSET_IS_EDITING'
export const RESET = 'carrierOnboarding/auth/RESET'

const initialState = {
  isLoading: false,
  waitingOnUserResponses: true,
  waitingOnAppDataSettings: true,
  wasRedirected: false,
  isEditing: false
}
// Reducer
export default function appSettings(state = initialState, action) {
  switch (action.type) {
    case SET_WAITING_USER_RESPONSES:
      return {
        ...state,
        waitingOnUserResponses: true
      }
    case UNSET_WAITING_USER_RESPONSES:
      return {
        ...state,
        waitingOnUserResponses: false
      }
    case SET_WAITING_APP_DATA_SETTINGS:
      return {
        ...state,
        waitingOnAppDataSettings: true
      }
    case UNSET_WAITING_APP_DATA_SETTINGS:
      return {
        ...state,
        waitingOnAppDataSettings: false
      }
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case UNSET_IS_LOADING:
      return {
        ...state,
        isLoading: false
      }
    case SET_WAS_REDIRECTED:
      return {
        ...state,
        wasRedirected: true
      }
    case UNSET_WAS_REDIRECTED:
      return {
        ...state,
        wasRedirected: false
      }
    case SET_IS_EDITING:
      return {
        ...state,
        isEditing: true
      }
    case UNSET_IS_EDITING:
      return {
        ...state,
        isEditing: false
      }
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const setIsLoadingAppSettings = createAction(SET_IS_LOADING)
export const unsetIsLoadingAppSettings = createAction(UNSET_IS_LOADING)
export const setIsWaitingOnUserResponsesAppSettings = createAction(SET_WAITING_USER_RESPONSES)
export const unsetIsWaitingOnUserResponsesAppSettings = createAction(UNSET_WAITING_USER_RESPONSES)
export const setIsWaitingOnAppDataSettingsAppSettings = createAction(SET_WAITING_APP_DATA_SETTINGS)
export const unsetIsWaitingOnAppDataSettingsAppSettings = createAction(UNSET_WAITING_APP_DATA_SETTINGS)
export const setWasRedirectedAppSettings = createAction(SET_WAS_REDIRECTED)
export const unsetWasRedirectedAppSettings = createAction(UNSET_WAS_REDIRECTED)
export const setIsEditingAppSettings = createAction(SET_IS_EDITING)
export const unsetIsEditingAppSettings = createAction(UNSET_IS_EDITING)
export const resetAppSettings = createAction(RESET)