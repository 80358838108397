import React from 'react'
import PropTypes from 'prop-types'

//import components
import { SiteContainer } from '@intxlog/iuilib'
import QuestionWrapper from 'components/QuestionWrapper'
import FooterButtons from 'components/FooterButtons'
import EditingMessage from 'components/EditingMessage'

//import css
import styles from './styles.module.scss'

const QuestionPageTemplate = ({
  title,
  infoText,
  showNext,
  prevText,
  nextText,
  prevFunction,
  nextFunction,
  showPrev,
  disablePrev,
  disableNext,
  wrapInBorder,
  children,
  isEditing,
  disabled
}) => {
  //house the content so we can make it dynamic
  let content = null

  //logic for wrapping the content in the question wrapper
  if (wrapInBorder) {
    content = <QuestionWrapper>
      {children}
    </QuestionWrapper>
  } else {
    content = children
  }

  let disabledStyle = disabled ? styles.disabledContainer : null

  return (
    <SiteContainer>
      {isEditing && <EditingMessage></EditingMessage>}
      <h1>{title}</h1>
      <p className={styles.infoText}>{infoText}</p>
      <div className={disabledStyle}>
        {content}
      </div>
      <FooterButtons
        showPrev={showPrev}
        showNext={showNext}
        textPrev={prevText}
        textNext={nextText}
        onNextClick={nextFunction}
        onPrevClick={prevFunction}
        disableNext={disableNext}
        disablePrev={disablePrev}
      ></FooterButtons>
    </SiteContainer>
  )
}

//rules for props being passed in
QuestionPageTemplate.propTypes = {
  title: PropTypes.string,
  infoText: PropTypes.string,
  nextText: PropTypes.string,
  nextFunction: PropTypes.func,
  showNext: PropTypes.bool,
  prevText: PropTypes.string,
  prevFunction: PropTypes.func,
  disablePrev: PropTypes.bool,
  disableNext: PropTypes.bool,
  showPrev: PropTypes.bool,
  wrapInBorder: PropTypes.bool
}

// Specifies the default values for props:
QuestionPageTemplate.defaultProps = {
  wrapInBorder: true
}

export default QuestionPageTemplate