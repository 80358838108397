import { createAction } from 'redux-actions'

// Actions
export const SET_EQUIPMENT_TYPES_IS_VALID = 'carrierOnboarding/companyProfileForm/SET_EQUIPMENT_TYPES_IS_VALID'
export const UNSET_EQUIPMENT_TYPES_IS_VALID = 'carrierOnboarding/companyProfileForm/UNSET_EQUIPMENT_TYPES_IS_VALID'
export const SET_PROFILE_IS_VALID = 'carrierOnboarding/companyProfileForm/SET_PROFILE_IS_VALID'
export const UNSET_PROFILE_IS_VALID = 'carrierOnboarding/companyProfileForm/UNSET_PROFILE_IS_VALID'
export const SET_FORM_IS_SUBMITTED = 'carrierOnboarding/companyProfileForm/SET_FORM_IS_SUBMITTED'
export const UNSET_FORM_IS_SUBMITTED = 'carrierOnboarding/companyProfileForm/UNSET_FORM_IS_SUBMITTED'
export const RESET = 'carrierOnboarding/companyProfileForm/RESET'

const initialState = {
  equipmentTypesIsValid: false,
  profileIsValid: false
}
// Reducer
export default function companyProfileForm (state = initialState, action) {
  switch (action.type) {
    case SET_EQUIPMENT_TYPES_IS_VALID:
      return {
        ...state,
        equipmentTypesIsValid: true
      }
    case UNSET_EQUIPMENT_TYPES_IS_VALID:
      return {
        ...state,
        equipmentTypesIsValid: false
      }
    case SET_PROFILE_IS_VALID:
      return {
        ...state,
        profileIsValid: true
      }
    case UNSET_PROFILE_IS_VALID:
      return {
        ...state,
        profileIsValid: false
      }
    case SET_FORM_IS_SUBMITTED:
      return {
        ...state,
        formSubmitted: true
      }
    case UNSET_FORM_IS_SUBMITTED:
      return {
        ...state,
        formSubmitted: false
      }
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const setEquipmentTypesIsValidCompanyProfileForm = createAction(SET_EQUIPMENT_TYPES_IS_VALID)
export const unsetEquipmentTypesIsValidCompanyProfileForm = createAction(UNSET_EQUIPMENT_TYPES_IS_VALID)
export const setProfileIsValidCompanyProfileForm = createAction(SET_PROFILE_IS_VALID)
export const unsetProfileIsValidCompanyProfileForm = createAction(UNSET_PROFILE_IS_VALID)
export const setFormIsSubmittedCompanyProfileForm = createAction(SET_FORM_IS_SUBMITTED)
export const unsetFormIsSubmittedCompanyProfileForm = createAction(UNSET_FORM_IS_SUBMITTED)
export const resetCompanyProfileForm = createAction(RESET)