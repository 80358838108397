import IELAPIServiceFactory from './IELAPIServiceFactory'

export default class IELAPIUtilityService {

    constructor(props) {
        this.service = IELAPIServiceFactory.new();
    }

    submitFeedback(feedback) {
        feedback.project_code = 'cop';

        return this.service._withToken().post(`feedback/submit`, feedback).then(() => {
        }).catch(error => {
            this.service.handleError(error, false)
        })
    }


}
