import React from 'react'

//import components
import { SiteContainer } from '@intxlog/iuilib'

//render prop component to be used for formatting other components
class ErrorPage extends React.Component {
  render(){
    return (
      <SiteContainer>
        <h1>404- Error</h1>
        <p>The page you have tried to reach has moved or is unavailable.</p>
      </SiteContainer>
    )
  }
}

export default ErrorPage