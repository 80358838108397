import React from 'react'
import PropTypes from 'prop-types'

//import components
import {Button, RadioButton, RadioGroup} from '@intxlog/iuilib'

//import css
import styles from './styles.module.scss'

const QuickPayQuestions = ({
  onChange,
  value
}) => {
  return (
    <div className={styles.container}>
      <h2>Pay Options</h2>
      <p className={styles.titleText}>Please choose one of the following:</p>
      <div className={styles.questionWrapper }>
        <RadioGroup
          name={`quickPay`}
          onChange={onChange}
          defaultValue={value}
          className={styles.quickPayWrapper}
        >
          <RadioButton
            labelText={`30 Days`}
            value={`thirtyDay`}
          ></RadioButton>
          <div>No fees </div>
          <div>Payment will be issued within 30 days of IEL receiving complete paperwork.</div>
          <RadioButton
            labelText={`2 Days`}
            value={`twoDay`}
          ></RadioButton>
          <div>3% processing fee</div>
          <div>3% will be deducted from the gross truck rate. Payment will issue within 2 days of IEL receiving complete paperwork. </div>
          <a type={`emailLink`} href="mailto:info@triumphpay.com">info@triumphpay.com</a>
          <Button text={process.env.REACT_APP_PAYMENT_PHONE} type="link" elementType="link" linkTo={`tel:+${process.env.REACT_APP_PAYMENT_PHONE}`} target='_blank' rel="noopener noreferrer" >{process.env.REACT_APP_PAYMENT_PHONE}</Button>
        </RadioGroup>

      </div>
    </div>
  )
}

//rules for props being passed in
QuickPayQuestions.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
}

export default QuickPayQuestions
