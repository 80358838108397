import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

//import components
import QuestionPageTemplate from 'components/QuestionPageTemplate'

//import containers
import CompanyProfileQuestionsContainer from 'containers/CompanyProfileQuestionsContainer'
import EquipmentTypesContainer from 'containers/EquipmentTypesContainer'

//import utils
import navigateTo from 'utils/navigateTo'
import checkRequiredFormErrors from 'utils/checkRequiredFormErrors'
//import actions
import {
  setFormIsSubmittedCompanyProfileForm
} from 'modules/companyProfileForm'

export class ProfileContainer extends React.Component {
  handleProceed = () => {
    this.props.setFormIsSubmittedCompanyProfileForm()

    //only navigate to the next page
    if (this.props.isEquipmentValid && this.props.isProfileValid) {
      this.props.navigateTo(`/remit-information`)
    } else {
      this.props.checkRequiredFormErrors()
    }
  }

  handleBack = () => {
    //navigate to the page
    this.props.navigateTo(`/advance-options`)
  }

  render(){
    return (
      <QuestionPageTemplate
        title={`Company Profile`}
        infoText={`The following information was retrieved from SAFER and will be used for any & all Accounting communications. If you have questions, please reach out to ${process.env.REACT_APP_CARRIER_SERVICES_EMAIL} or ${process.env.REACT_APP_CARRIER_DEPT_PHONE}.`}
        prevText={`Back to Advance Options`}
        prevFunction={this.handleBack}
        nextText={`Proceed to Remit Information`}
        nextFunction={this.handleProceed}
        isEditing={this.props.isEditing}
        disabled={this.props.isEditing}
      >
        <CompanyProfileQuestionsContainer></CompanyProfileQuestionsContainer>
        <EquipmentTypesContainer></EquipmentTypesContainer>
      </QuestionPageTemplate>
    )
  }
}

const mapStateToProps = state => ({
  usesFactoringCompany: state.userResponses.usesFactoringCompany,
  isEquipmentValid: state.companyProfileForm.equipmentTypesIsValid,
  isProfileValid: state.companyProfileForm.profileIsValid,
  ...state.appSettings
})

const mapDispatchToProps = dispatch => bindActionCreators({
  checkRequiredFormErrors,
  navigateTo,
  setFormIsSubmittedCompanyProfileForm
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileContainer)
