import React from 'react'
import PropTypes from 'prop-types'
import { Input } from '@intxlog/iuilib'

//import css
// import styles from './styles.module.scss'

const EquipmentTypeLine = ({
  text,
  value,
  idForLabel,
  onChange,
  defaultChecked,
  disabled
}) => {
  return (
    <Input
      type={`checkbox`}
      value={value}
      idForLabel={idForLabel}
      labelText={text}
      onChange={onChange}
      defaultChecked={defaultChecked}
      disabled={disabled}
    />
  )
}

//rules for props being passed in
EquipmentTypeLine.propTypes = {
  text: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  idForLabel: PropTypes.string,
  onChange: PropTypes.func,
  defaultChecked: PropTypes.bool
}

export default EquipmentTypeLine