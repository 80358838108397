import { createAction } from 'redux-actions'

// Actions
export const UPDATE_EMAIL_VALUE = 'carrierOnboarding/loginForm/UPDATE_EMAIL_VALUE'
export const SET_EMAIL_ERROR = 'carrierOnboarding/loginForm/SET_EMAIL_ERROR'
export const UNSET_EMAIL_ERROR = 'carrierOnboarding/loginForm/UNSET_EMAIL_ERROR'
export const SET_EMAIL_IS_VALID = 'carrierOnboarding/loginForm/SET_EMAIL_IS_VALID'
export const UNSET_EMAIL_IS_VALID = 'carrierOnboarding/loginForm/UNSET_EMAIL_IS_VALID'
export const SET_MC_IS_VALID = 'carrierOnboarding/loginForm/SET_MC_IS_VALID'
export const UNSET_MC_IS_VALID = 'carrierOnboarding/loginForm/UNSET_MC_IS_VALID'
export const UPDATE_EMAIL_ERROR_TEXT = 'carrierOnboarding/loginForm/UPDATE_EMAIL_ERROR_TEXT'
export const UPDATE_CONTENT_AREA = 'carrierOnboarding/loginForm/UPDATE_CONTENT_AREA'
export const RESET = 'carrierOnboarding/loginForm/RESET'

const initialState = {
  emailInputValue: '',
  mcIsValid: false,
  emailIsValid: false,
  emailInputError: false,
  emailInputErrorText: '',
  contentArea: 'default'
}
// Reducer
export default function loginForm (state = initialState, action) {
  switch (action.type) {
    case UPDATE_EMAIL_VALUE:
      return {
        ...state,
        emailInputValue: action.payload
      }
    case SET_EMAIL_ERROR:
      return {
        ...state,
        emailInputError: true
      }
    case UNSET_EMAIL_ERROR:
      return {
        ...state,
        emailInputError: false
      }
    case SET_EMAIL_IS_VALID:
      return {
        ...state,
        emailIsValid: true
      }
    case UNSET_EMAIL_IS_VALID:
      return {
        ...state,
        emailIsValid: false,
      }
    case SET_MC_IS_VALID:
      return {
        ...state,
        mcIsValid: true
      }
    case UNSET_MC_IS_VALID:
      return {
        ...state,
        mcIsValid: false
      }
    case UPDATE_EMAIL_ERROR_TEXT:
      return {
        ...state,
        emailInputErrorText: action.payload
      }
    case UPDATE_CONTENT_AREA:
      return {
        ...state,
        contentArea: action.payload
      }
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const updateEmailValue = createAction(UPDATE_EMAIL_VALUE)
export const setEmailError = createAction(SET_EMAIL_ERROR)
export const unsetEmailError = createAction(UNSET_EMAIL_ERROR)
export const setMCIsValid = createAction(SET_MC_IS_VALID)
export const unsetMCIsValid = createAction(UNSET_MC_IS_VALID)
export const setEmailIsValid = createAction(SET_EMAIL_IS_VALID)
export const unsetEmailIsValid = createAction(UNSET_EMAIL_IS_VALID)
export const updateEmailErrorText = createAction(UPDATE_EMAIL_ERROR_TEXT)
export const updateContentArea = createAction(UPDATE_CONTENT_AREA)
export const resetLoginForm = createAction(RESET)
