import { createAction } from 'redux-actions'

// Actions
export const SET_NAME_IS_VALID = 'carrierOnboarding/taxForm/SET_NAME_IS_VALID'
export const UNSET_NAME_IS_VALID = 'carrierOnboarding/taxForm/UNSET_NAME_IS_VALID'
export const SET_CLASSIFICATION_IS_VALID = 'carrierOnboarding/taxForm/SET_CLASSIFICATION_IS_VALID'
export const UNSET_CLASSIFICATION_IS_VALID = 'carrierOnboarding/taxForm/UNSET_CLASSIFICATION_IS_VALID'
export const SET_CLASSIFICATION_LLC_IS_VALID = 'carrierOnboarding/taxForm/SET_CLASSIFICATION_LLC_IS_VALID'
export const UNSET_CLASSIFICATION_LLC_IS_VALID = 'carrierOnboarding/taxForm/UNSET_CLASSIFICATION_LLC_IS_VALID'
export const SET_CLASSIFICATION_OTHER_IS_VALID = 'carrierOnboarding/taxForm/SET_CLASSIFICATION_OTHER_IS_VALID'
export const UNSET_CLASSIFICATION_OTHER_IS_VALID = 'carrierOnboarding/taxForm/UNSET_CLASSIFICATION_OTHER_IS_VALID'
export const SET_ADDRESS_IS_VALID = 'carrierOnboarding/taxForm/SET_ADDRESS_IS_VALID'
export const UNSET_ADDRESS_IS_VALID = 'carrierOnboarding/taxForm/UNSET_ADDRESS_IS_VALID'
export const SET_CITY_IS_VALID = 'carrierOnboarding/taxForm/SET_CITY_IS_VALID'
export const UNSET_CITY_IS_VALID = 'carrierOnboarding/taxForm/UNSET_CITY_IS_VALID'
export const SET_STATE_IS_VALID = 'carrierOnboarding/taxForm/SET_STATE_IS_VALID'
export const UNSET_STATE_IS_VALID = 'carrierOnboarding/taxForm/UNSET_STATE_IS_VALID'
export const SET_ZIP_IS_VALID = 'carrierOnboarding/taxForm/SET_ZIP_IS_VALID'
export const UNSET_ZIP_IS_VALID = 'carrierOnboarding/taxForm/UNSET_ZIP_IS_VALID'
export const SET_EIN_IS_VALID = 'carrierOnboarding/taxForm/SET_EIN_IS_VALID'
export const UNSET_EIN_IS_VALID = 'carrierOnboarding/taxForm/UNSET_EIN_IS_VALID'
export const SET_SIGNATURE_IS_VALID = 'carrierOnboarding/taxForm/SET_SIGNATURE_IS_VALID'
export const UNSET_SIGNATURE_IS_VALID = 'carrierOnboarding/taxForm/UNSET_SIGNATURE_IS_VALID'
export const SET_DISABLE_OTHER_INPUT = 'carrierOnboarding/taxForm/SET_DISABLE_OTHER_INPUT'
export const UNSET_DISABLE_OTHER_INPUT = 'carrierOnboarding/taxForm/UNSET_DISABLE_OTHER_INPUT'
export const SET_DISABLE_LLC_OPTIONS = 'carrierOnboarding/taxForm/SET_DISABLE_LLC_OPTIONS'
export const UNSET_DISABLE_LLC_OPTIONS = 'carrierOnboarding/taxForm/UNSET_DISABLE_LLC_OPTIONS'
export const SET_FORM_IS_SUBMITTED = 'carrierOnboarding/taxForm/SET_FORM_IS_SUBMITTED'
export const UNSET_FORM_IS_SUBMITTED = 'carrierOnboarding/taxForm/UNSET_FORM_IS_SUBMITTED'
export const RESET = 'carrierOnboarding/taxForm/RESET'

const initialState = {
  nameIsValid: false,
  addressIsValid: false,
  classificationIsValid: false,
  classificationLLCisValid: false,
  classificationOtherIsValid: false,
  cityIsValid: false,
  stateIsValid: false,
  zipIsValid: false,
  einIsValid: false,
  signatureIsValid: false,
  disableOtherInput:false,
  disableLLCoptions: false,
  formSubmitted: 0
}
// Reducer
export default function taxForm (state = initialState, action) {
  switch (action.type) {
    case SET_NAME_IS_VALID: 
      return {
        ...state,
        nameIsValid: true
      }
    case UNSET_NAME_IS_VALID: 
      return {
        ...state,
        nameIsValid: false
      }
    case SET_CLASSIFICATION_IS_VALID: 
      return {
        ...state,
        classificationIsValid: true
      }
    case UNSET_CLASSIFICATION_IS_VALID: 
      return {
        ...state,
        classificationIsValid: false
      }
    case SET_CLASSIFICATION_LLC_IS_VALID: 
      return {
        ...state,
        classificationLLCisValid: true
      }
    case UNSET_CLASSIFICATION_LLC_IS_VALID: 
      return {
        ...state,
        classificationLLCisValid: false
      }
    case SET_CLASSIFICATION_OTHER_IS_VALID: 
      return {
        ...state,
        classificationOtherIsValid: true
      }
    case UNSET_CLASSIFICATION_OTHER_IS_VALID: 
      return {
        ...state,
        classificationOtherIsValid: false
      }
    case SET_ADDRESS_IS_VALID: 
      return {
        ...state,
        addressIsValid: true
      }
    case UNSET_ADDRESS_IS_VALID: 
      return {
        ...state,
        addressIsValid: false
      }
    case SET_CITY_IS_VALID: 
      return {
        ...state,
        cityIsValid: true
      }
    case UNSET_CITY_IS_VALID: 
      return {
        ...state,
        cityIsValid: false
      }
    case SET_STATE_IS_VALID: 
      return {
        ...state,
        stateIsValid: true
      }
    case UNSET_STATE_IS_VALID: 
      return {
        ...state,
        stateIsValid: false
      }
    case SET_ZIP_IS_VALID: 
      return {
        ...state,
        zipIsValid: true
      }
    case UNSET_ZIP_IS_VALID: 
      return {
        ...state,
        zipIsValid: false
      }
    case SET_EIN_IS_VALID: 
      return {
        ...state,
        einIsValid: true
      }
    case UNSET_EIN_IS_VALID: 
      return {
        ...state,
        einIsValid: false
      }
    case SET_SIGNATURE_IS_VALID: 
      return {
        ...state,
        signatureIsValid: true
      }
    case UNSET_SIGNATURE_IS_VALID: 
      return {
        ...state,
        signatureIsValid: false
      }
    case SET_DISABLE_OTHER_INPUT: 
      return {
        ...state,
        disableOtherInput: true
      }
    case UNSET_DISABLE_OTHER_INPUT: 
      return {
        ...state,
        disableOtherInput: false
      }
    case SET_DISABLE_LLC_OPTIONS: 
      return {
        ...state,
        disableLLCoptions: true
      }
    case UNSET_DISABLE_LLC_OPTIONS: 
      return {
        ...state,
        disableLLCoptions: false
      }
    case SET_FORM_IS_SUBMITTED: 
      return {
        ...state,
        formSubmitted: parseInt(state.formSubmitted) + 1
      }
    case UNSET_FORM_IS_SUBMITTED: 
      return {
        ...state,
        formSubmitted: 0
      }
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const setNameIsValidTaxForm = createAction(SET_NAME_IS_VALID)
export const unsetNameIsValidTaxForm = createAction(UNSET_NAME_IS_VALID)
export const setClassificationIsValidTaxForm = createAction(SET_CLASSIFICATION_IS_VALID)
export const unsetClassificationIsValidTaxForm = createAction(UNSET_CLASSIFICATION_IS_VALID)
export const setClassificationLLCisValidTaxForm = createAction(SET_CLASSIFICATION_LLC_IS_VALID)
export const unsetClassificationLLCisValidTaxForm = createAction(UNSET_CLASSIFICATION_LLC_IS_VALID)
export const setClassificationOtherIsValidTaxForm = createAction(SET_CLASSIFICATION_OTHER_IS_VALID)
export const unsetClassificationOtherIsValidTaxForm = createAction(UNSET_CLASSIFICATION_OTHER_IS_VALID)
export const setAddressIsValidTaxForm = createAction(SET_ADDRESS_IS_VALID)
export const unsetAddressIsValidTaxForm = createAction(UNSET_ADDRESS_IS_VALID)
export const setCityIsValidTaxForm = createAction(SET_CITY_IS_VALID)
export const unsetCityIsValidTaxForm = createAction(UNSET_CITY_IS_VALID)
export const setStateIsValidTaxForm = createAction(SET_STATE_IS_VALID)
export const unsetStateIsValidTaxForm = createAction(UNSET_STATE_IS_VALID)
export const setZipIsValidTaxForm = createAction(SET_ZIP_IS_VALID)
export const unsetZipIsValidTaxForm = createAction(UNSET_ZIP_IS_VALID)
export const setEINIsValidTaxForm = createAction(SET_EIN_IS_VALID)
export const unsetEINIsValidTaxForm = createAction(UNSET_EIN_IS_VALID)
export const setSignatureIsValidTaxForm = createAction(SET_SIGNATURE_IS_VALID)
export const unsetSignatureIsValidTaxForm = createAction(UNSET_SIGNATURE_IS_VALID)
export const setDisableOtherInputTaxForm = createAction(SET_DISABLE_OTHER_INPUT)
export const unsetDisableOtherInputTaxForm = createAction(UNSET_DISABLE_OTHER_INPUT)
export const setDisableLLCoptionsTaxForm = createAction(SET_DISABLE_LLC_OPTIONS)
export const unsetDisableLLCoptionsTaxForm = createAction(UNSET_DISABLE_LLC_OPTIONS)
export const setFormIsSubmittedTaxForm = createAction(SET_FORM_IS_SUBMITTED)
export const unsetFormIsSubmittedTaxForm = createAction(UNSET_FORM_IS_SUBMITTED)
export const resetTaxForm = createAction(RESET)