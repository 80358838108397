import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@intxlog/iuilib'

//import css
import styles from './styles.module.scss'

const CompanyLookupResults = ({
  name,
  dbaName,
  street,
  city,
  state,
  phone,
  fax,
  email,
  mcNumber,
  dotNumber,
  isFromInvite
}) => {
  let fromInviteInfo = isFromInvite ? <p>If the information below is incorrect, please reach out to our Carrier Services Team at <Button text={process.env.REACT_APP_CARRIER_DEPT_PHONE} type="link" elementType="link" linkTo={`tel:+${process.env.REACT_APP_CARRIER_DEPT_PHONE}`} target='_blank' rel="noopener noreferrer" >{process.env.REACT_APP_CARRIER_DEPT_PHONE}</Button> to correct this.</p> : null

  return (
    <div className={styles.container}>
      <div className={styles.infoText}>We received the following information about your company. Please verify this is your company's information before continuing. {fromInviteInfo} </div>
      <div className={styles.infoWrapper}>
        <div className={styles.title}>Name:</div>
        <div>{name}</div>
        <div className={styles.title}>DBA Name:</div>
        <div>{dbaName}</div>
        <div className={styles.title}>Address:</div>
        <div>{street}</div>
        <div className={styles.title}>City:</div>
        <div>{city}</div>
        <div className={styles.title}>State:</div>
        <div>{state}</div>
        <div className={styles.title}>Phone:</div>
        <div>{phone}</div>
        <div className={styles.title}>Fax:</div>
        <div>{fax}</div>
        <div className={styles.title}>Email:</div>
        <div>{email}</div>
        <div className={styles.title}>MC #:</div>
        <div>{mcNumber}</div>
        <div className={styles.title}>DOT #:</div>
        <div>{dotNumber}</div>
      </div>
    </div>
  )
}

//rules for props being passed in
CompanyLookupResults.propTypes = {
  name: PropTypes.string,
  dbaName: PropTypes.string,
  street: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  phone: PropTypes.string,
  fax: PropTypes.string,
  email: PropTypes.string,
  mcNumber: PropTypes.string,
  dotNumber: PropTypes.string
}

export default CompanyLookupResults