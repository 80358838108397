import React from 'react'
import PropTypes from 'prop-types'

//import css
import styles from './styles.module.scss'

const EquipmentTypes = ({
  lines,
  errorMessage
}) => {
  return (
    <div className={styles.container}>
      <p>Please choose Equipment Types (Required): <span className={styles.errorMessage}>{errorMessage}</span></p>
      <div className={styles.listWrapper}>
        {lines}
      </div>
    </div>
  )
}

//rules for props being passed in
EquipmentTypes.propTypes = {
  lines: PropTypes.array
}

// // Specifies the default values for props:
// EquipmentTypes.defaultProps = {
//
// }

export default EquipmentTypes
