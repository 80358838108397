import React from 'react'
import { Route, Switch } from 'react-router-dom'

//import components
import ThankYou from 'components/ThankYou'

//import containers
import AdminContainer from 'containers/AdminContainer'

const UserCompletedRouter = () => {
  return (
    <Switch>
      {process.env.REACT_APP_ENV !== `prod` &&
        <Route path="/admin" component={AdminContainer} />
      }
      <Route component={ThankYou} />
    </Switch>
  )
}

export default UserCompletedRouter