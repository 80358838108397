import { createAction } from 'redux-actions'

// Actions
export const UPDATE = 'carrierOnboarding/equipmentTypes/UPDATE'
export const RESET = 'carrierOnboarding/equipmentTypes/RESET'

const initialState = []
// Reducer
export default function equipmentTypes (state = initialState, action) {
  switch (action.type) {
    case UPDATE:
      return action.payload
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const updateEquipmentTypes = createAction(UPDATE)
export const resetEquipmentTypes = createAction(RESET)