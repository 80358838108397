import handleUpdateAppDataSettings from "utils/handleUpdateAppDataSettings";
import handleUpdateUserResponses from "utils/handleUpdateUserResponses";

//user confirms data is correct from SaferWatch. Map the Saferwatch data to the users responses
export default function confirmCompanyData(obj) {

  return dispatch => {
    const companyName = obj.name
    const companyDBAName = obj.dbaName
    const companyAddress = obj.street
    const companyCity = obj.city
    const companyState = obj.state
    const companyZip = obj.zip
    const companyCountry = obj.country
    const companyPhone = obj.phone
    const companyFax = obj.fax
    const companyEmail = obj.email
    const companyMCNumber = obj.mcNumber
    const companyDOTNumber = obj.dotNumber

    //map the needed fields from the carrier Object
    const responses = {
      companyName,
      companyDBAName,
      companyAddress,
      companyAddress2: ``,
      companyCity,
      companyState,
      companyZip,
      companyCountry,
      companyPhone,
      companyFax,
      companyEmail: companyEmail,
      companyMCNumber,
      companyDOTNumber,
      accountingEmail: companyEmail,
      accountingPhone: companyPhone,
      accountingFax: companyFax,
      remitCompanyName: companyName,
      remitCompanyAddress: companyAddress,
      remitCompanyCity: companyCity,
      remitCompanyState: companyState,
      remitCompanyZip: companyZip,
      remitCompanyCountry: companyCountry,
      remitCompanyPhone: companyPhone,
      remitCompanyEmail: companyEmail,
      remitCompanyFax: companyFax,
      remitCompanyPayToFirstName: ``,
      remitCompanyPayToLastName: ``,
      usesFactoringCompany: false,
      factoringCompanyName: ``,
      dispatcherAccessorialCharges: false,
      dispatcherFuelAdvances: false,
      driverAccessorialCharges: false,
      driverFuelAdvances: false,
      quickPayPreference: `thirtyDay`,
      equipmentTypes: [],
      preferredLanes: [],
      payToCompanyName: companyName,
      w9Name: companyName,
      w9BusinessName: companyDBAName,
      w9TaxClassification: null,
      w9TaxClassificationLLCoptions: null,
      w9TaxClassificationOther: null,
      w9Address: companyAddress,
      w9City: companyCity,
      w9State: companyState,
      w9Zip: companyZip,
      w9NumberType: null,
      w9TaxNumber: ``,
      w9Signature: ``,
      w9Date: ``,
      serviceAgreementSignature: ``
    }

    //scroll the page back up
    window.scrollTo(0, 0)

    dispatch(handleUpdateUserResponses(responses))
    dispatch(handleUpdateAppDataSettings({ userConfirmed: true }))
  }
}
