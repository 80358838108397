import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

//import components
import AdvanceOptions from 'components/AdvanceOptions'
import QuestionPageTemplate from 'components/QuestionPageTemplate'


//import utils
import navigateTo from 'utils/navigateTo'
import handleUpdateUserResponses from 'utils/handleUpdateUserResponses'
import checkRequiredFormErrors from 'utils/checkRequiredFormErrors'

export class AdvanceContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formSubmitted: 0
    }
  }

  handleDispatcherAccessorial = (val) => {
    //val is coming in as a string. convert to boolean
    val = (val === 'true')

    //save the response
    this.props.handleUpdateUserResponses({ dispatcherAccessorialCharges: val })
  }

  handleDispatcherFuel = (val) => {
    //val is coming in as a string. convert to boolean
    val = (val === 'true')

    //save the response
    this.props.handleUpdateUserResponses({ dispatcherFuelAdvances: val })
  }

  handleDriverAccessorial = (val) => {
    //val is coming in as a string. convert to boolean
    val = (val === 'true')

    //save the response
    this.props.handleUpdateUserResponses({ driverAccessorialCharges: val })
  }

  handleDriverFuel = (val) => {
    //val is coming in as a string. convert to boolean
    val = (val === 'true')

    //save the response
    this.props.handleUpdateUserResponses({ driverFuelAdvances: val })
  }

  handleBack = () => {
    this.props.navigateTo(`/`)
  }

  handleProceed = () => {
    this.props.navigateTo('/company-profile')
  }

  render() {
    return (
      <QuestionPageTemplate
        title={`Advance Options`}
        infoText={`Please answer the following questions regarding advance options:`}
        prevText={`Back to Payment Options`}
        prevFunction={this.handleBack}
        nextText={`Proceed to Company Profile`}
        nextFunction={this.handleProceed}
        wrapInBorder={false}
        isEditing={this.props.isEditing}
        disabled={false} //Allow advance options enabled
      >
        <AdvanceOptions
          handleDispatcherAccessorial={this.handleDispatcherAccessorial}
          handleDispatcherFuel={this.handleDispatcherFuel}
          handleDriverAccessorial={this.handleDriverAccessorial}
          handleDriverFuel={this.handleDriverFuel}
          dispatcherAccessorial={this.props.dispatcherAccessorial}
          dispatcherFuel={this.props.dispatcherFuel}
          driverAccessorial={this.props.driverAccessorial}
          driverFuel={this.props.driverFuel}
          formSubmitted={this.state.formSubmitted}
        ></AdvanceOptions>
      </QuestionPageTemplate>
    )
  }
}

const mapStateToProps = state => ({
  dispatcherAccessorial: state.userResponses.dispatcherAccessorialCharges,
  dispatcherFuel: state.userResponses.dispatcherFuelAdvances,
  driverAccessorial: state.userResponses.driverAccessorialCharges,
  driverFuel: state.userResponses.driverFuelAdvances,
  uid: state.userData.uid,
  ...state.appSettings
})

const mapDispatchToProps = dispatch => bindActionCreators({
  checkRequiredFormErrors,
  navigateTo,
  handleUpdateUserResponses
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvanceContainer)
