import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import navigateTo from 'utils/navigateTo'

//import actions
import {
  updateEmailValue,
  setMCIsValid,
  unsetMCIsValid,
  setEmailIsValid,
  unsetEmailIsValid
} from 'modules/loginForm'

import {
  updateNumberTypeCompanyLookup,
  updateNumberCompanyLookup
} from 'modules/companyLookup'

import { resetModal, toggleOpenModal, setOpenModal, setClosedModal } from 'modules/modal'
import SignupForm from 'components/SignupForm'
import IELAPIUserService from 'services/IELAPIUserService'

export class SignupContainer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      emailDisabled: false,
      password: '',
      passwordConfirm: '',
      passwordIsGood: true,
      passwordError: ''
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.carrier.mcNumber !== this.props.carrier.mcNumber) {
      this.props.updateNumberType('MC')
      this.props.updateNumber(this.props.carrier.mcNumber)
    } else if (prevProps.carrier.dotNumber !== this.props.carrier.dotNumber) {
      this.props.updateNumberType('DOT')
      this.props.updateNumber(this.props.carrier.dotNumber)
    }

    if (prevProps.carrier.email !== this.props.carrier.email) {
      this.props.updateEmailValue(this.props.carrier.email)
      this.props.setEmailIsValid()
    }
  }

  componentDidMount() {
    this.userService = new IELAPIUserService();
    
    // Set the email field from the URL email prop if one exists
    this.setState({ email: this.props.email });
  }

  handleEmailChange = (val) => {
    this.setState({ email: val })
  }

  handleNameChange = (val) => {
    this.setState({ name: val })
  }

  handlePasswordChange = (val) => {
    this.setState({ password: val })
  }

  handlePasswordConfirmChange = (val) => {
    this.setState({ passwordConfirm: val })
  }

  handleSignupSubmit = (e) => {
    e.preventDefault()

    if (this.state.password === '') {
      this.setState({ passwordIsGood: false, passwordError: 'Password cannot be empty.' })
    } else {
      if (this.state.password !== this.state.passwordConfirm) {
        this.setState({ passwordIsGood: false, passwordError: 'Please make sure your passwords match.' })
      } else {
        this.setState({ passwordIsGood: true, passwordError: '' })
        this.userService.register(this.state.name, this.state.email, this.state.password, this.props.inviteInfo);
      }
    }
  }

  handleEmailIsValid = (bool) => {
    if (bool) {
      this.props.setEmailIsValid()
    } else {
      this.props.unsetEmailIsValid()
    }
  }

  onClickLogin = () => {
    this.props.navigateTo(`/login`)
  }

  handleModalClose = () => {
    this.props.setClosedModal()
    this.props.resetModal()
  }

  render() {
    return (
      <SignupForm
        emailInputValue={this.state.email}
        emailError={this.props.emailInputError}
        emailErrorText={this.props.emailInputErrorText}
        isEmailValid={this.handleEmailIsValid}
        emailDisabled={this.state.emailDisabled}
        disableButton={!this.props.emailIsValid || !this.props.mcIsValid || !this.props.companyLookup.numberType}
        onEmailChange={(val) => this.handleEmailChange(val)}
        onPasswordChange={(val) => this.handlePasswordChange(val)}
        onPasswordConfirmChange={(val) => this.handlePasswordConfirmChange(val)}
        onSubmit={this.handleSignupSubmit}
        isLoading={this.props.isLoading}
        modal={this.props.modal}
        handleModalClose={this.handleModalClose}
        onClickLogin={this.onClickLogin}
        passwordIsGood={this.state.passwordIsGood}
        passwordError={this.state.passwordError}
        onNameChange={(val) => this.handleNameChange(val)}
      ></SignupForm>
    )
  }
}

const mapStateToProps = state => ({
  ...state.loginForm,
  isLoading: state.appSettings.isLoading,
  modal: state.modal,
  auth: state.auth,
  carrier: state.carrier,
  inviteInfo: state.inviteInfo
})

const mapDispatchToProps = dispatch => bindActionCreators({
  updateEmailValue,
  setMCIsValid,
  unsetMCIsValid,
  setEmailIsValid,
  unsetEmailIsValid,
  updateNumberType: updateNumberTypeCompanyLookup,
  updateNumber: updateNumberCompanyLookup,
  resetModal,
  toggleOpenModal,
  setOpenModal,
  setClosedModal,
  navigateTo
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupContainer)