import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// Import Components
import { SiteContainer } from '@intxlog/iuilib'
import Logo from 'components/Logo'

//import styles
import styles from './styles.module.scss'
import Avatar from 'components/Avatar'

//render prop component to be used for formatting other components
class Header extends React.Component {
  handleOpenProfile = () => {
    this.props.navigateTo(`/profile`)
  }

  render() {
    //inline styles
    let inlineStyles = {
      height: this.props.height
    }

    return (
      <div className={styles.container}>
        <SiteContainer>
          <div className={styles.innerWrapper} style={inlineStyles}>
            <Link to={`/`}>
              <Logo
                makeLink={true}
              ></Logo>
            </Link>
            <span className={styles.title}>Carrier OnBoarding</span>
            {/* Only show avatar dropdown if user is logged in (has a user ID set) */}
            {this.props.userData.id &&
              <Avatar
                name={this.props.userData.name}
                completedProcess={this.props.completedProcess}
                logoutFunction={this.props.logoutFunction}
                editInfoFunction={this.props.editInfoFunction}
              ></Avatar>
            }
          </div>
        </SiteContainer>
      </div>
    )
  }
}

//rules for props being passed in
Header.propTypes = {
  height: PropTypes.string,
  menuOpen: PropTypes.bool,
  userData: PropTypes.object,
  toggleMenu: PropTypes.func,
  navigateTo: PropTypes.func
}

// Specifies the default values for props:
Header.defaultProps = {
  height: 'auto',
  userData: {}
}

export default Header