import IELAPIService from 'iel-api.js'

import store from 'store'

import navigateTo from 'utils/navigateTo'

import { setTypeModal, setTextModal, setOpenModal } from 'modules/modal'

import {
    userLoggedInAuth
} from 'modules/auth'

import { updateUserData } from 'modules/userData'

import {
    unsetIsWaitingOnAppDataSettingsAppSettings,
    unsetIsWaitingOnUserResponsesAppSettings
} from 'modules/appSettings'

import {
    setUserResponses
} from 'modules/userResponses'

import { setAppDataSettings } from 'modules/appDataSettings'

export default class COPIELAPIService extends IELAPIService {
    userInfo() {
        //Not catching the error here since we will catch it on the login call, 
        //that way if we have a case where the user has an account but the email isnt verified, we can 
        //let them know to verify it
        return this._withToken().get('COP/carrier/userInfo')
            .then((result) => {
                const resObj = result.data.data;
                store.dispatch(updateUserData(resObj))

                return resObj;
            })
    }

    getUserData() {
        return this._withToken().get(`COP/carrier/userData`).then((result) => {
            const resObj = result.data.data;

            //user responses:
            if (resObj.user_responses) {
                store.dispatch(setUserResponses(resObj.user_responses))
            }
            store.dispatch(unsetIsWaitingOnUserResponsesAppSettings())

            //app data settings:
            if (resObj.app_data_settings) {
                store.dispatch(setAppDataSettings(resObj.app_data_settings))
            }
            store.dispatch(unsetIsWaitingOnAppDataSettingsAppSettings())

        }).catch(error => {
            this.handleError(error, true)
        })
    }

    afterLoginError(error) {
        this.handleError(error, true, null)
    }

    afterLoginSuccess(result) {
        //set tokens and logged in items on the session storage
        sessionStorage.setItem(`accessToken`, this.accessToken)
        sessionStorage.setItem(`refreshToken`, this.refreshToken)

        result = this.userInfo().then((result) => {
            if (result) {
                this.getUserData().then(() => {
                    store.dispatch(navigateTo(`/`))
                    store.dispatch(userLoggedInAuth())
                })
            }
        }).catch(error => {
            this.handleError(error, true, null)
        });
    }

    // Handle error method that can be called anywhere the service is used, just uses existing handleError stuffs
    handleError = (error, showModal, userInfo) => {
        let message = null;
        if (showModal) {
            store.dispatch(setTypeModal(`error`))

            if (error.response && error.response.data.message) {
                message = error.response.data.message
            } else {
                message = `An unknown error has occured. Please try again, and if the issue persists, please contact ` + process.env.REACT_APP_CARRIER_SERVICES_EMAIL + `.`
            }

            store.dispatch(setTextModal(message))
            store.dispatch(setOpenModal())
        }
    }

    _withToken() {
        // Need to set the token in parent from Session
        super.accessToken = sessionStorage.getItem('accessToken') ? sessionStorage.getItem('accessToken') : this.accessToken;
        super.refreshToken = sessionStorage.getItem('refreshToken') ? sessionStorage.getItem('refreshToken') : this.refreshToken;
        return super._withToken();
    }
}