import React from 'react'
import PropTypes from 'prop-types'

//import css
import styles from './styles.module.scss'

//render prop component to be used for formatting other components
class LabelWrapper extends React.Component {
  render(){
    let labelText = this.props.text

    //conditional to add the asterisk for required fields
    if (this.props.isRequired) {
      labelText = `*${this.props.text}`
    }

    return (
      <React.Fragment>
        <label className={styles.label} htmlFor={this.props.id}>{labelText}</label>
        {this.props.children}
      </React.Fragment>
    )
  }
}

//rules for props being passed in
LabelWrapper.propTypes = {
  id: PropTypes.string,
  isRequired: PropTypes.bool,
  text: PropTypes.string
}

// Specifies the default values for props:
LabelWrapper.defaultProps = {
  isRequired: false
}

export default LabelWrapper