import COPIELAPIService from './COPIELAPIService'
import store from 'store'

export default class IELAPIServiceFactory {
    static new() {
        return new COPIELAPIService({
            baseURL: process.env.REACT_APP_ITMS_API_URL,
            accessToken: store.accessToken,
            refreshToken: store.refreshToken
        })
    }
}