import React from 'react'
import { Button } from '@intxlog/iuilib'
// import PropTypes from 'prop-types'

//import css
import styles from './styles.module.scss'

const TaxFormResponseMessage = () => {
  return (
    <div className={styles.container}>
      <p>If you have a Canadian business address, then please submit a completed copy of IRS Form W8 to  <Button text={process.env.REACT_APP_CARRIER_SERVICES_EMAIL} type="link" elementType="link" linkTo={`mailto:${process.env.REACT_APP_CARRIER_SERVICES_EMAIL}`}  rel='noopener noreferrer' target='_top'>{process.env.REACT_APP_CARRIER_SERVICES_EMAIL}</Button> or call <Button text={process.env.REACT_APP_CARRIER_DEPT_PHONE} type="link" elementType="link" linkTo={`tel:+${process.env.REACT_APP_CARRIER_DEPT_PHONE}`} target='_blank' rel="noopener noreferrer" >{process.env.REACT_APP_CARRIER_DEPT_PHONE}</Button> with any questions.</p>
    </div>
  )
}

//rules for props being passed in
// TaxFormResponseMessage.propTypes = {

// }

export default TaxFormResponseMessage