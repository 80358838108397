import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@intxlog/iuilib'

//import css
import styles from './styles.module.scss'

class FooterButtons extends React.Component {
  state = {
    width: 0,
    height: 0,
    breakpoint: 560
  }

  componentDidMount = () => {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }
  
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  render(){
    let textPrev = `Back`
    let textNext = `Next`

    if (this.state.width > this.state.breakpoint) {
      textPrev = this.props.textPrev
      textNext = this.props.textNext
    }

    return (
      <div className={styles.container}>
        {/* conditional to show buttons */}
        {this.props.showPrev &&
          <Button
            text={textPrev}
            onClick={this.props.onPrevClick}
            className={styles.left}
            disabled={this.props.disablePrev}
          ></Button>
        }
        {this.props.showNext &&
          <Button
            text={textNext}
            onClick={this.props.onNextClick}
            className={styles.right}
            disabled={this.props.disableNext}
          ></Button>
        }
      </div>
    )
  }
}

//rules for props being passed in
FooterButtons.propTypes = {
  showNext: PropTypes.bool,
  showPrev: PropTypes.bool, 
  textNext: PropTypes.string,
  textPrev: PropTypes.string,
  onNextClick: PropTypes.func,
  onPrevClick: PropTypes.func,
  disablePrev: PropTypes.bool,
  disableNext: PropTypes.bool
}

// Specifies the default values for props:
FooterButtons.defaultProps = {
  showNext: true,
  showPrev: true 
}

export default FooterButtons