import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

//import components
import EquipmentTypeLine from 'components/EquipmentTypeLine'
import EquipmentTypes from 'components/EquipmentTypes'

//import utils
import handleUpdateUserResponses from 'utils/handleUpdateUserResponses'

//import actions
import {
  setEquipmentTypesIsValidCompanyProfileForm,
  unsetEquipmentTypesIsValidCompanyProfileForm
} from 'modules/companyProfileForm'
import IELAPICarrierService from 'services/IELAPICarrierService'

export class EquipmentTypesContainer extends React.Component {
  componentDidMount = () => {
    this.apiService = new IELAPICarrierService();
    this.apiService.getTrailerTypes();

    if (this.props.userEquipmentTypesArray.length > 0) {
      this.props.setEquipmentTypesIsValidCompanyProfileForm()
    }
  }

  componentDidUpdate = (prevProps) => {
    const prevLength = prevProps.userEquipmentTypesArray.length
    const currentLength = this.props.userEquipmentTypesArray.length

    //check if the value changed to cut down on updates
    if (prevLength !== currentLength) {
      if (currentLength > 0) {
        this.props.setEquipmentTypesIsValidCompanyProfileForm()
      } else {
        this.props.unsetEquipmentTypesIsValidCompanyProfileForm()
      }
    }
  }

  handleChange = (value) => {
    const userTypesArray = this.props.userEquipmentTypesArray
    const val = parseInt(value)

    //check to see if the value is in the users array. If it is then remove it and if its not add it
    if (userTypesArray.indexOf(val) > -1) {
      //copy the current equipment types array
      const newArray = userTypesArray.slice()

      //modify the array by removing the value
      newArray.splice(newArray.indexOf(val), 1)

      //save the response
      this.props.handleUpdateUserResponses({equipmentTypes: newArray})
    } else {
      // copy the current equipment types array
      const newArray = userTypesArray.slice()
  
      //modify the array by adding the new value
      newArray.push(val)
  
      //save the response
      this.props.handleUpdateUserResponses({equipmentTypes: newArray})
    } //end if
  }

  render(){ 
    const arrayET = this.props.equipmentTypesArray
    let equipmentLinesArray = []
    let errorMessage = ``

    if (this.props.formSubmitted && !this.props.isValid) {
      errorMessage = `You must choose at least one equipment type!`
    }

    //loop through the equipment types and add a line for each type
    for (let i = 0; i < arrayET.length; i++) {
      const typeID = arrayET[i].id
      const type = arrayET[i].value

      equipmentLinesArray.push(
        <EquipmentTypeLine
          key={typeID}
          text={type}
          value={typeID}
          idForLabel={`equipType${typeID}`}
          onChange={this.handleChange}
          defaultChecked={this.props.userEquipmentTypesArray.indexOf(typeID) > -1 ? true : false}
          error={this.props.formSubmitted && !this.props.isValid}
          disabled={this.props.isEditing}
        ></EquipmentTypeLine>
      )
    }

    return <EquipmentTypes
      errorMessage={errorMessage}
      lines={equipmentLinesArray}
    ></EquipmentTypes>
  }
}

const mapStateToProps = state => ({
  equipmentTypesArray: state.equipmentTypes,
  userEquipmentTypesArray: state.userResponses.equipmentTypes,
  uid: state.userData.uid,
  formSubmitted: state.companyProfileForm.formSubmitted,
  isValid: state.companyProfileForm.equipmentTypesIsValid,
  ...state.appSettings
})

const mapDispatchToProps = dispatch => bindActionCreators({
  handleUpdateUserResponses,
  setEquipmentTypesIsValidCompanyProfileForm,
  unsetEquipmentTypesIsValidCompanyProfileForm
}, dispatch)

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(EquipmentTypesContainer)