import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from "react-router-dom"


//import utils
import navigateTo from 'utils/navigateTo'
import confirmCompanyData from 'utils/confirmCompanyData'

import handleUpdateUserResponses from 'utils/handleUpdateUserResponses'

//import components
import CompanyLookup from 'components/CompanyLookup'
import CompanyLookupResults from 'components/CompanyLookupResults'
import CompanyLookupErrorResult from 'components/CompanyLookupErrorResult'
import FooterButtons from 'components/FooterButtons'

//import actions
import {
  updateNumberCompanyLookup,
  updateNumberTypeCompanyLookup,
  unsetSubmittedCompanyLookup,
} from 'modules/companyLookup'
import IELAPICarrierService from 'services/IELAPICarrierService'

export class HomeContainerFromInvite extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      //Carrier Identification Number
      CID: ''
    }

    this.apiService = new IELAPICarrierService();
  }

  componentDidMount() {
    let number = this.props.userResponses.companyMCNumber ? this.props.userResponses.companyMCNumber : this.props.userResponses.companyDOTNumber
    if (number !== '' && number !== null) {
      let isMCNumber = this.props.userResponses.companyMCNumber ? true : false

      this.apiService.lookUpCarrierInfo(isMCNumber, number);
    }
  }

  //handle the Carrier Identification Change
  handleCIDtextChange = (val) => {
    this.setState({
      CID: val
    })
  }

  //submit the lookup form
  handleLookupSubmit = (e) => {
    e.preventDefault()
    let number = this.state.CID
    var isMCNumber = false;

    if (this.props.numberType === 'MC') {
      isMCNumber = true
    }

    this.apiService.lookUpCarrierInfo(isMCNumber, number);
  }

  //handle the CID number change
  handleCIDChange = (val) => {
    this.props.updateNumberType(val)
  }

  proceedToPaymentOptions = () => {
    this.props.confirmCompanyData(this.props.carrierInfo)
    this.props.navigateTo('/')
  }

  render() {
    let resultsContent = null

    //if the form was submitted then show some content
    if (this.props.submitted) {
      if (this.props.hasResult) {
        resultsContent = <>
          <CompanyLookupResults
            {...this.props.carrierInfo}
            isFromInvite={true}
          ></CompanyLookupResults>


          <FooterButtons
            textNext={`Proceed to Payment Options`}
            onNextClick={() => { this.proceedToPaymentOptions() }}
            showPrev={false}
          ></FooterButtons>
        </>
      }
    } else {
      resultsContent = <CompanyLookupErrorResult></CompanyLookupErrorResult>
    }

    return (
      <CompanyLookup
        chooseLookupTypeComponent={null}
        lookupFormComponent={null}
        resultsContent={resultsContent}
        isFromInvite={true}
      />
    )
  }
}

const mapStateToProps = state => ({
  carrierInfo: state.carrier,
  uid: state.userData.uid,
  saferWatchData: state.saferWatchResponse,
  companyLookup: state.companyLookup,
  appDataSettings: state.appDataSettings,
  userResponses: state.userResponses,
  ...state.companyLookup
})

const mapDispatchToProps = dispatch => bindActionCreators({
  navigateTo,
  updateNumber: updateNumberCompanyLookup,
  updateNumberType: updateNumberTypeCompanyLookup,
  unsetSubmitted: unsetSubmittedCompanyLookup,
  confirmCompanyData,
  handleUpdateUserResponses
}, dispatch)

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeContainerFromInvite))
