import { createAction } from 'redux-actions'

// Actions
export const SET_TOKEN = 'carrierOnboarding/inviteInfo/SET_TOKEN'
export const SET_INVITE_INVALID = 'carrierOnboarding/inviteInfo/SET_INVITE_INVALID'
export const UPDATE = 'carrierOnboarding/inviteInfo/UPDATE'
export const RESET = 'carrierOnboarding/inviteInfo/RESET'

const initialState = {
    token: '',
    email: '',
    mcNumber: '',
    dotNumber: '',
    valid: false
}
// Reducer
export default function inviteInfo (state = initialState, action) {
    switch (action.type) {
        case SET_TOKEN:
            return {
                ...state,
                token: action.payload
            }
        case SET_INVITE_INVALID:
            return {
                ...state,
                valid: false
            }
        case UPDATE:
            return action.payload
        case RESET:
            return initialState
        default:
            return state
    }
}

// Action Creators
export const setInviteToken = createAction(SET_TOKEN)
export const setInviteInvalid = createAction(SET_INVITE_INVALID)
export const updateInviteInfo = createAction(UPDATE)
export const resetInviteInfo = createAction(RESET)