import React from 'react'
import PropTypes from 'prop-types'

//import css
// import styles from './styles.module.scss' 

const LoginPage  = ({
  content
}) => {
  return (
    <div>
      {content}
    </div>
  )
} 

//rules for props being passed in
LoginPage.propTypes = {
  contentArea: PropTypes.string,
  loginForm: PropTypes.object
}

export default LoginPage