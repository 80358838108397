import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Input, RadioButton, RadioGroup } from '@intxlog/iuilib'

//import components
import TaxClassificationInput from 'components/TaxClassificationInput'

//import action creators
import {
  setDisableOtherInputTaxForm,
  unsetDisableOtherInputTaxForm,
  setDisableLLCoptionsTaxForm,
  unsetDisableLLCoptionsTaxForm,
  setClassificationIsValidTaxForm,
  unsetClassificationIsValidTaxForm,
  setClassificationLLCisValidTaxForm,
  unsetClassificationLLCisValidTaxForm,
  setClassificationOtherIsValidTaxForm,
  unsetClassificationOtherIsValidTaxForm
} from 'modules/taxForm'

//import utils
import handleUpdateUserResponses from 'utils/handleUpdateUserResponses'

export class TaxClassificationInputContainer extends React.Component {  
  componentDidMount() {
    const val = this.props.valueTaxClass

    if (val !== `other`) {
      this.props.setDisableOtherInputTaxForm()
      this.props.setClassificationOtherIsValidTaxForm()
    } 
    if (val !== `llc`) {
      this.props.setDisableLLCoptionsTaxForm()
    }
    //TODO; This should be handled by the UI lib
    if (val === `other`) {
      if (this.props.valueOther === null) {
        this.props.unsetClassificationOtherIsValidTaxForm()
      } else {
        this.props.setClassificationOtherIsValidTaxForm()
      }
    }
  }

  componentDidUpdate(prevProps) {
    //check if the value changed
    if (prevProps.valueTaxClass !== this.props.valueTaxClass) {
      //logic for disabling the other input
      //if it becomes 'other' then disable the other input
      if (this.props.valueTaxClass === `other`) {
        this.props.unsetDisableOtherInputTaxForm()
        this.props.valueOther ? this.props.setClassificationOtherIsValidTaxForm() : this.props.unsetClassificationOtherIsValidTaxForm()
      } 
      
      // if it was 'other' then disable the other input
      if (prevProps.valueTaxClass === `other`) {
        this.props.setDisableOtherInputTaxForm()
        this.props.setClassificationOtherIsValidTaxForm()
      }

      //if the value becomes 'llc' then enable the buttons
      if (this.props.valueTaxClass === `llc`) {
        this.props.unsetDisableLLCoptionsTaxForm()
      }

      //if it was llc then disable the buttons
      if (prevProps.valueTaxClass === `llc`) {
        this.props.setDisableLLCoptionsTaxForm()
      }
    }

    //other input validation.
    //TODO: this should be handled by the UI lib
    if (prevProps.valueOther === null && prevProps.valueOther !== this.props.valueOther && this.props.valueTaxClass === `other`) {
      this.props.setClassificationOtherIsValidTaxForm() 
    }
    if (prevProps.valueOther !== null && this.props.valueOther === null && this.props.valueTaxClass === `other`) {
      this.props.unsetClassificationOtherIsValidTaxForm() 
    }
  }

  //change event for the valueTaxClass
  onValueChange = (val) => {
    this.props.handleUpdateUserResponses({w9TaxClassification: val})
  }

  onLLCchange = (val) => {
    this.props.handleUpdateUserResponses({w9TaxClassificationLLCoptions: val})
  }

  onOtherChange = (val) => {
    if (val === ``) {
      val = null
    }
    this.props.handleUpdateUserResponses({w9TaxClassificationOther: val})
  } 

  onClassificationIsValid = (isValid) => {
    isValid ? this.props.setClassificationIsValidTaxForm() : this.props.unsetClassificationIsValidTaxForm()
  }

  onLLCisValid = (isValid) => {
    isValid ? this.props.setClassificationLLCisValidTaxForm() : this.props.unsetClassificationLLCisValidTaxForm()
  }

  render() {
    let otherTextInput = <Input 
      type='text'
      idForLabel={`other`}
      labelText={`Other`}
      placeholder={`Other`}
      defaultValue={this.props.valueOther}
      disabled={this.props.disableOtherInput}
      required={!this.props.disableOtherInput}
      onChange={this.onOtherChange}
      isValid={this.onOtherIsValid}
      formSubmitted={this.props.formSubmitted}
    ></Input>

    let llcOptions = <RadioGroup
        name={`llcOptions`}
        disabled={this.props.disableLLCoptions}
        required={!this.props.disableLLCoptions}
        formSubmitted={this.props.formSubmitted}
        onChange={this.onLLCchange}
        isValid={this.onLLCisValid}
        defaultValue={this.props.valueLLC}
      >
        <RadioButton 
          value={`cCorp`}
          labelText={`C Corporation`}
        ></RadioButton>
        <RadioButton 
          labelText={`S Corporation`}
          value={`sCorp`}
        ></RadioButton>
        <RadioButton 
          value={`partnership`}
          labelText={`Partnership`}
        ></RadioButton>
      </RadioGroup>

    return (
      <TaxClassificationInput
        value={this.props.valueTaxClass}
        onChange={this.onValueChange}
        onValid={this.onClassificationIsValid}
        otherTextInput={otherTextInput}
        llcOptionsInputs={llcOptions}
        formSubmitted={this.props.formSubmitted}
      ></TaxClassificationInput>
    )
  }
}

const mapStateToProps = state => ({
  valueLLC: state.userResponses.w9TaxClassificationLLCoptions,
  valueOther: state.userResponses.w9TaxClassificationOther,
  valueTaxClass: state.userResponses.w9TaxClassification,
  disableOtherInput: state.taxForm.disableOtherInput,
  disableLLCoptions: state.taxForm.disableLLCoptions,
  formSubmitted: state.taxForm.formSubmitted,
  uid: state.userData.uid 
})

const mapDispatchToProps = dispatch => bindActionCreators({
  setDisableOtherInputTaxForm,
  unsetDisableOtherInputTaxForm,
  setDisableLLCoptionsTaxForm,
  unsetDisableLLCoptionsTaxForm,
  setClassificationIsValidTaxForm,
  unsetClassificationIsValidTaxForm,
  setClassificationLLCisValidTaxForm,
  unsetClassificationLLCisValidTaxForm,
  setClassificationOtherIsValidTaxForm,
  unsetClassificationOtherIsValidTaxForm,
  handleUpdateUserResponses
}, dispatch)

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(TaxClassificationInputContainer)