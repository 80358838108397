import React from 'react'

import { Button } from '@intxlog/iuilib'

//import css
import styles from './styles.module.scss'

const ConfirmationModal = (props) => {
  return (
    <div className={styles.modalBackgroundLayer}>
      <div onClick={props.closeModal} className={styles.exitLayer}></div>
      <div className={styles.modal}>
        <div className={styles.header}>
          <h1 className={styles.headerText}>{props.headerText}</h1>
          <span className={styles.closeModal} onClick={props.closeModal}>&#10005;</span>
        </div>

        <div className={styles.description}>{props.descriptionText}</div>

        {/* Bottom text and buttons */}
        <div className={styles.question}>Do you wish to proceed?</div>
        <div className={styles.buttons}>
          <Button
            text="Yes"
            type="primary"
            size="small"
            onClick={props.selectedYes}
            className={styles.bottomButton}
          ></Button>
          <Button
            text="Cancel"
            type="primary"
            size="small"
            onClick={props.selectedNo}
            className={styles.bottomButton}
          ></Button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationModal;