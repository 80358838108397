import { createAction } from 'redux-actions'

// Actions
export const UPDATE = 'carrierOnboarding/userResponses/UPDATE'
export const SET = 'carrierOnboarding/userResponses/SET'
export const RESET = 'carrierOnboarding/userResponses/RESET'

const initialState = {
  companyName: ``,
  companyDBAName: ``,
  companyAddress: ``,
  companyAddress2: ``,
  companyCity: ``,
  companyState: ``,
  companyZip: ``,
  companyCountry: ``,
  companyPhone: ``,
  companyFax: ``,
  companyEmail: ``,
  companyMCNumber: ``,
  companyDOTNumber: ``,
  accountingEmail: ``,
  accountingPhone: ``,
  accountingFax: ``,
  remitCompanyName: ``,
  remitCompanyAddress: ``,
  remitCompanyCity: ``,
  remitCompanyState: ``,
  remitCompanyZip: ``,
  remitCompanyCountry: ``,
  remitCompanyPhone: ``,
  remitCompanyEmail: ``,
  remitCompanyFax: ``,
  remitCompanyPayToFirstName: ``,
  remitCompanyPayToLastName: ``,
  usesFactoringCompany: false,
  factoringCompanyName: ``,
  dispatcherAccessorialCharges: false,
  dispatcherFuelAdvances: false,
  driverAccessorialCharges: false,
  driverFuelAdvances: false,
  quickPayPreference: false,
  equipmentTypes: [],
  preferredLanes: [],
  w9Name: ``,
  w9BusinessName: ``,
  w9TaxClassification: null,
  w9TaxClassificationLLCoptions: null,
  w9TaxClassificationOther: null,
  w9Address: ``,
  w9City: ``,
  w9State: ``,
  w9Zip: ``,
  w9NumberType: null,
  w9TaxNumber: ``,
  w9Signature: ``,
  w9Date: ``,
  serviceAgreementSignature: ``
}
// Reducer
export default function userResponses(state = initialState, action) {
  switch (action.type) {
    // want update to only update fields and not overwrite entire object
    case UPDATE:
      return {
        ...state,
        ...action.payload
      }
    case SET:
      return {
        companyName: action.payload.company_name,
        companyDBAName: action.payload.company_DBA_name,
        companyAddress: action.payload.company_address,
        companyAddress2: action.payload.company_address2,
        companyCity: action.payload.company_city,
        companyState: action.payload.company_state,
        companyZip: action.payload.company_zip,
        companyCountry: action.payload.company_country,
        companyPhone: action.payload.company_phone,
        companyFax: action.payload.company_fax,
        companyEmail: action.payload.company_email,
        companyMCNumber: action.payload.company_MC_number,
        companyDOTNumber: action.payload.company_DOT_number,
        accountingEmail: action.payload.accounting_email,
        accountingPhone: action.payload.accounting_phone,
        accountingFax: action.payload.accounting_fax,
        remitCompanyName: action.payload.remit_company_name,
        remitCompanyAddress: action.payload.remit_company_address,
        remitCompanyCity: action.payload.remit_company_city,
        remitCompanyState: action.payload.remit_company_state,
        remitCompanyZip: action.payload.remit_company_zip,
        remitCompanyCountry: action.payload.remit_company_country,
        remitCompanyPhone: action.payload.remit_company_phone,
        remitCompanyEmail: action.payload.remit_company_email,
        remitCompanyFax: action.payload.remit_company_fax,
        remitCompanyPayToFirstName: action.payload.remit_company_pay_to_first_name,
        remitCompanyPayToLastName: action.payload.remit_company_pay_to_last_name,
        usesFactoringCompany: action.payload.uses_factoring_company,
        factoringCompanyName: action.payload.factoring_company_name,
        dispatcherAccessorialCharges: action.payload.dispatcher_accessorial_charges,
        dispatcherFuelAdvances: action.payload.dispatcher_fuel_advances,
        driverAccessorialCharges: action.payload.driver_accessorial_charges,
        driverFuelAdvances: action.payload.driver_fuel_advances,
        quickPayPreference: action.payload.quick_pay_preference,
        equipmentTypes: action.payload.equipment_types,
        preferredLanes: action.payload.preferred_lanes,
        w9Name: action.payload.w9_name,
        w9BusinessName: action.payload.w9_business_name,
        w9TaxClassification: action.payload.w9_tax_classification,
        w9TaxClassificationLLCoptions: action.payload.w9_tax_classification_LLC_options,
        w9TaxClassificationOther: action.payload.w9_tax_classification_other,
        w9Address: action.payload.w9_address,
        w9City: action.payload.w9_city,
        w9State: action.payload.w9_state,
        w9Zip: action.payload.w9_zip,
        w9NumberType: action.payload.w9_number_type,
        w9TaxNumber: action.payload.w9_tax_number,
        w9Signature: action.payload.w9_signature,
        w9Date: action.payload.w9_date,
        serviceAgreementSignature: action.payload.service_agreement_signature
      }
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const updateUserResponses = createAction(UPDATE)
export const setUserResponses = createAction(SET)
export const resetUserResponses = createAction(RESET)
