import { createAction } from 'redux-actions'

// Actions
export const UPDATE = 'carrierOnboarding/saferWatchResponse/UPDATE'
export const RESET = 'carrierOnboarding/saferWatchResponse/RESET'

const initialState = {}
// Reducer
export default function saferWatchResponse (state = initialState, action) {
  switch (action.type) {
    // want update to only update feilds and not overwrite entire object
    case UPDATE:
      return action.payload
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const updateSaferWatchResponse = createAction(UPDATE)
export const resetSaferWatchResponse = createAction(RESET)
