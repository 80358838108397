import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

//import components
import LoginForm from 'components/LoginForm'
import LoginPage from 'components/LoginPage'

import navigateTo from 'utils/navigateTo'

//import actions
import {
  updateEmailValue,
  setMCIsValid,
  unsetMCIsValid,
  setEmailIsValid,
  unsetEmailIsValid
} from 'modules/loginForm'

import {
  updateNumberTypeCompanyLookup,
  updateNumberCompanyLookup
} from 'modules/companyLookup'

import { resetModal, toggleOpenModal, setOpenModal, setClosedModal } from 'modules/modal'
import IELAPIUserService from 'services/IELAPIUserService'

export class LoginContainer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: ''
    }

    this.login = this.handleLoginSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.carrier.email !== this.props.carrier.email) {
      this.props.updateEmailValue(this.props.carrier.email)
      this.props.setEmailIsValid()
    }
  }

  componentDidMount() {
    this.userService = new IELAPIUserService();

    // Set the email field from the URL email prop if one exists
    this.setState({ email: this.props.email });
  }

  handleEmailChange = (val) => {
    this.setState({ email: val })
  }

  handlePasswordChange = (val) => {
    this.setState({ password: val })
  }

  handleLoginSubmit = (e) => {
    e.preventDefault()
    this.userService.signIn(this.state.email, this.state.password);
  }

  handleEmailIsValid = (bool) => {
    if (bool) {
      this.props.setEmailIsValid()
    } else {
      this.props.unsetEmailIsValid()
    }
  }

  onClickSignup = () => {
    this.props.navigateTo(`/signup`)
  }

  handleModalClose = () => {
    this.props.setClosedModal()
    this.props.resetModal()
  }

  render() {
    let content = <LoginForm
      emailInputValue={this.state.email}
      emailError={this.props.emailInputError}
      emailErrorText={this.props.emailInputErrorText}
      isEmailValid={this.handleEmailIsValid}
      disableButton={!this.props.emailIsValid || !this.props.mcIsValid || !this.props.companyLookup.numberType}
      onEmailChange={(val) => this.handleEmailChange(val)}
      onPasswordChange={(val) => this.handlePasswordChange(val)}
      onSubmit={this.login}
      isLoading={this.props.isLoading}
      modal={this.props.modal}
      handleModalClose={this.handleModalClose}
      onClickSignup={this.onClickSignup}
    ></LoginForm>

    return (
      <LoginPage
        content={content}
      ></LoginPage>
    )
  }
}

const mapStateToProps = state => ({
  ...state.loginForm,
  companyLookup: state.companyLookup,
  isLoading: state.appSettings.isLoading,
  modal: state.modal,
  auth: state.auth,
  carrier: state.carrier
})

const mapDispatchToProps = dispatch => bindActionCreators({
  updateEmailValue,
  setMCIsValid,
  unsetMCIsValid,
  setEmailIsValid,
  unsetEmailIsValid,
  updateNumberType: updateNumberTypeCompanyLookup,
  updateNumber: updateNumberCompanyLookup,
  resetModal,
  setOpenModal,
  setClosedModal,
  toggleOpenModal,
  navigateTo
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer)

