import React from 'react'
import PropTypes from 'prop-types'
import { Input } from '@intxlog/iuilib'

//import containers
import SSNEINContainer from 'containers/SSNEINContainer'
import TaxClassificationInputContainer from 'containers/TaxClassificationInputContainer'

//import components
import LabelWrapper from 'components/LabelWrapper'

//import helpers
import StatesOptions from 'helpers/selectOptions/States'

//import css
import styles from './styles.module.scss'

const TaxForm = ({
  name,
  businessName,
  address,
  city,
  state,
  zip,
  signature,
  onNameChange,
  onBusinessNameChange,
  onAddressChange,
  onCityChange,
  onStateChange,
  onZipChange,
  onSignatureChange,
  onNameIsValid,
  onAddressIsValid,
  onCityIsValid,
  onStateIsValid,
  onZipIsValid,
  onSignatureIsValid,
  formSubmitted
}) => {
  return (
    <div className={styles.container}>
      <LabelWrapper
        id={`name`}
        text={`Name:`}
        isRequired={true}
      >
        <Input
          idForLabel={`name`}
          defaultValue={name}
          onChange={onNameChange}
          required={true}
          formSubmitted={formSubmitted}
          isValid={onNameIsValid}
        ></Input>
      </LabelWrapper>
      <LabelWrapper
        id={`businessName`}
        text={`Business Name:`}
      >
        <Input
          idForLabel={`businessName`}
          defaultValue={businessName}
          onChange={onBusinessNameChange}
        ></Input>
      </LabelWrapper>
      <LabelWrapper
        id={`taxClass`}
        text={`Tax Classification:`}
        isRequired={true}
      >
        <TaxClassificationInputContainer></TaxClassificationInputContainer>
      </LabelWrapper>
      <LabelWrapper
        id={`address`}
        text={`Address:`}
        isRequired={true}
      >
        <Input
          idForLabel={`address`}
          defaultValue={address}
          onChange={onAddressChange}
          required={true}
          formSubmitted={formSubmitted}
          isValid={onAddressIsValid}
        ></Input>
      </LabelWrapper>
      <LabelWrapper
        id={`city`}
        text={`City:`}
        isRequired={true}
      >
        <Input
          idForLabel={`city`}
          defaultValue={city}
          onChange={onCityChange}
          required={true}
          formSubmitted={formSubmitted}
          isValid={onCityIsValid}
        ></Input>
      </LabelWrapper>
      <LabelWrapper
        id={`state`}
        text={`State:`}
        isRequired={true}
      >
        <Input
          type={`select`}
          defaultValue={state || `IUILIBdefault`}
          onChange={onStateChange}
          required={true}
          formSubmitted={formSubmitted}
          isValid={onStateIsValid}
        >
          <option disabled value={`IUILIBdefault`}>Please choose a state</option>
          <StatesOptions></StatesOptions>
        </Input>
      </LabelWrapper>
      <LabelWrapper
        id={`zip`}
        text={`ZIP/Postal Code:`}
        isRequired={true}
      >
        <Input
          idForLabel={`zip`}
          type={`zip`}
          defaultValue={zip}
          onChange={onZipChange}
          required={true}
          formSubmitted={formSubmitted}
          isValid={onZipIsValid}
        ></Input>
      </LabelWrapper>
      <LabelWrapper
        id={`ssn`}
        text={`SSN or EIN:`}
        isRequired={true}
      >
        <SSNEINContainer></SSNEINContainer>
      </LabelWrapper>
      <LabelWrapper
        id={`signature`}
        text={`Signature:`}
        isRequired={true}
      >
        <Input
          idForLabel={`signature`}
          defaultValue={signature}
          onChange={onSignatureChange}
          required={true}
          formSubmitted={formSubmitted}
          isValid={onSignatureIsValid}
        ></Input>
      </LabelWrapper>
    </div>
  )
}

//rules for props being passed in
TaxForm.propTypes = {
  name: PropTypes.string,
  businessName: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  signature: PropTypes.string,
  onNameChange: PropTypes.func,
  onBusinessNameChange: PropTypes.func,
  onAddressChange: PropTypes.func,
  onCityChange: PropTypes.func,
  onStateChange: PropTypes.func,
  onZipChange: PropTypes.func,
  onSignatureChange: PropTypes.func,
  onDateChange: PropTypes.func,
  onNameIsValid: PropTypes.func,
  onAddressIsValid: PropTypes.func,
  onCityIsValid: PropTypes.func,
  onStateIsValid: PropTypes.func,
  onZipIsValid: PropTypes.func,
  onSignatureIsValid: PropTypes.func,
  formSubmitted: PropTypes.number
}

export default TaxForm