import React from 'react'
import { RadioGroup, RadioButton } from '@intxlog/iuilib'
import PropTypes from 'prop-types'


//import css
import styles from './styles.module.scss'

const TaxClassificationInput = ({
  value,
  onChange,
  onValid,
  otherTextInput,
  llcOptionsInputs,
  formSubmitted
}) => {
  return (
    <div className={styles.container}>
      <RadioGroup
        name={`taxClass`}
        onChange={onChange}
        required
        formSubmitted={formSubmitted}
        defaultValue={value}
        isValid={onValid}
      >
        <RadioButton 
          labelText={`Individual/sole proprietor or single-member LLC`}
          value={`indiv`}
        ></RadioButton>
        <RadioButton 
          labelText={`C Corporation`}
          value={`cCorp`}
        ></RadioButton>
        <RadioButton 
          labelText={`S Corporation`}
          value={`sCorp`}
        ></RadioButton>
        <RadioButton 
          labelText={`Partnership`}
          value={`partnership`}
        ></RadioButton>
        <RadioButton
          labelText={`Trust/estate`}
          value={`trust`}
        ></RadioButton>
        <RadioButton 
          labelText={`Limited Liability Company`}
          value={`llc`}
        ></RadioButton>
        <div className={styles.llcOptionsWrapper}>
          {llcOptionsInputs}
        </div>
        <RadioButton 
          labelText={`Other`}
          value={`other`}
        ></RadioButton>
        <div className={styles.otherWrapper}>
          {otherTextInput}
        </div>
      </RadioGroup>
    </div>
  )
}

//rules for props being passed in
TaxClassificationInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onValid: PropTypes.func,
  otherTextInput: PropTypes.object,
  llcOptions: PropTypes.object,
  formSubmitted: PropTypes.number
}

// // Specifies the default values for props:
// TaxClassificationInput.defaultProps = {
//
// }

export default TaxClassificationInput