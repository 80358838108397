import React from 'react'
import { Button } from '@intxlog/iuilib'
// import PropTypes from 'prop-types'

//import css
import styles from './styles.module.scss'

const CompanyLookupErrorResult = () => {
  return (
    <div className={styles.container}>
      <p>Thank you for your interest in setting up with Integrity Express Logistics. If you do not have an MC number or a DOT number please reach out to our Carrier Services Specialists at  <Button text={process.env.REACT_APP_CARRIER_DEPT_PHONE} type="link" elementType="link" linkTo={`tel:+${process.env.REACT_APP_CARRIER_DEPT_PHONE}`} target='_blank' rel="noopener noreferrer" >{process.env.REACT_APP_CARRIER_DEPT_PHONE}</Button> or <Button text={process.env.REACT_APP_CARRIER_SERVICES_EMAIL} type="link" elementType="link" linkTo={`mailto:${process.env.REACT_APP_CARRIER_SERVICES_EMAIL}`}  rel='noopener noreferrer' target='_top'>{process.env.REACT_APP_CARRIER_SERVICES_EMAIL}</Button> so we may better assist you with the set up process.</p>
    </div>
  )
}

//rules for props being passed in
// CompanyLookupErrorResult.propTypes = {

// }

export default CompanyLookupErrorResult