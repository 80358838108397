import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

//import components
import { LoadingGif, Modal } from '@intxlog/iuilib'
import Header from 'components/Header'

//import utils
import navigateTo from 'utils/navigateTo'
import handleUpdateAppDataSettings from 'utils/handleUpdateAppDataSettings'
import logUserOut from 'utils/logUserOut'

//import actions
import { toggleOpenModal } from 'modules/modal'
import {
  setWasRedirectedAppSettings,
  unsetWasRedirectedAppSettings,
  setIsEditingAppSettings
} from 'modules/appSettings'

//import routers
import LoggedInUserNotConfirmedRouter from 'routers/LoggedInUserNotConfirmedRouter'
import LoggedInUserConfirmedRouter from 'routers/LoggedInUserConfirmedRouter'
import UserCompletedRouter from 'routers/UserCompletedRouter'

//TODO: remove styles from container
//import styles
import styles from './styles.module.scss'

//Feedback stuff
import FeedbackModal from 'components/FeedbackModal'
import FeedbackBanner from 'components/FeedbackBanner'
import IELAPIUtilityService from 'services/IELAPIUtilityService'

export class loggedInContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      feedbackModal: false
    }
  }

  componentDidMount() {
    this.utilityService = new IELAPIUtilityService();

    //Redirect user to last URL they were at. This was previously in componentDidUpdate but was no longer firing at the correct time
    if (!this.props.waitingOnAppDataSettings && !this.props.waitingOnUserResponses && this.props.userConfirmed && !this.props.wasRedirected) {
      if (this.props.location.pathname !== `/admin`) {
        this.props.navigateTo(this.props.lastURL)
        this.props.setWasRedirectedAppSettings()
      }
    }
  }

  componentDidUpdate = (prevProps) => {
    //see if the path changes because we need to store it
    if (prevProps.location.pathname !== this.props.location.pathname) {
      const path = this.props.location.pathname
      this.props.handleUpdateAppDataSettings({ lastURL: path })
    }
  }

  submitFeedback(feedback) {
    this.utilityService.submitFeedback(feedback);
  }

  //Takes a user back to the input screens
  //Will ONLY allow to edit payterms and nothing else
  editInfoFunction = () => {
    //If we dont navigate to '/' this will not work
    this.props.navigateTo('/')

    this.props.setIsEditingAppSettings();
  }

  render() {
    //var to house dynamic content
    let content = null

    //If the user is going back to edit their settings, otherwise handle all others
    if (this.props.completedProcess && this.props.isEditing) {
      content = <LoggedInUserConfirmedRouter></LoggedInUserConfirmedRouter>
    } else {
      //only show the content if we are done waiting on appDataSettings and user responses
      if (!this.props.waitingOnAppDataSettings && !this.props.waitingOnUserResponses) {
        if (this.props.userConfirmed) {
          if (this.props.completedProcess) {
            content = <UserCompletedRouter></UserCompletedRouter>
          } else {
            content = <LoggedInUserConfirmedRouter></LoggedInUserConfirmedRouter>
          }
        } else {
          content = <LoggedInUserNotConfirmedRouter
            isFromInvite={this.props.mcNumber !== "" || this.props.dotNumber !== ""}
          ></LoggedInUserNotConfirmedRouter>
        }
      }
    }

    //logic for the css classes for the main content of the app
    let mainCSSCLasses = classNames({
      [styles.loading]: this.props.isLoading
    })

    return (
      <>
        {/* Feedback stuff */}
        <FeedbackBanner
          openFeedbackModal={() => { this.setState({ feedbackModal: true }) }}
        ></FeedbackBanner>
        {this.state.feedbackModal && <FeedbackModal
          closeModal={() => { this.setState({ feedbackModal: false }) }}
          submitFeedback={(feedback) => { this.submitFeedback(feedback) }}
        ></FeedbackModal>}

        <Header
          logoutFunction={this.props.logoutFunction}
          menuOpen={this.props.menuOpen}
          userData={this.props.userData}
          toggleMenu={this.props.toggleAppMenu}
          navigateTo={this.props.navigateTo}
          completedProcess={this.props.completedProcess}
          editInfoFunction={this.editInfoFunction}
        ></Header>
        <main className={mainCSSCLasses}>
          {content}
        </main>
        {this.props.isLoading &&
          <LoadingGif></LoadingGif>
        }
        <Modal
          {...this.props.modal}
          closeFunc={this.props.toggleOpenModal}
        ></Modal>
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...state.appSettings,
  ...state.appDataSettings,
  usesFactoringCompany: state.userResponses.usesFactoringCompany,
  userData: state.userData,
  modal: state.modal,
  mcNumber: state.userResponses.companyMCNumber,
  dotNumber: state.userResponses.companyDOTNumber
})

const mapDispatchToProps = dispatch => bindActionCreators({
  navigateTo,
  toggleOpenModal,
  handleUpdateAppDataSettings,
  setWasRedirectedAppSettings,
  unsetWasRedirectedAppSettings,
  setIsEditingAppSettings,
  logoutFunction: logUserOut
}, dispatch)

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(loggedInContainer))
