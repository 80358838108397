import { createAction } from 'redux-actions'

// Actions
export const SET_NAME_IS_VALID = 'carrierOnboarding/remitInfoForm/SET_NAME_IS_VALID'
export const UNSET_NAME_IS_VALID = 'carrierOnboarding/remitInfoForm/UNSET_NAME_IS_VALID'
export const SET_ADDRESS_IS_VALID = 'carrierOnboarding/remitInfoForm/SET_ADDRESS_IS_VALID'
export const UNSET_ADDRESS_IS_VALID = 'carrierOnboarding/remitInfoForm/UNSET_ADDRESS_IS_VALID'
export const SET_CITY_IS_VALID = 'carrierOnboarding/remitInfoForm/SET_CITY_IS_VALID'
export const UNSET_CITY_IS_VALID = 'carrierOnboarding/remitInfoForm/UNSET_CITY_IS_VALID'
export const SET_STATE_IS_VALID = 'carrierOnboarding/remitInfoForm/SET_STATE_IS_VALID'
export const UNSET_STATE_IS_VALID = 'carrierOnboarding/remitInfoForm/UNSET_STATE_IS_VALID'
export const SET_ZIP_IS_VALID = 'carrierOnboarding/remitInfoForm/SET_ZIP_IS_VALID'
export const UNSET_ZIP_IS_VALID = 'carrierOnboarding/remitInfoForm/UNSET_ZIP_IS_VALID'
export const SET_COUNTRY_IS_VALID = 'carrierOnboarding/remitInfoForm/SET_COUNTRY_IS_VALID'
export const UNSET_COUNTRY_IS_VALID = 'carrierOnboarding/remitInfoForm/UNSET_COUNTRY_IS_VALID'
export const SET_EMAIL_IS_VALID = 'carrierOnboarding/remitInfoForm/SET_EMAIL_IS_VALID'
export const UNSET_EMAIL_IS_VALID = 'carrierOnboarding/remitInfoForm/UNSET_EMAIL_IS_VALID'
export const SET_PHONE_IS_VALID = 'carrierOnboarding/remitInfoForm/SET_PHONE_IS_VALID'
export const UNSET_PHONE_IS_VALID = 'carrierOnboarding/remitInfoForm/UNSET_PHONE_IS_VALID'
export const SET_FORM_IS_SUBMITTED = 'carrierOnboarding/remitInfoForm/SET_FORM_IS_SUBMITTED'
export const UNSET_FORM_IS_SUBMITTED = 'carrierOnboarding/remitInfoForm/UNSET_FORM_IS_SUBMITTED'
export const RESET = 'carrierOnboarding/remitInfoForm/RESET'

const initialState = {
  nameIsValid: false,
  addressIsValid: false,
  cityIsValid: false,
  stateIsValid: false,
  zipIsValid: false,
  countryIsValid: false,
  emailIsValid: false,
  phoneIsValid: false,
  formSubmitted: 0
}
// Reducer
export default function remitInfoForm (state = initialState, action) {
  switch (action.type) {
    case SET_NAME_IS_VALID:
      return {
        ...state,
        nameIsValid: true
      }
    case UNSET_NAME_IS_VALID:
      return {
        ...state,
        nameIsValid: false
      }
    case SET_ADDRESS_IS_VALID:
      return {
        ...state,
        addressIsValid: true
      }
    case UNSET_ADDRESS_IS_VALID:
      return {
        ...state,
        addressIsValid: false
      }
    case SET_CITY_IS_VALID:
      return {
        ...state,
        cityIsValid: true
      }
    case UNSET_CITY_IS_VALID:
      return {
        ...state,
        cityIsValid: false
      }
    case SET_STATE_IS_VALID:
      return {
        ...state,
        stateIsValid: true
      }
    case UNSET_STATE_IS_VALID:
      return {
        ...state,
        stateIsValid: false
      }
    case SET_ZIP_IS_VALID:
      return {
        ...state,
        zipIsValid: true
      }
    case UNSET_ZIP_IS_VALID:
      return {
        ...state,
        zipIsValid: false
      }
    case SET_COUNTRY_IS_VALID:
      return {
        ...state,
        countryIsValid: true
      }
    case UNSET_COUNTRY_IS_VALID:
      return {
        ...state,
        countryIsValid: false
      }
    case SET_EMAIL_IS_VALID:
      return {
        ...state,
        emailIsValid: true
      }
    case UNSET_EMAIL_IS_VALID:
      return {
        ...state,
        emailIsValid: false
      }
    case SET_PHONE_IS_VALID:
      return {
        ...state,
        phoneIsValid: true
      }
    case UNSET_PHONE_IS_VALID:
      return {
        ...state,
        phoneIsValid: false
      }
    case SET_FORM_IS_SUBMITTED:
      return {
        ...state,
        formSubmitted: parseInt(state.formSubmitted) + 1
      }
    case UNSET_FORM_IS_SUBMITTED:
      return {
        ...state,
        formSubmitted: 0
      }
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const setNameIsValidRemitInfoForm = createAction(SET_NAME_IS_VALID)
export const unsetNameIsValidRemitInfoForm = createAction(UNSET_NAME_IS_VALID)
export const setAddressIsValidRemitInfoForm = createAction(SET_ADDRESS_IS_VALID)
export const unsetAddressIsValidRemitInfoForm = createAction(UNSET_ADDRESS_IS_VALID)
export const setCityIsValidRemitInfoForm = createAction(SET_CITY_IS_VALID)
export const unsetCityIsValidRemitInfoForm = createAction(UNSET_CITY_IS_VALID)
export const setStateIsValidRemitInfoForm = createAction(SET_STATE_IS_VALID)
export const unsetStateIsValidRemitInfoForm = createAction(UNSET_STATE_IS_VALID)
export const setZipIsValidRemitInfoForm = createAction(SET_ZIP_IS_VALID)
export const unsetZipIsValidRemitInfoForm = createAction(UNSET_ZIP_IS_VALID)
export const setCountryIsValidRemitInfoForm = createAction(SET_COUNTRY_IS_VALID)
export const unsetCountryIsValidRemitInfoForm = createAction(UNSET_COUNTRY_IS_VALID)
export const setEmailIsValidRemitInfoForm = createAction(SET_EMAIL_IS_VALID)
export const unsetEmailIsValidRemitInfoForm = createAction(UNSET_EMAIL_IS_VALID)
export const setPhoneIsValidRemitInfoForm = createAction(SET_PHONE_IS_VALID)
export const unsetPhoneIsValidRemitInfoForm = createAction(UNSET_PHONE_IS_VALID)
export const setFormIsSubmittedRemitInfoForm = createAction(SET_FORM_IS_SUBMITTED)
export const unsetFormIsSubmittedRemitInfoForm = createAction(UNSET_FORM_IS_SUBMITTED)
export const resetRemitInfoForm = createAction(RESET)
