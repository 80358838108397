import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

//import containers
import LoggedInContainer from 'containers/LoggedInContainer'

import { updateUserData } from 'modules/userData'

//import utils
import logUserOut from 'utils/logUserOut'

//import routers
import LoggedOutRouter from 'routers/LoggedOutRouter'

import {
  userLoggedInAuth
} from 'modules/auth'

import navigateTo from 'utils/navigateTo'

import IELAPIServiceFactory from 'services/IELAPIServiceFactory'

//import styles
import 'assets/styles/base.module.scss'

export class App extends React.Component {
  componentDidMount() {
    //Grab the bearer token from storage
    const token = sessionStorage.getItem('accessToken');
    

    //If there is a token and the user is marked as not logged in,
    //re-auth them since they probably just refreshed the page
    if (token && !this.props.authentication.userLoggedIn) {
      const api = IELAPIServiceFactory.new()
      
      api.userInfo()
      api.getUserData()
      this.props.userLoggedInAuth()
    }
  }

  render() {
    let content = null

    if (!this.props.authentication.userLoggedIn) {
      content = <LoggedOutRouter></LoggedOutRouter>
    } else {
      content = <LoggedInContainer></LoggedInContainer>
    }

    return (content)
  }
}

const mapStateToProps = state => ({
  authentication: state.auth
})

const mapDispatchToProps = dispatch => bindActionCreators({
  updateUserData,
  logoutFunction: logUserOut,
  userLoggedInAuth,
  navigateTo
}, dispatch)

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(App))
