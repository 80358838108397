import React from 'react'

//import css
import styles from './styles.module.scss'

const FeedbackBanner = (props) => {
    return (
        <div className={styles.banner}>
            <span onClick={() => { props.openFeedbackModal() }} className={styles.text}>Leave Feedback!</span>
        </div>
    )
}

export default FeedbackBanner;