import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { SiteContainer } from '@intxlog/iuilib'

//import components
import Header from 'components/Header'

//import containers
import LoginContainer from 'containers/LoginContainer'
import SignupContainer from 'containers/SignupContainer'
import SplashContainer from 'containers/SplashContainer'

const LoggedOutRouter = () => {
  return (
    <>
      <Header></Header>
      <SiteContainer>
        <Switch>
          <Route exact path='/login' component={LoginContainer} />
          <Route exact path='/signup' component={SignupContainer} />

          {/* Two below routes will allow the email to be passed from the URL into the email field */}
          <Route path='/login/:email' render={(props) => (
            <LoginContainer email={props.match.params.email} />
          )} />
          <Route path='/signup/:email' render={(props) => (
            <SignupContainer email={props.match.params.email} />
          )} />

          <Route component={SplashContainer} />

        </Switch>
      </SiteContainer>
    </>
  )
}

export default LoggedOutRouter