import React from 'react'
import PropTypes from 'prop-types'
import { Input } from '@intxlog/iuilib'

//import components
import LabelWrapper from 'components/LabelWrapper'

//import helper Components
import StatesOptions from 'helpers/selectOptions/StateProvince'
import CountryOptions from 'helpers/selectOptions/Country'

//import css
import styles from './styles.module.scss'

const CompanyProfileQuestions = ({
  name,
  nameDBA,
  address,
  address2,
  city,
  state,
  zip,
  country,
  contactEmail,
  accountingPhone,
  accountingEmail,
  onNameDBAChange,
  usesFactoringCompany,
  onEmailChange,
  isEmailValid,
  disableEmail
}) => {
  return (
    <div className={styles.container}>
      <LabelWrapper
        id={`legalName`}
        text={`Company Legal Name:`}
      >
        <Input
          idForLabel={`legalName`}
          defaultValue={name}
          disabled
        ></Input>
      </LabelWrapper>
      <LabelWrapper
        id={`DBAName`}
        text={`Company DBA Name:`}
      >
        <Input
          idForLabel={`DBAName`}
          defaultValue={nameDBA}
          onChange={onNameDBAChange}
        ></Input>
      </LabelWrapper>
      <LabelWrapper
        id={`companyAddress`}
        text={`Address Line 1:`}
      >
        <Input
          idForLabel={`companyAddress`}
          defaultValue={address}
          disabled
        ></Input>
      </LabelWrapper>
      <LabelWrapper
        id={`companyAddress2`}
        text={`Address Line 2:`}
      >
        <Input
          idForLabel={`companyAddress2`}
          defaultValue={address2}
          disabled
        ></Input>
      </LabelWrapper>
      <LabelWrapper
        id={`companyAddress`}
        text={`Address:`}
      >
        <Input
          idForLabel={`companyAddress`}
          defaultValue={address}
          disabled
        ></Input>
      </LabelWrapper>
      <LabelWrapper
        id={`city`}
        text={`City:`}
      >
        <Input
          idForLabel={`city`}
          defaultValue={city}
          disabled
        ></Input>
      </LabelWrapper>
      <LabelWrapper
        id={`state`}
        text={`State/Province:`}
      >
        <Input
          type={`select`}
          defaultValue={state}
          disabled
        >
          <StatesOptions></StatesOptions>
        </Input>
      </LabelWrapper>
      <LabelWrapper
        id={`zip`}
        text={`ZIP/Postal Code:`}
      >
        <Input
          idForLabel={`zip`}
          defaultValue={zip}
          disabled
        ></Input>
      </LabelWrapper>
      <LabelWrapper
        id={`country`}
        text={`Country:`}
      >
        <Input
          type={`select`}
          defaultValue={country}
          disabled
        >
          <CountryOptions></CountryOptions>
        </Input>
      </LabelWrapper>
      <LabelWrapper
        id={`contactEmail`}
        text={`Contact Email:`}
        isRequired={true}
      >
        <Input
          type="email"
          defaultValue={contactEmail}
          disabled={disableEmail}
          required
          onChange={onEmailChange}
          validate={true}
          isValid={isEmailValid}
        ></Input>
      </LabelWrapper>
      {!usesFactoringCompany &&
        <>
          <LabelWrapper
            id={`acctPhone`}
            text={`Accounting Phone:`}
          >
            <Input
              defaultValue={accountingPhone}
              disabled
            ></Input>
          </LabelWrapper>
          <LabelWrapper
            id={`acctEmail`}
            text={`Accounting Email:`}
          >
            <Input
              defaultValue={accountingEmail}
              disabled
            ></Input>
          </LabelWrapper>
        </>
      }
    </div>
  )
}


//rules for props being passed in
CompanyProfileQuestions.propTypes = {
  name: PropTypes.string,
  nameDBA: PropTypes.string,
  address: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  country: PropTypes.string,
  contactEmail: PropTypes.string,
  accountingPhone: PropTypes.string,
  accountingEmail: PropTypes.string,
  onNameDBAChange: PropTypes.func,
  usesFactoringCompany: PropTypes.bool
}

export default CompanyProfileQuestions