import IELAPIServiceFactory from './IELAPIServiceFactory'
import store from 'store'

import navigateTo from 'utils/navigateTo'

import { setTypeModal, setTextModal, setOpenModal } from 'modules/modal'

import {
    setIsLoadingAppSettings,
    unsetIsLoadingAppSettings
} from 'modules/appSettings'

import { setInviteInvalid, updateInviteInfo } from 'modules/inviteInfo'

export default class IELAPIUserService {
    constructor(props) {
        this.service = IELAPIServiceFactory.new()
        this.uid = store.getState().userData.uid
    }

    register(name, email, password, inviteInfo) {
        store.dispatch(setIsLoadingAppSettings())

        return this.service._withToken().post(`COP/carrier/register`, {
            'name': name,
            'email': email,
            'password': password,
            'inviteInfo': inviteInfo
            //The below header will determine what URL the API uses to generate the email verification link for
            //Example values include CAPOLOCAL, CAPOQA, CAPOPROD, etc 
        }, { headers: { 'X-IEL-APP': process.env.REACT_APP_ITMS_API_ENV_NAME } }).then((result) => {
            store.dispatch(unsetIsLoadingAppSettings())
            store.dispatch(navigateTo(`/login/${email}`))

            store.dispatch(setTypeModal('success'))
            store.dispatch(setTextModal('Account created successfully. Please click the link that was just sent to your email to verify your account and complete the registration process. Please allow a few minutes for the email to arrive.'))
            store.dispatch(setOpenModal())
        }).catch(error => {
            store.dispatch(unsetIsLoadingAppSettings())
            this.service.handleError(error, true, { email: email })
        })
    }

    verifyEmail(token) {
        return this.service._withToken().post(`COP/carrier/verifyEmail`, {
            'token': token
        }).then(() => {
            store.dispatch(setTypeModal('success'))
            store.dispatch(setTextModal('Email Verified Successfully!'))
            store.dispatch(setOpenModal())
        }).catch(error => {
            this.service.handleError(error, true)
        })
    }

    signIn(email, password) {
        this.service.login(email, password, process.env.REACT_APP_ITMS_API_CLIENT_ID, process.env.REACT_APP_ITMS_API_CLIENT_SECRET)
    }

    validateInvite(token) {
        return this.service._withToken().post(`COP/carrier/validateInvite`, {
            'token': token
        }).then((result) => {
            const resObj = result.data.data;

            store.dispatch(updateInviteInfo({
                token: resObj.token,
                email: resObj.email,
                mcNumber: resObj.mcnumber,
                dotNumber: resObj.dotnumber,
                valid: true
            }))

        }).catch(error => {
            store.dispatch(setInviteInvalid())
            this.service.handleError(error, true)
        })
    }

    updateAppDataSettings(appDataSettings) {
        return this.service._withToken().post(`COP/carrier/updateAppDataSettings`, appDataSettings).then(() => {
        }).catch(error => {
            this.service.handleError(error)
        })
    }

    updateUserResponses(responses) {
        return this.service._withToken().post(`COP/carrier/updateResponses`, responses).then(() => {
        }).catch(error => {
            this.service.handleError(error)
        })
    }

}
