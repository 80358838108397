import React from 'react'
import PropTypes from 'prop-types'

//import components
import { Button, SiteContainer } from '@intxlog/iuilib'

//import css
import styles from './styles.module.scss'

//render prop component to be used for formatting other components
const CompanyLookup = ({
  chooseLookupTypeComponent,
  lookupFormComponent,
  resultsContent,
  isFromInvite
}) => {
  let title = isFromInvite ? "Company Information" : "Enter MC# or DOT#"

  return (
    <SiteContainer>
      <div className={styles.container}>
        <h1>{title}</h1>
        {isFromInvite ? null : <p>Let's begin by getting your information from SaferWatch. We can lookup your info using your MC number or DOT number. Which would you like to use?</p>}
        {chooseLookupTypeComponent}
        <p><b>Note:</b> If you are an intrastate carrier with a permit # only, please reach out to our Carrier Services Team to begin registration at  <Button text={process.env.REACT_APP_CARRIER_DEPT_PHONE} type="link" elementType="link" linkTo={`tel:+${process.env.REACT_APP_CARRIER_DEPT_PHONE}`} target='_blank' rel="noopener noreferrer" >{process.env.REACT_APP_CARRIER_DEPT_PHONE}</Button>.</p>
        {lookupFormComponent}
        {resultsContent}
      </div>
    </SiteContainer>
  )
}

//rules for props being passed in
CompanyLookup.propTypes = {
  chooseLookupTypeComponent: PropTypes.object,
  lookupFormComponent: PropTypes.object,
  resultsContent: PropTypes.object
}
//
// // Specifies the default values for props:
// CompanyLookup.defaultProps = {
//
// }

export default CompanyLookup