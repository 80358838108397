import { createAction } from 'redux-actions'

// Actions
export const UPDATE = 'carrierOnboarding/appDataSettings/UPDATE'
export const SET = 'carrierOnboarding/appDataSettings/SET'
export const RESET = 'carrierOnboarding/appDataSettings/RESET'

const initialState = {
  userConfirmed: false,
  answeredFactoring: false,
  lastURL: `/`,
  completedProcess: false,
  isFromInvite: false
}
// Reducer
export default function appDataSettings(state = initialState, action) {
  switch (action.type) {
    // want update to only update feilds and not overwrite entire object
    case UPDATE:
      return {
        ...state,
        ...action.payload
      }
    case SET:
      return {
        userConfirmed: action.payload.user_confirmed,
        answeredFactoring: action.payload.answered_factoring,
        lastURL: action.payload.last_URL,
        completedProcess: action.payload.completed_process,
        isFromInvite: action.payload.is_from_invite,
      }
    case RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export const updateAppDataSettings = createAction(UPDATE)
export const setAppDataSettings = createAction(SET)
export const resetAppDataSettings = createAction(RESET)