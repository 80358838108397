import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from "react-router-dom"


//import utils
import navigateTo from 'utils/navigateTo'
import confirmCompanyData from 'utils/confirmCompanyData'

import handleUpdateUserResponses from 'utils/handleUpdateUserResponses'

//import components
import CompanyLookup from 'components/CompanyLookup'
import ChooseCIDtype from 'components/ChooseCIDtype'
import CompanyLookupResults from 'components/CompanyLookupResults'
import CompanyLookupDuplicateResult from 'components/CompanyLookupDuplicateResult'
import CompanyLookupErrorResult from 'components/CompanyLookupErrorResult'
import CompanyLookupForm from 'components/CompanyLookupForm'
import FooterButtons from 'components/FooterButtons'

//import actions
import {
  updateNumberCompanyLookup,
  updateNumberTypeCompanyLookup,
  unsetSubmittedCompanyLookup,
} from 'modules/companyLookup'
import IELAPICarrierService from 'services/IELAPICarrierService'

export class HomeContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      //Carrier Identification Number
      CID: ''
    }

    this.apiService = new IELAPICarrierService();
  }

  //handle the Carrier Identification Change
  handleCIDtextChange = (val) => {
    this.setState({
      CID: val
    })
  }

  //submit the lookup form
  handleLookupSubmit = (e) => {
    e.preventDefault()
    let number = this.state.CID
    var isMCNumber = false;

    if (this.props.numberType === 'MC') {
      isMCNumber = true
    }

    this.apiService.lookUpCarrierInfo(isMCNumber, number);
  }

  //handle the CID number change
  handleCIDChange = (val) => {
    this.props.updateNumberType(val)
  }

  proceedToPaymentOptions = () => {
    this.props.confirmCompanyData(this.props.carrierInfo)
    this.props.navigateTo('/')
  }

  render() {
    //placeholders for components we are going to pass in
    let lookupForm = null
    let resultsContent = null

    //logic for lookup form content
    if (this.props.numberType) {
      lookupForm = <CompanyLookupForm
        onChange={this.handleCIDtextChange}
        onSubmit={this.handleLookupSubmit}
      ></CompanyLookupForm>
    }

    //if the form was submitted then show some content
    if (this.props.submitted) {
      if (this.props.hasResult) {
        if (this.props.isDuplicate) {
          //If carrier exists within IEL system, show warning and do not allow user to proceed
          resultsContent = <>
            <CompanyLookupDuplicateResult
              {...this.props.carrierInfo}
            ></CompanyLookupDuplicateResult>

            <FooterButtons
              showNext={false}
              showPrev={true}
              textPrev={`Try Again`}
              onPrevClick={() => this.props.unsetSubmitted()}
            ></FooterButtons>
          </>
        } else {
          resultsContent = <>
            <CompanyLookupResults
              {...this.props.carrierInfo}
            ></CompanyLookupResults>

            <FooterButtons
              textNext={`Proceed to Payment Options`}
              onNextClick={() => { this.proceedToPaymentOptions() }}
              showPrev={true}
              textPrev={`Try Again`}
              onPrevClick={() => this.props.unsetSubmitted()}
            ></FooterButtons>
          </>
        }
      }
    } else {
      resultsContent = <CompanyLookupErrorResult></CompanyLookupErrorResult>
    }

    const chooseLookupType = <ChooseCIDtype
      onChange={this.handleCIDChange}
      defaultValue={this.props.companyLookup.numberType}
    ></ChooseCIDtype>

    return (
      <CompanyLookup
        chooseLookupTypeComponent={chooseLookupType}
        lookupFormComponent={lookupForm}
        resultsContent={resultsContent}
        isFromInvite={false}
      />
    )
  }
}

const mapStateToProps = state => ({
  carrierInfo: state.carrier,
  uid: state.userData.uid,
  saferWatchData: state.saferWatchResponse,
  companyLookup: state.companyLookup,
  appDataSettings: state.appDataSettings,
  ...state.companyLookup
})

const mapDispatchToProps = dispatch => bindActionCreators({
  navigateTo,
  updateNumber: updateNumberCompanyLookup,
  updateNumberType: updateNumberTypeCompanyLookup,
  unsetSubmitted: unsetSubmittedCompanyLookup,
  confirmCompanyData,
  handleUpdateUserResponses
}, dispatch)

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeContainer))
